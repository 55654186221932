import create from "zustand";
import createVanilla from "zustand/vanilla";

export const uiSelector = (fn: "authError" | "setAuthError") => (
  state: State
) => state[fn || "authError"];

/**
 * We need a store without react in order to be able to set state in class component which can't
 * be transform using a HoC
 */

type State = {
  authError: object;
  setAuthError: (data: object) => void;
};

export const vanillaUiStore = createVanilla<State>(set => ({
  authError: {},
  setAuthError: (data = {}) => set(state => (state.authError = data))
}));

export default create(vanillaUiStore);
