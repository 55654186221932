import React, { useCallback, useEffect, useRef, useState } from "react";
import Card from "../Card";
import TrainingsInfo from "./TrainingsInfo";
import ProgressCircle from "../ProgressCircle";
import "./styles.scss";
import { ITraining } from "../../../types/training";
import { getContentLang } from "../../utils/i18n";
import useTrainingsStore from "../../stores/trainings";
import { schemes } from "../../theme/colors";
import SvgIcon from "../SvgIcon";
import { useMediaLayout } from "use-media";

const TrainingsItem = ({
  training,
  onClick
}: {
  training: ITraining;
  onClick?: () => void;
}) => {
  const { name, picture, started } = training;
  const cardRef = useRef(null);
  const phoneScreen = useMediaLayout({
    maxWidth: "599px"
  });

  const [imageHeight, setImageHeight] = useState({});

  const { progress } = useTrainingsStore(
    useCallback(
      state =>
        state.trainings.find(t => t.id_training === training.id_training) || {
          progress: 0
        },
      [training.id_training]
    )
  );

  useEffect(() => {
    if (!cardRef) return;
    //@ts-ignore
    const cardHeight = cardRef.current.clientHeight;
    setImageHeight({
      ...(!phoneScreen &&
        cardHeight > 150 && {
          height: cardHeight
        })
    });
  }, [cardRef, phoneScreen]);

  const _onClick = useCallback(() => {
    if (onClick && started) onClick();
  }, [onClick, started]);

  return (
    <Card onClick={_onClick} style={{ padding: 0, cursor: "pointer" }}>
      <div
        className={`inteach-training-item ${
          started ? "" : "inteach-training-item--disabled"
        } d-flex`}
        ref={cardRef}>
        <div className="inteach-training-item__image" style={imageHeight}>
          {picture && <img src={picture} alt="" style={imageHeight} />}
        </div>
        <div className="inteach-training-item__content">
          <div>
            <h2 className="inteach-training-item__title">
              {getContentLang(name, training.default_lang)}
            </h2>
          </div>
          <div className="inteach-training-item__info-container">
            <TrainingsInfo training={training} />
            {started ? (
              <ProgressCircle progress={progress} size={"60px"} />
            ) : (
              <SvgIcon
                name={"lock"}
                height={50}
                width={50}
                color={schemes.gray[600]}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default TrainingsItem;
