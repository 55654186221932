import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import LEARNING_ITEM_TYPES from "../../constants/learningItems";
import SvgIcon from "../SvgIcon";
import { schemes } from "../../theme/colors";
import "./styles.scss";
import { onLearningItemPress } from "../../utils/learningItem";
import moment from "moment";
import { getContentLang } from "../../utils/i18n";
import usePubSub, { NEW_ANALYTICS } from "../../hooks/usePubSub";
import useTrainingsStore from "../../stores/trainings";

const LearningItem = ({
  active,
  startAt,
  learningItemTitleAttribute,
  idTraining,
  item,
  defaultLang
}: {
  active: boolean;
  startAt: string;
  learningItemTitleAttribute: string;
  idTraining: string;
  item: any;
  defaultLang?: string;
}) => {
  const [t] = useTranslation();
  const { publish } = usePubSub();
  const { id_learning_item, points, learning_item_type, capsule_type } = item;
  const { tPrefix, titleAttribute } = LEARNING_ITEM_TYPES[
    capsule_type || learning_item_type
  ];
  const _titleAttribute = learningItemTitleAttribute || titleAttribute;

  const { isLocked, success } = useTrainingsStore(
    useCallback(
      state =>
        state.learningItems[idTraining].find(
          li => li.id_learning_item === id_learning_item
        ) || { isLocked: false, success: false },
      [idTraining, id_learning_item]
    )
  );

  const updateLearningItem = useTrainingsStore(
    //@ts-ignore
    state => state.updateLearningItem
  );

  const updateTrainingProgress = useTrainingsStore(
    //@ts-ignore
    state => state.updateTrainingProgress
  );

  const onFileOpened = useCallback(async () => {
    //@ts-ignore
    updateLearningItem(idTraining, item.id_learning_item, {
      success: true,
      finished: true
    });

    await publish(NEW_ANALYTICS, {
      learning_item_id: item.id_learning_item,
      learning_item_type,
      learning_set_id: item.learning_set_id,
      training_id: idTraining,
      type: "progress",
      finished: true,
      success: true
    });
  }, [item, idTraining, learning_item_type]);

  const onClick = useCallback(async () => {
    if (!active) return;

    if (isLocked) {
      toast.warning(t("learningSets.learningItems.locked"));
      return;
    }

    await onLearningItemPress(
      item,
      idTraining,
      //@ts-ignore
      updateTrainingProgress,
      success,
      onFileOpened
    );
  }, [
    updateTrainingProgress,
    item,
    idTraining,
    active,
    onFileOpened,
    isLocked,
    success
  ]);

  const renderStarFill = useCallback(() => {
    if (active && !isLocked) {
      return (
        <span className="d-flex align-items-center learning-item__points">
          <SvgIcon
            color={schemes.gray[600]}
            name="star"
            height={15}
            width={15}
          />
          {t("pages.trainings.points_interval", {
            postProcess: "interval",
            count: points
          })}
        </span>
      );
    }

    if (isLocked) {
      return null;
    }

    return (
      <span className="learning-item__available">
        {t("learningSets.learningItems.availableOn", {
          date: moment(startAt).format("DD/MM/YYYY - hh:mm")
        }).toLocaleLowerCase()}
      </span>
    );
  }, [active, points, startAt, t]);

  return (
    <div
      onClick={onClick}
      className={`learning-item ${
        !active ? "learning-item--disabled" : ""
      } p-1`}>
      <div className="learning-item__check mr-1">
        <div
          className={`learning-item__check-circle ${
            success ? "learning-item__check-circle--success" : ""
          }`}>
          <SvgIcon
            name={isLocked ? "lock" : "check"}
            height={18}
            width={18}
            color={
              success
                ? "white"
                : isLocked
                ? schemes.gray[700]
                : schemes.gray[500]
            }
          />
        </div>
      </div>
      <div className="learning-item__content">
        <div>
          <h3
            className={`learning-item__title ${
              !active ? "learning-item__title--disabled" : ""
            }`}>
            {getContentLang(item[_titleAttribute], defaultLang)}
          </h3>
        </div>
        <div className="learning-item__infos">
          <span className="learning-item__type">{t(tPrefix + ".title")}</span>
          {renderStarFill()}
        </div>
      </div>
      <div className="learning-item__button">
        <button>
          <SvgIcon name="next" height={10} width={10} />
        </button>
      </div>
    </div>
  );
};

export default LearningItem;
