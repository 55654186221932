export default {
  common: {
    cancel: "Abbrechen",
    update: "Aktualisierung",
    understood: "Ich habe es verstanden.",
    next: "Weiter",
    agree: "Akzeptieren",
    signout: "Abmelden",
    start: "Start",
    save: "Speichern",
    settingUpWebapp: "Wir bereiten Deine WebApp vor",
    contactUs: "Kontakt aufnehmen",
    loading: "Wird geladen",
    seeMore: "Mehr sehen",
    loadMore: "Mehr laden",
    yes: "Ja",
    no: "Nein",
    open: "Öffnen",
    close: "Schließen",
    french: "Französisch",
    english: "Englisch",
    spanish: "Spanisch",
    italian: "Italienisch",
    german: "Deutsch",
    dutch: "Niederländisch",
    rejoin: "Wieder beitreten"
  },
  languages: {
    fr: "Französisch",
    en: "Englisch",
    es: "Spanisch",
    nl: "Niederländisch",
    de: "Deutsche",
    it: "Italienisch"
  },
  menu: {
    home: "Home",
    trainings: "Ihre Trainings",
    account: "Mein Konto",
    settings: "Einstellungen",
    wall: "Meine Wall"
  },
  user: {
    updateProfilePictureSuccess: "Profilbild aktualisiert!",
    errorUploadProfilePicture:
      "Das Profilbild konnte nicht aktualisiert werden.",
    updateProfileSuccess: "Ihr Profil wurde aktualisiert!",
    errorUpdateProfile: "Ihr Profil konnte nicht aktualisiert werden.",
    updatePasswordSuccess: "Ihr Passwort wurde aktualisiert!",
    errorUpdatePassword: "Ihr Passwort konnte nicht aktualisert werden.",
    firstname: "Vorname",
    lastname: "Nachname"
  },
  amplify: {
    "Code hint":
      "Überprüfen Sie, ob Ihr 4-stelliger Code mit den letzten 4 Zeichen des erhaltenen Links übereinstimmt.",
    signupEmail: "Geben sie ihre E-Mail Adresse ein",
    signupPassword: "Geben Sie Ihr Kennwort ein (mindestens 8 Zeichen",
    "Reset your password": "Ihr Passwort zurücksetzen",
    "Send Code": "Code senden",
    "Code Sent": "Code gesendet",
    "Create Account": "Anmelden",
    successSignIn: "Erfolgreiche Verbindung!",
    successSignOut: "Sie sind abgemeldet",
    errorSignOut: "Abmelden nicht möglich",
    emptyPassword: "Das Passwort muss eingegeben werden",
    "Resend code": "Code erneut senden",
    "Sign in to your account": "Anmelden",
    "Sign in": "Anmelden",
    InvalidEmail: "ungültiges Email-Format",
    inscriptionCode: "Registrierungscode",
    Email: "E-Mail-Adresse",
    "Create a new account": "Ein Konto erstellen",
    "Enter your username": "Nutzername",
    "Enter your email": "Email",
    "Enter your password": "Passwort",
    "Temporary password has expired and must be reset by an administrator.":
      "Ihr temporäres Passwort ist abgelaufen und muss von einem Administrator zurückgesetzt werden.",
    "Use QRCode": "QR-Code scannen",
    Submit: "Senden",
    Send: "Senden",
    "Enter your confirmation code":
      "Bitte geben Sie den per E-Mail erhaltenen Bestätigungscode ein.",
    "Enter your new password": "Neues Passwort",
    "Incorrect username or password.": "Falsches Passwort",
    "User does not exist.": "Falsches Passwort",
    InvalidParameterExceptionEmail: "Das E-Mail-Format ist ungültig",
    UsernameExistsException: "Diese E-Mail Adresse existiert bereits.",
    InvalidPasswordExceptionUppercase:
      "Das Passwort muss mindestens einen Großbuchstaben enthalten",
    InvalidPasswordExceptionLowercase:
      "Das Passwort muss mindestens einen Kleinbuchstaben enthalten",
    InvalidPasswordExceptionNumeric:
      "Das Passwort muss mindestens eine Ziffer enthalten",
    InvalidPasswordExceptionLong:
      "Das Passwort muss mindestens 8 Zeichen lang sein",
    InvalidPasswordExceptionSymbol:
      "Das Passwort muss mindestens ein Sonderzeichen enthalten",
    InvalidParameterExceptionGreaterthanorequal:
      "Das Passwort muss mindestens 8 Zeichen lang sein",
    InvalidParameterExceptionPasswordempty: "Das Passwort ist erforderlich",
    UserNotFoundException: "Benutzer wurde nicht gefunden",
    NotAuthorizedExceptionIncorrectusername: "Benutzer wurde nicht gefunden",
    NotAuthorizedException: "Falsche Email oder Passwort",
    InvalidPasswordExceptionNotLongEnough:
      "Das Passwort muss 6 Zeichen lang sein",
    InvalidPasswordExceptionBadFormat:
      "Ungültiges Passwortformat (darf weder mit einem Leerzeichen beginnen noch enden)",
    InvalidParameterException: "Ein Fehler ist aufgetreten",
    LimitExceededException:
      "Das Versuchslimit wurde überschritten. Bitte versuchen Sie es nach einiger Zeit.",
    InvalidParameterExceptionThereisnoregisteredverifiedemail:
      "Sie können Ihr Passwort nicht ändern, da Ihr Konto nicht überprüft wurde. Bitte wenden Sie sich dazu an Ihren Administrator.",
    InvalidParameterExceptionAlreadyconfirmed:
      "Der Benutzer ist bereits bestätigt.",
    "I've got a code": "Mit Code anmelden",
    "Forget your password?": "Passwort vergessen",
    inscriptionCodeHint: "4-stellige Registrierungscode",
    ssoButtonTitleDoctolib: "Me connecter avec Doctolib",
    "Back doctolib login": "Revenir à la connexion Doctolib",
    Redirection: "Sie werden zu Ihrem Zugangsanbieter weitergeleitet",
    CodeMismatchException: "Ungültiger Bestätigungscode",
    connectionLoading: "Verbindung",
    signUpLoading: "Einschreibung",
    sendingCode: "Senden des Codes",
    changingPassword: "Passwort aktualisieren"
  },
  rightbar: {
    messaging: "Instant Messaging",
    statistics: "Statistiken",
    trainers: "Ihre Trainer",
    formationTime: "Trainings zeit",
    sessionNumber: "Anzahl von Sessionen",
    mainTrainer: "Haupttrainer"
  },
  messaging: {
    title: "Chat",
    chat: "Chat",
    yourTrainers: "Deine Trainer",
    mainTrainer: "Haupttrainer",
    nothingYet: "Starten Sie ein Gespräch",
    loadingChannels: "Nachrichten werden geladen ...",
    writeMessage: "Nachricht",
    sendMessage: "Senden",
    Commands: "Befehle",
    searchingPeople: "Suche nach Benutzern",
    uploadFile: "Datei hinzufügen",
    uploadPhoto: "Bild hinzufügen",
    addFile: "Datei hinzufügen",
    cancel: "Abbrechen",
    chooseAction: "Wählen Sie eine Aktion",
    deleteMessage: "Löschen",
    editMessage: "Bearbeiten",
    reply: "Antworten",
    addReaction: "Reagieren",
    oneReply: "1 Antwort",
    countReplies: "{{ReplyCount}} Antworten",
    loadingMessages: "Nachrichten werden geladen ...",
    editingMessage: "Bearbeiten der Nachricht",
    startThread: "Neues Gespräch beginnen",
    startNewThread: "Starten Sie ein neues Gespräch",
    noChannelsYet: "Sie haben noch keinen Chat",
    noItems: "Keine Artikel",
    messageDeleted: "gelöschte Nachricht",
    sent: "Gesendet",
    openEmojiPicker: "Emojis-Selektor öffnen",
    close: "Schließen",
    countOnline: "{{ watcherCount }} en ligne",
    limitExceeded: "Bitte versuchen Sie es später noch einmal ..."
  },
  api: {
    unknownError: "Ein Fehler ist aufgetreten. Versuchen Sie es erneut",
    errorUploadProfilePicture: "Ihr Profilbild kann nicht geladen werden"
  },
  start: "Start",
  continue: "Fortsetzen",
  signout: "Austragen",
  "Network Error":
    "Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind.",
  codePushUpdateAvailable: "Eine Aktualisierung ist verfügbar",
  codePushUpdateAvailableInstall:
    "Ein Update ist verfügbar. Möchten Sie es jetzt installieren?",
  links: {
    usingLinkInProgress: "Link in Bearbeitung verwenden.",
    linkUsed: "Link verwendet!",
    linkNotAuthorized: "Sie sind nicht berechtigt, diesen Link zu verwenden.",
    linkInternalError: "interner Link-Fehler"
  },
  modals: {
    notifications: {
      title: "Benachrichtigungen",
      empty: "Keine Benachrichtigungen"
    },
    editAccount: {
      title: "Mein Konto ändern"
    },
    rgpd: {
      title: "DSGVO"
    },
    changePassword: {
      title: "Passwort ändern",
      oldPasswordLabel: "Altes Passwort",
      newPasswordLabel: "Neues Passwort",
      newPasswordConfirmLabel: "Neues Passwort bestätigen"
    },
    capsuleReward: {
      points_success_activity_interval:
        "Sie haben diese Aktivität abgeschlossen und Sie erhalten (0){0 Punkt};(1){1 Punkt}; ",
      points_success_activity_plural:
        "Sie haben diese Aktivität abgeschlossen und Sie erhalten {{count}} Punkte ",
      percent_correct: "{{count}}% richtige Antworten",
      minimum_required: "Mindestanforderung: {{count}}%",
      congrats: "Herzliche Glückwünsche!",
      fail: "Schade!",
      reward: "Sie haben diese Aktivität abgeschlossen und gewonnen!",
      failDescription:
        "Diesmal haben Sie es leider nicht geschafft. Versuchen Sie erneut, um diese Kapsel zu validieren!"
    }
  },
  pages: {
    help: {
      upgrade: "Aktualisieren",
      appUpgradeNeededTitle:
        "Diese App spiegelt das Bild der Lernenden wider, sie entwickelt sich weiter! 🎉",
      appUpgradeNeeded:
        "Für eine bessere Lernerfahrung laden wir Sie ein, diese Anwendung zu aktualisieren.",
      goBackTrainings: "Zurück zu den Trainingskursen",
      linkChar:
        'Wenn Sie nicht auf den Registrierungsbildschirm zugreifen konnten, geben Sie die letzten vier Zeichen des Einladungslinks in das Feld unten ein. (Bsp: "abcd" für den folgenden Link https://{{brand}}.page.link/abcd).',
      emptyCode: "Das Feld kann nicht leer sein.",
      codeNotFound: "Wir konnten diesen Registrierungslink nicht finden.",
      verifyLink: "Prüfen",
      titleNotConfirmed: "Ihr Konto ist nicht bestätigt.",
      title: "Wir können Ihr Konto nicht finden.🕵️",
      signupCodeTitle: "falscher Registrierungscode",
      signupCodeText:
        "Überprüfen Sie, ob der eingegebene 4-stellige Code korrekt ist. Wenn ja, kontaktieren Sie uns, indem Sie auf die untenstehenden  Schaltfläche klicken!",
      titleExists: "Dieses Konto besteht bereits 🔍",
      noPanic: "Keine Panik 🧘🏽♂",
      alreadySignup:
        "Wenn Sie sich noch nie mit dieser E-Mail registriert haben, wenden Sie sich bitte an den Administrator, damit er ein neues Konto für Sie einrichten kann.",
      alreadySignupConfirmCode:
        'Wenn Ihr Konto nicht bestätigt ist und Sie dies tun möchten, klicken Sie auf "Code bestätigen" und dann auf "Code erneut senden".',
      didSignupError:
        "Wenn Sie ein Problem bei der Registrierung haben, können Sie uns kontaktieren, indem Sie auf die Schaltfläche unten klicken.",
      tryLoginAgain: "Erneuter Verbindungsversuch",
      codeSent: "Wir haben Ihnen gerade einen Code zurückgeschickt.",
      emailSubject: "Problem bei der Registrierung {{brand}}",
      emailBody:
        "Hallo, \n\nIch kann mich nicht mit meiner E-Mail anmelden: {{username}}.\n\nKönnen Sie mir helfen?\n \n Danke schön"
    },
    messaging: {
      title: "Instant Messaging"
    },
    account: {
      title: "Mein Konto",
      editButton: "Meine Informationen ändern",
      trainingTime: "Trainingszeit",
      points: "Erfahrungspunkte",
      sessionNumber: "Sessionen dieser Woche",
      time_per_user_format_hours: "{{hours}}h{{minutes}}",
      time_per_user_format_minutes: "{{}minutes}min",
      statsLoadError:
        "Wir konnten Ihre Statistiken nicht laden. Versuchen Sie später, wiederzukommen"
    },
    settings: {
      title: "Einstellungen",
      changePassword: "Passwort ändern",
      changeLanguage: "Sprache ändern",
      infosRGPD: "DSGVO Informationen",
      toggleNotifications: "Benachrichtigungen"
    },
    trainings: {
      title: "Ihre Trainings",
      refreshListFail: "Das Laden Ihrer Kurse ist fehlgeschlagen",
      noTraining: "Kein Training!",
      participants_interval: "(0){Es gibt keine Teilnehmer};(1){1 Teilnehmer};",
      participants_plural: "{{count}} Teilnehmer",
      points_interval: "(0){0 Punkt};(1){1 Punkt};",
      points_plural: "{{count}} Punkte",
      training_not_started: "Diese Schulung wird am {{date}} verfügbar sein"
    },
    wall: {
      title: "Nachrichten",
      loadingFail: "Wir können Ihre Post im Moment nicht hochladen.",
      commentsCount: "{{count}} Kommentar",
      commentsCount_plural: "{{count}} Kommentare",
      likes: "{{count}} Gefällt mir",
      otherLikes:
        "{{name}} et {{count}} weitere Person haben auf einen Beitrag reagiert.",
      otherLikes_plural:
        "{{name}} et {{count}} weitere Personen haben auf einen Beitrag reagiert.",
      otherLikesPeople_1: "Es gefällt {{firstUser}} ",
      otherLikesPeople_2: "Es gefällt {{firstUser}} und {{secondUser}}",
      otherLikesPeople_3:
        "Es gefällt {{firstUser}}, {{secondUser}} und {{thirdUser}}",
      commentBoxPlaceholder: "Ihr Kommentar",
      seeLikes: "Sehen",
      loadMoreComments: "Mehr Kommentare laden",
      loadMoreLikes: "Mehr Gefällt mir laden",
      newPosts_interval: "(1){1 neuer Beitrag}",
      newPosts_plural: "{{count}} neue Beiträge",
      likesModal: {
        title: "({{count}}) Gefällt mir"
      },
      firstToComment: "Seien Sie der erste, der einen Kommentar abgibt!",
      start: "Start",
      noPosts: "Es gibt noch keine Beiträge",
      loadMorePosts: "Mehr Beiträge laden"
    },
    search: {
      title: "Suche ",
      refreshListFail: "Die Suche ist fehlgeschlagen",
      noResult: "Keine Ergebnisse gefunden",
      searchPlaceholder: "Inhalt suchen",
      filters: {
        keywords: "Stichwortsuche",
        training: "Nach Training filtern",
        types: {
          capsules: "Kapseln",
          videos: "Videos",
          files: "Dateien"
        }
      }
    }
  },
  learningSets: {
    learningItems: {
      locked: "Beenden Sie die vorherige Aktivität",
      availableOn: "Verfügbar von {{date}}",
      capsule: {
        title: "Kapsel"
      },
      test: {
        title: "Test"
      },
      video: {
        title: "Video"
      },
      scorm: {
        title: "SCORM-Paket"
      },
      file: {
        title: "Datei"
      },
      practice: {
        title: "Übung",
        moreTime: "Ich brauche noch etwas Zeit",
        done: "Ich bin fertig!",
        confirm: "Ich bestätige",
        alertTitle: "Sind Sie sicher?",
        alertText:
          "Ich bestätige, dass ich mit dieser Übung fertig bin. Wenn mein Trainer um eine schriftliche Übung bittet, habe ich ihm diese bereits geschickt. "
      }
    }
  },
  oneSignal: {
    bellPrompt: {
      "tip.state.unsubscribed": "Benachrichtigungen aktivieren",
      "tip.state.subscribed": "Sie haben die Benachrichtigungen aktiviert.",
      "tip.state.blocked": "Sie haben die Benachrichtigungen deaktiviert.",
      "message.prenotify": "Hier klicken um Benachrichtigungen zu aktivieren",
      "message.action.subscribed":
        "Sie haben die Benachrichtigungen aktiviert!",
      "message.action.resubscribed":
        "Sie haben die Benachrichtigungen aktiviert!",
      "message.action.unsubscribed":
        "Sie haben die Benachrichtigungen deaktiviert.",
      "dialog.main.title": "Benachrichtigungen",
      "dialog.main.button.subscribe": "Angemeldet",
      "dialog.main.button.unsubscribe": "Abmelden",
      "dialog.blocked.title": "Benachrichtigungen entsperren",
      "dialog.blocked.message":
        "Folgen Sie den Anweisungen, um Benachrichtigungen zu entsperren"
    }
  }
};
