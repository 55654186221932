import API from "../api/base";

export const nextActivity = () => API.get("next-activity");

export const search = (
  firstResult: any, // TODO type
  maxResultCount: number,
  keywords: string,
  training: any, // TODO type
  learningItemTypes: any // TODO type
) =>
  API.get("learning-items/search", {
    firstResult,
    maxResultCount,
    keywords:
      keywords && keywords.trim() !== "" ? keywords.split(" ") : undefined,
    trainingId: training ? training.id_training : null,
    learningItemTypes
  });
