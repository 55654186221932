export default {
  common: {
    cancel: "Annuleren",
    update: "Vernieuwen",
    understood: "ik snap het",
    next: "De volgende",
    agree: "Accepteren",
    signout: "Afmelden",
    start: "Beginnen",
    save: "Opnemen",
    settingUpWebapp: "Wij bereiden uw webapp",
    contactUs: "Neem contact met ons op",
    loading: "Bezig met laden",
    seeMore: "Bekijk meer",
    loadMore: "meer laden",
    yes: "Ja",
    no: "Neen",
    open: "Openen",
    close: "sluiten",
    french: "Frans",
    english: "Engels",
    spanish: "Spaans",
    italian: "Italiaans",
    german: "Duitse",
    dutch: "Nederlands",
    rejoin: "Doe opnieuw mee"
  },
  languages: {
    fr: "Frans",
    en: "Engels",
    es: "Spaans",
    nl: "Nederlands",
    de: "Duitse",
    it: "Italienisch"
  },
  menu: {
    home: "Ontvangst",
    trainings: "Uw training",
    account: "Mijn account",
    settings: "Instellingen",
    wall: "Mijn Wall"
  },
  user: {
    updateProfilePictureSuccess: "Geüpdatet profiel!",
    errorUploadProfilePicture: "Onmogelijk om de foto bij te werken.",
    updateProfileSuccess: "Uw profiel is bijgewerkt!",
    errorUpdateProfile: "Kan uw profiel niet bijwerken.",
    updatePasswordSuccess: "Uw wachtwoord is geüpdatet !",
    errorUpdatePassword: "Kan uw wachtwoord niet bijwerken.",
    firstname: "Voornaam",
    lastname: "Naam"
  },
  amplify: {
    "Code hint":
      "Controleer of uw 4-cijferige code is de laatste 4 cijfers van de link die u heeft ontvangen.",
    signupEmail: "Vul uw e-mail",
    signupPassword: "Voer uw wachtwoord in (minimaal 8 tekens)",
    "Reset your password": "Stel je wachtwoord opnieuw in",
    "Send Code": "Code verzenden",
    "Code Sent": "Code verzonden",
    "Create Account": "registreren",
    successSignIn: "Succesvolle verbinding!",
    successSignOut: "Je bent offline",
    errorSignOut: "Kon niet uitloggen",
    emptyPassword: "Het wachtwoord moet worden ingevoerd",
    "Resend code": "Zet de code",
    "Sign in to your account": "Verbinding",
    "Sign in": "Verbinding",
    InvalidEmail: "ongeldige email formaat",
    inscriptionCode: "Registratiecode",
    Email: "E-mail adres",
    "Create a new account": "Account aanmaken",
    "Enter your username": "gebruikersnaam",
    "Enter your email": "E-mail",
    "Enter your password": "Wachtwoord",
    "Temporary password has expired and must be reset by an administrator.":
      "Uw tijdelijke wachtwoord is verlopen en moet opnieuw door een beheerder zijn",
    "Use QRCode": "Gebruik een QR Code",
    Submit: "sturen",
    Send: "sturen",
    "Enter your confirmation code": "Bevestigingscode ontvangen via e-mail.",
    "Enter your new password": "nieuw paswoord",
    "Incorrect username or password.": "Incorrect wachtwoord",
    "User does not exist.": "Incorrect wachtwoord",
    InvalidParameterExceptionEmail: "Ongeldige email formaat",
    UsernameExistsException: "Dit email adres bestaat al.",
    InvalidPasswordExceptionUppercase:
      "Het wachtwoord moet hoofdletters hebben",
    InvalidPasswordExceptionLowercase: "Het wachtwoord moet kleine letters",
    InvalidPasswordExceptionNumeric: "Het wachtwoord moet nummers",
    InvalidPasswordExceptionLong: "Het wachtwoord moet minstens 8 tekens",
    InvalidPasswordExceptionSymbol:
      "Het wachtwoord moet speciale tekens hebben",
    InvalidParameterExceptionGreaterthanorequal:
      "Het wachtwoord moet minstens 8 tekens",
    InvalidParameterExceptionPasswordempty: "Het wachtwoord is vereist",
    UserNotFoundException: "Gebruiker niet gevonden",
    NotAuthorizedExceptionIncorrectusername: "Gebruiker niet gevonden",
    NotAuthorizedException: "Incorrecte email of wachtwoord",
    InvalidPasswordExceptionNotLongEnough:
      "Het woord wachtwoord moet bestaan ​​uit 6 karakters",
    InvalidPasswordExceptionBadFormat:
      "Ongeldig wachtwoordformaat (mag niet beginnen of eindigen met een spatie)",
    InvalidParameterException: "er is een fout opgetreden",
    LimitExceededException:
      "Poginglimiet overschreden, probeer het na enige tijd.",
    InvalidParameterExceptionThereisnoregisteredverifiedemail:
      "U kunt uw wachtwoord niet wijzigen omdat uw account niet is geverifieerd. Neem hiervoor contact op met uw administrator.",
    InvalidParameterExceptionAlreadyconfirmed: "De gebruiker is al bevestigd.",
    "I've got a code": "Registreer Krijg met code",
    "Forget your password?": "Je wachtwoord vergeten",
    inscriptionCodeHint: "registratiecode 4 tekens",
    ssoButtonTitleDoctolib: "Maak contact met Doctolib",
    "Back doctolib login": "Keer terug naar de Doctolib-verbinding",
    Redirection: "U wordt doorgestuurd naar uw toegangsprovider",
    CodeMismatchException: "Ongeldige verificatie code",
    connectionLoading: "Verbinding",
    signUpLoading: "Inschrijving",
    sendingCode: "het verzenden van de code",
    changingPassword: "Wachtwoord bijwerken"
  },
  rightbar: {
    messaging: "messaging",
    statistics: "Statistieken",
    trainers: "je trainers",
    formationTime: "Trainingstijd",
    sessionNumber: "Aantal Sessions",
    mainTrainer: "hoofd coach"
  },
  messaging: {
    title: "Chat",
    chat: "Chat",
    yourTrainers: "Uw trainers",
    mainTrainer: "Hoofdtrainer",
    nothingYet: "Start het gesprek",
    loadingChannels: "Berichten laden ...",
    writeMessage: "Bericht",
    sendMessage: "sturen",
    Commands: "bestellingen",
    searchingPeople: "Zoeken naar gebruikers",
    uploadFile: "Een bestand toevoegen",
    uploadPhoto: "Voeg een foto toe",
    addFile: "Een bestand toevoegen",
    cancel: "Annuleren",
    chooseAction: "Kies een actie",
    deleteMessage: "verwijderen",
    editMessage: "Bewerk",
    reply: "beantwoorden",
    addReaction: "Reageer",
    oneReply: "1 reactie",
    countReplies: "{{}} ReplyCount antwoorden",
    loadingMessages: "Berichten laden ...",
    editingMessage: "editing Message",
    startThread: "Een gesprek beginnen",
    startNewThread: "Begin een nieuw gesprek",
    noChannelsYet: "Je hoeft geen chat",
    noItems: "NIETS",
    messageDeleted: "verwijderde bericht",
    sent: "verzonden",
    openEmojiPicker: "Open emoji selector",
    close: "sluiten",
    countOnline: "{{}} WatcherCount online",
    limitExceeded: "Probeer het later opnieuw ..."
  },
  api: {
    unknownError: "Er is een fout opgetreden. Probeer het opnieuw",
    errorUploadProfilePicture: "Je profielfoto kan niet worden geladen"
  },
  start: "Begin",
  continue: "Doorgaan met",
  signout: "Afmelden",
  "Network Error": "Zorg ervoor dat u verbinding heeft met internet.",
  codePushUpdateAvailable: "Een update is beschikbaar",
  codePushUpdateAvailableInstall:
    "Er is een update beschikbaar, wilt u deze nu installeren?",
  links: {
    usingLinkInProgress: "Met behulp van de huidige koppeling ...",
    linkUsed: "Link gebruikt!",
    linkNotAuthorized: "Het is niet toegestaan ​​om gebruik deze link",
    linkInternalError: "We konden de verbinding niet van toepassing"
  },
  modals: {
    notifications: {
      title: "meldingen",
      empty: "Geen notificaties"
    },
    editAccount: {
      title: "Bewerk klantgegevens"
    },
    rgpd: {
      title: "RGPD"
    },
    changePassword: {
      title: "het wachtwoord te wijzigen",
      oldPasswordLabel: "Oud Wachtwoord",
      newPasswordLabel: "nieuw paswoord",
      newPasswordConfirmLabel: "Bevestig wachtwoord"
    },
    capsuleReward: {
      points_success_activity_interval:
        "U heeft deze activiteit afgerond en verdiende (0) {} 0 punten; (1) {} 1 punt;",
      points_success_activity_plural:
        "U heeft deze activiteit en verdiende {{count}} items voltooid",
      percent_correct: "{{Count}}% correct",
      minimum_required: "Vereisten: {{count}}%",
      congrats: "Gefeliciteerd!",
      fail: "Mislukking",
      reward: "U heeft deze activiteit afgerond en verdiende",
      failDescription:
        "Je hoeft deze keer niet lukken. Probeer deze capsule te bevestigen!"
    }
  },
  pages: {
    help: {
      upgrade: "Update",
      appUpgradeNeededTitle:
        "Deze applicatie is een weerspiegeling van zijn leerlingen, het vordert! 🎉",
      appUpgradeNeeded:
        "Voor een betere leerervaring, werkt u deze toepassing.",
      goBackTrainings: "Keer terug naar Training",
      linkChar:
        'Plooi de laatste vier karakters van de uitnodiging schakel in het vak hieronder als je niet in staat zijn om toegang te krijgen tot de registratie scherm zijn geweest. (Bijvoorbeeld "ABCD" om de volgende koppeling https: // {{}} merk .page.link / ABCD).',
      emptyCode: "Het veld kan niet leeg zijn.",
      codeNotFound: "We hebben niet gevonden de registratie link.",
      verifyLink: "Controleren",
      titleNotConfirmed: "Uw account is niet bevestigd.",
      title: "We kunnen uw account niet vinden 🕵️",
      signupCodeTitle: "verkeerde registratiecode",
      signupCodeText:
        "Controleer of de 4-cijferige code die u hebt ingevoerd correct is. Als dit het geval is, neem dan contact met ons opnemen door te klikken op de onderstaande knop!",
      titleExists: "Dit account bestaat al 🔍",
      noPanic: "Geen paniek 🧘🏽",
      alreadySignup:
        "Als je niet geregistreerd bij deze e-mail kunt u contact opnemen met de beheerder, zodat hij kan u opnieuw te registreren.",
      alreadySignupConfirmCode:
        'Als uw account niet is bevestigd en je wilt doen, klikt u op "Confirm code" en "Return codes".',
      didSignupError:
        "Als u een probleem hebben ondervonden aan te melden kunt u contact met ons opnemen door te klikken op de onderstaande knop.",
      tryLoginAgain: "Re-verbinding probeert te maken",
      codeSent: "We hebben net sturen u een code",
      emailSubject: "Registratie Issue {{brand}}",
      emailBody:
        "Hallo,\n \n\n Ik kan me niet aanmelden met mijn e-mail: {{username}}.\n \n\n Kun je me helpen ?\n \n\n Dankjewel"
    },
    messaging: {
      title: "messaging"
    },
    account: {
      title: "mijn account",
      editButton: "Verander mijn informatie",
      trainingTime: "Trainingstijd",
      points: "Ervaringspunten",
      sessionNumber: "Aantal sessies deze week",
      time_per_user_format_hours: "{{hours}}h{{minutes}}",
      time_per_user_format_minutes: "{{minutes}}minuten",
      statsLoadError:
        "We kunnen uw statistieken niet laden, probeer later terug te komen"
    },
    settings: {
      title: "instellingen",
      changePassword: "het wachtwoord te wijzigen",
      changeLanguage: "De taal wijzigen",
      infosRGPD: "RGPD informatie",
      toggleNotifications: "meldingen"
    },
    trainings: {
      title: "uw training",
      refreshListFail: "Het opladen van uw training is mislukt",
      noTraining: "Geen training in de kaarten!",
      participants_interval:
        "(0){Er zijn geen deelnemer};(1){1 vertegenwoordiger};",
      participants_plural: "{{Count}} deelnemers",
      points_interval: "(0)} {0 punten: (1)} {1 punt;",
      points_plural: "{{Count}} artikelen",
      training_not_started: "Deze training zal beschikbaar zijn op {{date}}"
    },
    wall: {
      title: "Nieuws",
      loadingFail: "We kunnen niet nog uw postings te laden.",
      commentsCount: "{{Count}} Comment",
      commentsCount_plural: "{{Count}} Comments",
      likes: "{{Count}} Ik hou",
      otherLikes: "{{Naam}} en {{count}} else vond",
      otherLikes_plural: "{{Naam}} en {{count}} anderen graag",
      otherLikesPeople_1: "{{}} Zoals FirstUser",
      otherLikesPeople_2: "{{}} FirstUser en {{}} vond secondUser",
      otherLikesPeople_3: "{{FirstUser}}} {{secondUser} en {{}} vond thirdUser",
      commentBoxPlaceholder: "Jouw commentaar",
      seeLikes: "zien",
      loadMoreComments: "Laad meer reacties",
      loadMoreLikes: "Load houd ik meer van",
      newPosts_interval: "(1) {1} nieuwe post;",
      newPosts_plural: "{{Count}} nieuwe berichten",
      likesModal: {
        title: "Kennisgeving I like ({{count}})"
      },
      firstToComment: "Wees de eerste om te reageren !",
      start: "Beginnen",
      noPosts: "Geen van de berichten in de muur",
      loadMorePosts: "Laad meer berichten"
    },
    search: {
      title: "Zoeken",
      refreshListFail: "De zoekopdracht is mislukt",
      noResult: "Geen resultaten",
      searchPlaceholder: "Zoek inhoud",
      filters: {
        keywords: "Zoeken op trefwoorden",
        training: "Filter op training",
        types: {
          capsules: "capsules",
          videos: "Videos",
          files: "bestanden"
        }
      }
    }
  },
  learningSets: {
    learningItems: {
      locked: "Voltooi de voorgaande activiteit",
      availableOn: "Beschikbare datum}}",
      capsule: {
        title: "Capsule"
      },
      test: {
        title: "evaluatie"
      },
      video: {
        title: "Video"
      },
      scorm: {
        title: "scormpakket"
      },
      file: {
        title: "het dossier"
      },
      practice: {
        title: "In de praktijk",
        moreTime: "Ik wat tijd nodig nog",
        done: "Ik ben klaar !",
        confirm: "ik bevestig",
        alertTitle: "Weet je zeker dat ?",
        alertText:
          "Ik bevestig dat ik deze implementatie hebben voltooid. Indien een verslag van mijn trainer wordt gevraagd, stuurde ik hem"
      }
    }
  },
  oneSignal: {
    bellPrompt: {
      "tip.state.unsubscribed": "Aanmelden voor",
      "tip.state.subscribed": "Je bent geabonneerd op meldingen",
      "tip.state.blocked": "Je geblokkeerde meldingen",
      "message.prenotify": "Klik hier om je te abonneren op meldingen",
      "message.action.subscribed": "U geabonneerd op meldingen!",
      "message.action.resubscribed": "U geabonneerd op meldingen!",
      "message.action.unsubscribed": "Je abonnement op meldingen geweest!",
      "dialog.main.title": "meldingen",
      "dialog.main.button.subscribe": "Abonnee",
      "dialog.main.button.unsubscribe": "Afmelden",
      "dialog.blocked.title": "unlock Meldingen",
      "dialog.blocked.message": "Volg de instructies om unlock meldingen:"
    }
  }
};
