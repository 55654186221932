export const getJsScormFunctions = (functions: any) => `
(function() {
window.API_1484_11 = {
  ${Object.keys(functions)
  .map(
    key =>
      `${key}: function () {
        window.WebApp.postMessage(JSON.stringify({
            type: "scorm",
            function: "${key}", params: arguments
          }), '*');
      }`
  )
  .join(",")}
};
})();`;

export const onMessage = (functions: any) => (event: any) => {
  try {
  const _data = JSON.parse(event.data);
  if (_data.function && _data.params && _data.type === "scorm") {
    functions[_data.function](...Object.values(_data.params));
  } } catch (e) {
    // Silent
  }
};
