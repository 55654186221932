export default {
  common: {
    cancel: "Cancel",
    update: "Update",
    understood: "Understood",
    next: "Next",
    agree: "I agree",
    signout: "Sign out",
    start: "Start",
    save: "Save",
    settingUpWebapp: "We're setting up your webapp",
    contactUs: "Contact us",
    loading: "Loading",
    seeMore: "See more",
    loadMore: "Load more",
    yes: "Yes",
    no: "No",
    open: "Open",
    close: "Close",
    french: "French",
    english: "English",
    spanish: "Spanish",
    italian: "Italian",
    german: "German",
    dutch: "Dutch",
    rejoin: "Join"
  },
  languages: {
    fr: "French",
    en: "English",
    es: "Spanish",
    nl: "Netherland",
    de: "German",
    it: "Italian"
  },
  menu: {
    home: "Home",
    trainings: "Trainings",
    account: "Account",
    settings: "Settings",
    wall: "My Wall"
  },
  user: {
    updateProfilePictureSuccess: "Profile picture updated!",
    errorUploadProfilePicture: "Cannot update profile picture.",
    updateProfileSuccess: "Your profile has been updated!",
    errorUpdateProfile: "Unable to update your profile.",
    updatePasswordSuccess: "Your password has been updated !",
    errorUpdatePassword: "Unable to update your password.",
    firstname: "First name",
    lastname: "Last name"
  },
  amplify: {
    "Code hint":
      "Check that your four character code matches the last four characters of the link you received.",
    signupEmail: "Enter your email",
    signupPassword: "Enter your password (8 characters minimum)",
    "Reset your password": "Reset your password",
    "Send Code": "Send Code",
    "Code Sent": "Code sent",
    "Create Account": "Create Account",
    successSignIn: "Successful connection !",
    successSignOut: "You are logged out",
    errorSignOut: "Unable to log out",
    emptyPassword: "Password must be entered",
    "Resend code": "Resend code",
    "Sign in to your account": "Sign in to your account",
    "Sign in": "Sign in",
    InvalidEmail: "Email format invalid",
    inscriptionCode: "Registration code",
    inscriptionCodeHint: "Registration code with 4 characters",
    "I've got a code": "Register with a code",
    Email: "E-mail adress",
    "Create a new account": "Create an account",
    "Enter your username": "Enter your username",
    "Enter your email": "Enter your email",
    "Enter your password": "Enter your password",
    "Temporary password has expired and must be reset by an administrator.":
      "Your temporary password has expired and must be reset by an administrator",
    "Use QRCode": "Use QRCode",
    Submit: "Submit",
    Send: "Send",
    "Enter your confirmation code": "Enter your confirmation code",
    "Enter your new password": "Enter your new password",
    "Incorrect username or password.": "Incorrect password",
    "User does not exist.": "Incorrect password",
    InvalidParameterExceptionEmail: "Email not valid",
    UsernameExistsException: "This email address already exists.",
    InvalidPasswordExceptionUppercase:
      "Password must have at least one uppercase character",
    InvalidPasswordExceptionLowercase:
      "Password must have at least one lowercase character",
    InvalidPasswordExceptionNumeric:
      "Password must have at least one numeric character",
    InvalidPasswordExceptionLong: "Password must be at least 8 characters long",
    InvalidPasswordExceptionSymbol:
      "Password must have at least one special character",
    InvalidParameterExceptionGreaterthanorequal:
      "Password must be at least 6 characters long",
    InvalidPasswordExceptionBadFormat:
      "Invalid password format (must neither start nor end with a space)",
    InvalidParameterExceptionPasswordempty: "Password required",
    UserNotFoundException: "User not found",
    NotAuthorizedExceptionIncorrectusername: "User not found",
    NotAuthorizedException: "Incorrect email or password",
    InvalidPasswordExceptionNotLongEnough:
      "Password must be at least 8 characters long",
    InvalidParameterException: "An error occured",
    LimitExceededException:
      "Attempt limit exceeded, please try after some time.",
    InvalidParameterExceptionThereisnoregisteredverifiedemail:
      "You cannot change your password because your account is not verified. Please contact your administrator to do so.",
    InvalidParameterExceptionAlreadyconfirmed: "The user is already confirmed.",
    "Forget your password?": "Forgot your password ?",
    ssoButtonTitleDoctolib: "Connect with Doctolib",
    "Back doctolib login": "Return to the Doctolib connection",
    Redirection: "You will be redirected to your access provider",
    CodeMismatchException: "Invalid verification code",
    connectionLoading: "Connection",
    signUpLoading: "Signing up",
    sendingCode: "Sending the code",
    changingPassword: "Updating password"
  },
  rightbar: {
    messaging: "Chat",
    statistics: "Statistics",
    trainers: "Your trainers",
    formationTime: "Training time",
    sessionNumber: "Number of sessions",
    mainTrainer: "Principal Coach"
  },
  messaging: {
    title: "Chat",
    chat: "Chat",
    yourTrainers: "Your trainers",
    mainTrainer: "Main trainer",
    nothingYet: "Start to chat",
    loadingChannels: "Loading messages ...",
    writeMessage: "Message",
    sendMessage: "Send",
    Commands: "Commands",
    searchingPeople: "Search users",
    uploadFile: "Add file",
    uploadPhoto: "Add a picture",
    addFile: "Add file",
    cancel: "Cancel",
    chooseAction: "Select an action",
    deleteMessage: "Delete",
    editMessage: "Edit",
    reply: "Reply",
    addReaction: "Add reaction",
    oneReply: "1 answer",
    countReplies: `\\{\\{ replyCount \\}\\} answers`,
    loadingMessages: "Loading messages ...",
    editingMessage: "Editing message",
    startThread: "New thread",
    startNewThread: "Start of a new thread",
    noChannelsYet: "You don't have a chat yet",
    noItems: "No items",
    messageDeleted: "Message deleted",
    sent: "Sent",
    openEmojiPicker: "Open the emoji picker",
    close: "Close",
    countOnline: "{{ watcherCount }} online",
    limitExceeded: "Please try again later ..."
  },
  api: {
    unknownError: "An error occured. Please try again.",
    errorUploadProfilePicture: "Can't load your profile picture"
  },
  start: "Start",
  continue: "Continue",
  signout: "Signout",
  "Network Error": "Please make sure you're connected to the internet.",
  codePushUpdateAvailable: "An update is available",
  codePushUpdateAvailableInstall:
    "An update is available, do you want to install it now ?",
  links: {
    usingLinkInProgress: "Using the current link ...",
    linkUsed: "Link used!",
    linkNotAuthorized: "You are not authorized to use this link",
    linkInternalError: "No pudimos aplicar el enlace"
  },
  modals: {
    notifications: {
      title: "Notifications",
      empty: "No notifications"
    },
    editAccount: {
      title: "Modify my account"
    },
    rgpd: {
      title: "GDPR"
    },
    changePassword: {
      title: "Change password",
      oldPasswordLabel: "Old password",
      newPasswordLabel: "New password",
      newPasswordConfirmLabel: "Confirm your new password"
    },
    capsuleReward: {
      points_success_activity_interval:
        "You completed this activity and earned (0) {0 point}; (1) {1 point};",
      points_success_activity_plural:
        "You completed this activity and earned {{count}} points",
      percent_correct: "{{count}}% of good answers",
      minimum_required: "Minimum requis : {{count}}%",
      congrats: "Congratulations !",
      fail: "Fail",
      reward: "You completed this activity and won",
      failDescription:
        "You did not succeed this time. Try again to validate this capsule!"
    }
  },
  pages: {
    help: {
      upgrade: "Upgrade",
      appUpgradeNeededTitle:
        "This application is like its learners, it is progressing! 🎉",
      appUpgradeNeeded:
        "For a better learning experience, we invite you to update this app.",
      goBackTrainings: "Return to training",
      linkChar: `Enter the last four characters of the invitation link in the field below if you were unable to access the registration screen. (Exemple : "abcd" for the following link https://{{brand}}.page.link/abcd).`,
      emptyCode: "Field can't be empty",
      codeNotFound: "We couldn't find an invitation link.",
      verifyLink: "Check",
      titleNotConfirmed: "Your account is not confirmed.",
      title: "We can't find your account 🕵️‍♀️",
      signupCodeTitle: "Wrong registration code",
      signupCodeText: `Check that the 4-digit code you entered is correct. If so, contact us by clicking on the button below!`,
      titleExists: "This account already exists 🔍",
      noPanic: "Do not panic 🧘🏽‍♂",
      alreadySignup:
        "If you have never registered with this email contact the administrator so that he can re-create an account for you.",
      alreadySignupConfirmCode: `If your account is not confirmed and you wish to do so, click on "Confirm a code" then "Resend a code".`,
      didSignupError:
        "If you have encountered a problem registering you can contact us by clicking on the button below.",
      tryLoginAgain: "Try to connect again",
      codeSent: "We just resend you a code",
      emailSubject: "Registration problem {{brand}}",
      emailBody: `
  Hello,
  
  I can't connect with my email : {{username}}.
  
  Pouvez-vous m'aider ?
  
  Thank's
        `
    },
    messaging: {
      title: "Messaging"
    },
    account: {
      title: "My account",
      editButton: "Update my information",
      trainingTime: "Training time",
      points: "Experience points",
      sessionNumber: "Number of sessions this week",
      time_per_user_format_hours: "{{hours}}h{{minutes}}",
      time_per_user_format_minutes: "{{minutes}}min",
      statsLoadError: "We failed to load your stats, try coming back later"
    },
    settings: {
      title: "Settings",
      changePassword: "Change password",
      changeLanguage: "Change language",
      infosRGPD: "GPDR informations",
      toggleNotifications: "Notifications"
    },
    trainings: {
      title: "Your trainings",
      refreshListFail: "An error occured while loading your trainings",
      noTraining: "No trainings yet !",
      participants_interval: "(0){No learners yet};(1){1 learner};",
      participants_plural: "{{count}} learners",
      points_interval: "(0){0 point};(1){1 point};",
      points_plural: "{{count}} points",
      training_not_started: "This training will be available on {{date}}"
    },
    wall: {
      title: "News",
      loadingFail: "We can't load your posts at the time.",
      commentsCount: "{{count}} comments",
      commentsCount_plural: "{{count}} comments",
      likes: "{{count}} Likes",
      otherLikes: "{{name}} and {{count}} like this post",
      otherLikes_plural: "{{name}} and {{count}} people like this post",
      otherLikesPeople_1: "{{firstUser}} like",
      otherLikesPeople_2: "{{firstUser}} and {{secondUser}} like this",
      otherLikesPeople_3:
        "{{firstUser}}, {{secondUser}} and {{thirdUser}} like this",
      commentBoxPlaceholder: "Your comment",
      seeLikes: "See",
      loadMoreComments: "Load more comments",
      loadMoreLikes: "Load more likes",
      newPosts_interval: "(1){1 new post};",
      newPosts_plural: "{{count}} new posts",
      likesModal: {
        title: "Likes"
      },
      firstToComment: "Be the first to comment !",
      start: "Start",
      noPosts: "No posts in your wall yet.",
      loadMorePosts: "Load more posts"
    },
    search: {
      title: "Search",
      refreshListFail: "An error occured while searching",
      noResult: "Nothing found",
      searchPlaceholder: "Search for content",
      filters: {
        keywords: "Search with keywords",
        training: "Filter by training",
        types: {
          capsules: "Capsules",
          videos: "Videos",
          files: "Files"
        }
      }
    }
  },
  learningSets: {
    learningItems: {
      availableOn: "Available {{date}}",
      locked: "Finish the previous activity",
      capsule: {
        title: "Lesson"
      },
      test: {
        title: "Quiz"
      },
      video: {
        title: "Video"
      },
      scorm: {
        title: "SCORM package"
      },
      file: {
        title: "File"
      },
      practice: {
        title: "Practice",
        moreTime: "I need more time",
        done: "I'm done !",
        confirm: "I confirm",
        alertTitle: "Are you sure ?",
        alertText:
          "I confirm that I have completed this practice. If a report is requested by my trainer, I sent him"
      }
    }
  },
  oneSignal: {
    bellPrompt: {
      "tip.state.unsubscribed": "S'abonner aux notifications",
      "tip.state.subscribed": "Vous êtes abonné aux notifications",
      "tip.state.blocked": "Vous avez bloqué les notifications",
      "message.prenotify": "Cliquer pour vous abonner aux notifications",
      "message.action.subscribed": "Vous vous êtes abonné aux notifications !",
      "message.action.resubscribed":
        "Vous vous êtes abonné aux notifications !",
      "message.action.unsubscribed":
        "Vous vous êtes désabonné des notifications !",
      "dialog.main.title": "Notifications",
      "dialog.main.button.subscribe": "Abonné",
      "dialog.main.button.unsubscribe": "Se désabonner",
      "dialog.blocked.title": "Débloquer les Notifications",
      "dialog.blocked.message":
        "Suivre les instructions pour débloquer les notifications:"
    }
  }
};
