import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { PubSub, Auth, I18n } from "aws-amplify";

import SvgIcon from "../../SvgIcon";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ProfilePicture from "../../ProfilePicture";
import useUserStore, { userSelector } from "../../../stores/users";
import "./styles.scss";
import { IUser } from "../../../../types/users";
import Config from "../../../config";

const TAB_ICON_STACKS_MAP: (
  | "wall"
  | "home"
  | "trainings"
  | "account"
  | "settings"
)[] = Config.get("config.tabsOrder") || [
  "home",
  "trainings",
  "account",
  "settings"
];

const TABS_PROPS_MAP = {
  wall: { langKey: "wall", path: "", exact: true, iconName: "home" },
  home: { langKey: "home", path: "", exact: true, iconName: "home" },
  trainings: {
    langKey: "trainings",
    path: "trainings",
    exact: false,
    iconName: "graduationCap"
  },
  account: {
    langKey: "account",
    path: "account",
    exact: false,
    iconName: "user"
  },
  settings: {
    langKey: "settings",
    path: "settings",
    exact: false,
    iconName: "settings"
  }
};

const Leftbar = ({ style }: { style?: React.CSSProperties }) => {
  const [t] = useTranslation();

  const [authenticatedUser, setAuthenticatedUser] = useState();
  const [userPoints, setUserPoints] = useState(null);
  //@ts-ignore
  const user: IUser = useUserStore(userSelector("user"));

  useEffect(() => {
    if (!authenticatedUser) return () => {};

    const subToTopic = () => {
      return PubSub.subscribe(`analytics/${authenticatedUser}/get`).subscribe({
        next: ({ value }) => {
          const { points } = value;

          setUserPoints(points);
        },
        error: error => {}
      });
    };

    const subscription = subToTopic();

    return () => subscription.unsubscribe();
  }, [authenticatedUser, setUserPoints]);

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      const {
        attributes: { sub }
      } = await Auth.currentAuthenticatedUser();
      setAuthenticatedUser(sub);
    };
    getAuthenticatedUser();
  }, []);

  if (!user) return null;

  const onLogout = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      toast.error(I18n.get("errorSignOut"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    }
  };

  return (
    // @ts-ignore
    <div style={{ ...style }} className="inteach-leftbar">
      <div className="inteach-leftbar__content">
        <div>
          <ProfilePicture user={user} size={60} canEdit />
          <h3 className="inteach-leftbar__title">
            {user.firstname} {user.lastname}
          </h3>
          <span className="inteach-leftbar__points">
            {userPoints !== null ? userPoints : user.points}
            <SvgIcon name="star" height={25} width={25} />
          </span>
          <nav className="inteach-leftbar__nav">
            <ul>
              {TAB_ICON_STACKS_MAP.map(tabKey => {
                const { langKey, path, exact, iconName } = TABS_PROPS_MAP[
                  tabKey
                ];
                return (
                  <li className="inteach-leftbar__link">
                    <NavLink exact={exact} to={`/${path}`}>
                      <SvgIcon name={iconName} /> {t(`menu.${langKey}`)}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>

        <div>
          <a onClick={onLogout} className="cursor-pointer">
            {t("common.signout")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Leftbar);
