import React, { useEffect, useState } from "react";
import Modal from "../modal/BaseModal";
import { useTranslation } from "react-i18next";
import API from "../../api/base";
import { isEmpty } from "lodash";
import moment from "moment";
import "./styles.scss";
import { INotification } from "../../../types/notification";

const Notifications = ({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [t] = useTranslation();
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const getNotifications = async () => {
    try {
      const _notifications: INotification[] = await API.get(
        `/actions/notifications`
      );

      setNotifications(
        _notifications.filter(notification => notification.id_notification)
      );
    } catch (e) {}
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={t("modals.notifications.title")}
      closeBtn>
      {notifications.length ? (
        <div>
          {notifications.map((notification: INotification) => (
            <div
              className="inteach-notifications inteach-notifications--unread"
              key={notification.id_notification}>
              <span className="inteach-notifications__title">
                {notification.title}
              </span>
              <span className="inteach-notifications__content">
                {notification.description}
                <div className="inteach-notifications__dot" />
              </span>
            </div>
          ))}
        </div>
      ) : (
        <p>{t("modals.notifications.empty")}</p>
      )}
    </Modal>
  );
};

export default Notifications;
