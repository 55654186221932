import React from "react";
import { useTranslation } from "react-i18next";
import { default as LoaderSpinner } from "react-loader-spinner";
import "./styles.scss";

const Loader = () => {
  const [t] = useTranslation();

  return (
    <div className="inteach-loader">
      <LoaderSpinner
        type="ThreeDots"
        color="var(--main-color)"
        height={50}
        width={50}
      />
      <h3>{t("common.settingUpWebapp")}</h3>
    </div>
  );
};

export default Loader;
