import React from "react";
import { default as ReactModal } from "react-modal";
import useMedia from "use-media";
import SvgIcon from "../SvgIcon";
import { merge } from "lodash";

interface Size {
  [key: string]: {
    horizontal: number;
    vertical: number;
  };
}

const modalSize: Size = {
  sm: {
    horizontal: 27,
    vertical: 30
  },
  md: {
    horizontal: 20,
    vertical: 25
  },
  lg: {
    horizontal: 15,
    vertical: 20
  },
  full: {
    horizontal: 0,
    vertical: 0
  }
};

const Modal = ({
  isOpen,
  onRequestClose,
  title = "",
  closeBtn,
  children,
  style,
  contentAbsolute = false,
  contentStyle,
  size = "md",
  headerAbsolute = false
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
  closeBtn?: boolean;
  children: any;
  style?: ReactModal.Styles;
  contentAbsolute?: boolean;
  contentStyle?: React.CSSProperties;
  size?: "sm" | "md" | "lg" | "full";
  headerAbsolute?: boolean;
}) => {
  const isSmallDevice = useMedia({ maxWidth: "840px" });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={merge(
        {
          content: {
            top: isSmallDevice ? "0%" : modalSize[size].horizontal + "%",
            bottom: isSmallDevice ? "0%" : modalSize[size].horizontal + "%",
            left: isSmallDevice ? "0%" : modalSize[size].vertical + "%",
            right: isSmallDevice ? "0%" : modalSize[size].vertical + "%",
            padding: 0,
            display: "flex",
            flexDirection: "column",
            borderRadius: 0
          }
        },
        style
      )}>
      {(title || closeBtn) && (
        <div
          className={`inteach-modal__header ${
            headerAbsolute ? "inteach-modal__header--absolute" : ""
          }`}>
          <h2 className="inteach-modal__title">{title}</h2>
          {closeBtn && (
            <button className="inteach-modal__close" onClick={onRequestClose}>
              <SvgIcon name="close" color="#70737b" />
            </button>
          )}
        </div>
      )}
      <div
        className="inteach-modal__content"
        style={{
          ...(contentAbsolute && {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            padding: 0,
            height: "100vh"
          }),
          ...contentStyle
        }}>
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
