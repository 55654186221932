import React, { useState, useCallback, useEffect } from "react";
// @ts-ignore
import { Activity } from "react-activity-feed";
import { omit } from "lodash";
import moment from "moment";

import PostHeader from "./PostHeader";
import PostContent from "./PostContent";
import PostCommentLike from "./PostCommentLike";
import usePubSub, { NEW_ANALYTICS } from "../../hooks/usePubSub";

const BasePost = ({
  activity,
  currentUserId,
  training,
  onToggleReaction,
  beforeLikeComment,
  children,
  type = "wall",
  onActivityClick,
  refreshFeed,
  socket,
  inputCommentRef
}: {
  activity: any;
  currentUserId: any;
  training: any;
  onToggleReaction: any;
  beforeLikeComment?: any;
  children?: any;
  type: "wall" | "full";
  onActivityClick: any;
  refreshFeed: any;
  socket?: any;
  inputCommentRef?: any;
}) => {
  const { publish } = usePubSub();
  const [doLike, setDoLike] = useState(false);
  const [usersReaction, setUsersReaction] = useState({});
  const [currentReaction, setCurrentReaction] = useState({});
  const publishedAt = moment(activity.time).fromNow();
  const commentsCount = activity.reaction_counts["comment"] || 0;
  const likesCount = activity.reaction_counts["heart"] || 0;

  // Watch reactions
  useEffect(() => {
    setCurrentReaction(
      Object.values(usersReaction).find(
        //@ts-ignore
        ({ user }) => user.id === currentUserId
      ) || {}
    );
  }, [usersReaction, currentUserId]);

  // Private render
  const _renderActivity = () => {
    const activityContent = (
      <Activity
        styles={{
          container: {
            borderBottomWidth: 0
          }
        }}
        activity={{
          ...activity
        }}
        Header={
          <PostHeader
            publishedAt={publishedAt}
            training={type === "full" ? training : null}
          />
        }
        Content={
          <PostContent
            activity={activity}
            training={training}
            showTag={type === "wall"}
          />
        }
      />
    );

    if (type === "wall") {
      return <div>{activityContent}</div>;
    }

    return activityContent;
  };

  const onSeeMorePress = useCallback(() => {
    // TODO show modal like list
  }, []);

  const doesUserLikeActivity = useCallback(() => {
    return !!(activity.own_reactions.heart || []).find(
      // @ts-ignore TODO
      ({ activity_id }) => activity_id === activity.id
    );
  }, [activity]);

  const onLikePress = useCallback(async () => {
    const training_id = activity.training
      ? activity.training
      : activity.actor.split("training:")[0];

    onToggleReaction("heart", activity);

    await publish(NEW_ANALYTICS, {
      social_id: activity.id,
      training_id,
      type: "commitment"
    });

    // Remove from reactions
    if (doLike) {
      // @ts-ignore
      setUsersReaction(u => omit(u, [currentReaction.id]));
    }

    setDoLike(!doLike);
    refreshFeed();
  }, [activity, doLike, currentReaction, onToggleReaction, refreshFeed]);

  const onCommentPress = useCallback(() => {}, []);

  return (
    <>
      {_renderActivity()}
      {beforeLikeComment()}

      <PostCommentLike
        activity={activity}
        currentUserId={currentUserId}
        onSeeMorePress={onSeeMorePress}
        onLikePress={onLikePress}
        onCommentPress={onCommentPress}
        doLike={doesUserLikeActivity()}
        likesCount={likesCount}
        commentsCount={commentsCount}
        refreshFeed={refreshFeed}
      />

      {children}
    </>
  );
};

export default BasePost;
