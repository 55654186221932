import React, { useState } from "react";
import Content from "../../components/layout/Content";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TrainingsList from "../../components/trainings/TrainingsList";
import { ITraining } from "../../../types/training";

const Trainings = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Content title={t("pages.trainings.title")}>
      <TrainingsList
        onItemClick={(training: ITraining) => {
          history.push("/trainings/" + training.id_training, {
            learnerCount: training.learnerCount
          });
        }}
        all={true}
      />
    </Content>
  );
};

export default Trainings;
