import React from "react";
import LearningItem from "./LearningItem";
import "./styles.scss";
import { getContentLang } from "../../utils/i18n";
import { ITranslation } from "../../../types";
import { ILearningItem } from "../../../types/learningItem";

const LearningSet = ({
  items,
  title,
  learningItemTitleAttribute,
  idTraining,
  defaultLang
}: {
  items: any;
  title: ITranslation;
  learningItemTitleAttribute?: any;
  idTraining: string;
  userProgress: any;
  defaultLang?: string;
}) => {
  return (
    <div className="learning-set">
      <div className="learning-set__title mt-1 p-1">
        <h4>{getContentLang(title, defaultLang)}</h4>
      </div>
      {items.map(
        (
          item: ILearningItem & {
            active: boolean;
            startAt: string;
          }
        ) => (
          <LearningItem
            item={item}
            active={item.active}
            startAt={item.startAt}
            learningItemTitleAttribute={learningItemTitleAttribute}
            idTraining={idTraining}
            key={item.id_learning_item}
          />
        )
      )}
    </div>
  );
};

export default LearningSet;
