import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";

import Modal from "./BaseModal";
import CapsuleReward from "./CapsuleReward";
import usePubSub, { NEW_ANALYTICS } from "../../hooks/usePubSub";
import useTrainingsStore from "../../stores/trainings";
import { generateRandomId } from "../../utils";

const VideoModal = ({
  url,
  learning_item_id,
  learning_item_type,
  learning_set_id,
  training_id,
  points,
  success = false
}: {
  url: string;
  learning_item_id: string;
  learning_item_type: any;
  learning_set_id: string;
  training_id: string;
  points: number;
  success?: boolean;
}) => {
  const updateLearningItem = useTrainingsStore(
    state => state.updateLearningItem
  );
  const { publish } = usePubSub();

  const emitData = {
    tracking_id: generateRandomId(),
    learning_item_id,
    learning_item_type,
    learning_set_id,
    training_id,
    type: "time"
  };

  const closeModal = useCallback(() => {
    if (success) {
      // @ts-ignore
      ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
    } else {
      ReactDOM.render(
        // @ts-ignore
        React.createElement(CapsuleReward, {
          isCapsule: false,
          success: true,
          points
        }),
        document.getElementById("modal")
      );
    }
  }, [points]);

  //@ts-ignore
  useEffect(() => {
    const sendAnalytics = async () => {
      await publish(NEW_ANALYTICS, {
        ...emitData,
        start: new Date()
      });
    };

    sendAnalytics();

    return async () => {
      updateLearningItem(training_id, learning_item_id, {
        success: true,
        finished: true
      });

      await Promise.all([
        publish(NEW_ANALYTICS, {
          ...emitData,
          type: "progress",
          finished: true,
          success: true
        }),
        publish(NEW_ANALYTICS, {
          ...emitData,
          type: "points",
          success: true
        }),
        publish(NEW_ANALYTICS, {
          ...emitData,
          end: new Date()
        })
      ]);

      closeModal();
    };
  }, [closeModal, emitData, learning_item_id, training_id]);

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => closeModal()}
      style={{
        content: {
          border: "none",
          borderRadius: 0
        }
      }}
      contentStyle={{
        padding: 0
      }}
      closeBtn>
      <video
        onEnded={closeModal}
        src={url}
        controls
        style={{ width: "100%", height: "100%" }}
        autoPlay></video>
    </Modal>
  );
};

export default VideoModal;
