import React, { useCallback, useEffect, useState } from "react";
import Content from "../../components/layout/Content";
import Post from "../../components/Post";
// @ts-ignore
import { StreamApp, FlatFeed } from "react-activity-feed";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import Lottie from "react-lottie";
import * as UserAPI from "../../api/users";
import LoadMorePaginator from "../../components/getStream/paginator/LoadMorePaginator";
import Config from "../../config";

const Home = ({ streamToken }: { streamToken?: string }) => {
  const [t] = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [hasPosts, setHasPosts] = useState(true);

  const feedOptions = {
    withOwnReactions: true,
    withRecentReactions: true,
    recentReactionsLimit: 20,
    refresh: true,
    limit: 10
  };

  const noop = useCallback(() => {}, []);

  const _getCurrentUser = async () => {
    const _user = await Auth.currentAuthenticatedUser();
    setCurrentUser(_user);
  };

  const _getPostsCount = async () => {
    try {
      const _hasPosts = await UserAPI.hasPosts();
      setHasPosts(_hasPosts);
    } catch (e) {}
  };

  useEffect(() => {
    _getPostsCount();
    _getCurrentUser();
  }, []);

  const renderNotFound = useCallback(() => {
    if (hasPosts) return null;

    return (
      <div className="text-center">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: require("../../resources/posts_notfound.json")
          }}
          width={"50%"}
          height={"50%"}
        />
        <h4>{t("pages.wall.noPosts")}</h4>
      </div>
    );
  }, [hasPosts, t]);

  if (!streamToken) return renderNotFound();

  return (
    <StreamApp
      apiKey={Config.get("config.getStream.apiKey")}
      appId={Config.get("config.getStream.appId")}
      token={streamToken}>
      <Content title={t("pages.wall.title")}>
        {renderNotFound()}
        <FlatFeed
          options={feedOptions}
          // TODO Notifier={Notifier}
          notify
          Paginator={(props: any) => (
            <LoadMorePaginator
              {...props}
              textButton={t("pages.wall.loadMorePosts")}
            />
          )}
          doFeedRequest={async (client: any, feedGroup: any, userId: string, options: any) =>
            UserAPI.getFeed(feedGroup, options)
          }
          Activity={(activityArgs: any) => {
            return (
              <Post
                {...activityArgs}
                currentUser={currentUser}
                onRefresh={noop}
              />
            );
          }}
        />
      </Content>
    </StreamApp>
  );
};

export default Home;
