import React, { CSSProperties } from "react";

const Card = ({
  children,
  style,
  onClick,
  className = ""
}: {
  children: any,
  style?: CSSProperties,
  onClick?: () => any,
  className?: string
}) => {
  return (
    <div
      className={`inteach-card ${className}`}
      style={style}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
