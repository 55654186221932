/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from "react";

import { I18n, ConsoleLogger as Logger } from "@aws-amplify/core";

import AuthPiece, { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import { toast } from "react-toastify";
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Button,
  Link
} from "aws-amplify-react";
import {
  InputLabel,
  FormField,
  Input,
  SectionFooterPrimaryContent
} from "aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React";

import { Auth } from "@aws-amplify/auth";
import Loader from "react-loader-spinner";
import LoadingText from "../Loader/LoadingText";
const logger = new Logger("ForgotPassword");

export interface IForgotPasswordState extends IAuthPieceState {
  isLoading: boolean;
  delivery: any;
  isSending: boolean;
}

export default class ForgotPassword extends AuthPiece<
  IAuthPieceProps,
  IForgotPasswordState
> {
  constructor(props: IAuthPieceProps) {
    super(props);

    this.send = this.send.bind(this);
    this.submit = this.submit.bind(this);

    this._validAuthStates = ["forgotPassword"];
    this.state = { isLoading: false, delivery: null, isSending: false };
  }

  async send() {
    if (this.state.isLoading || this.state.isSending) return;
    this.setState({ isSending: true });
    const { authData = {} } = this.props;
    const username = this.getUsernameFromInput() || authData.username;
    if (!Auth || typeof Auth.forgotPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    try {
      const data = await Auth.forgotPassword(username);
      logger.debug(data);
      this.setState({ ...this.state, delivery: data.CodeDeliveryDetails });
      toast(I18n.get("Code Sent"), { position: "bottom-right" });
    } catch (err) {
      this.error(err);
    }
    this.setState({ isSending: false });
  }

  async submit() {
    if (this.state.isLoading) return;
    const { authData = {} } = this.props;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || authData.username;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    this.setState({ ...this.state, isLoading: true });
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, password);
      logger.debug(data);
      this.changeState("signIn");
      this.setState({ ...this.state, delivery: null });
    } catch (err) {
      this.error(err);
    }
    this.setState({ ...this.state, isLoading: false });
  }

  sendView() {
    const theme = this.props.theme;
    return <div>{this.renderUsernameField(theme)}</div>;
  }

  submitView() {
    const theme = this.props.theme;
    return (
      <div>
        <FormField theme={theme}>
          <InputLabel theme={theme}>{I18n.get("Code")} *</InputLabel>
          <Input
            placeholder={I18n.get("Code")}
            theme={theme}
            key="code"
            name="code"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </FormField>
        <FormField theme={theme}>
          <InputLabel theme={theme}>{I18n.get("New Password")} *</InputLabel>
          <Input
            placeholder={I18n.get("New Password")}
            theme={theme}
            type="password"
            key="password"
            name="password"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </FormField>
      </div>
    );
  }

  showComponent(theme: any) {
    const { authState, hide, authData = {} } = this.props;
    if (hide && hide.includes(ForgotPassword)) {
      return null;
    }

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get("Reset your password")}
        </SectionHeader>
        <SectionBody theme={theme}>
          {this.state.delivery || authData.username
            ? this.submitView()
            : this.sendView()}
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            {this.state.delivery || authData.username ? (
              <Button
                theme={theme}
                onClick={this.submit}
                title={I18n.get("Submit")}>
                {this.state.isLoading ? (
                  <Loader
                    type="ThreeDots"
                    color="#F8F8F8"
                    height={25}
                    width={35}
                  />
                ) : (
                  I18n.get("Submit")
                )}
              </Button>
            ) : (
              <Button
                theme={theme}
                title={I18n.get("Send Code")}
                onClick={async () => {
                  if (this.state.isLoading) return;
                  this.setState({ ...this.state, isLoading: true });
                  await this.send();
                  this.setState({ ...this.state, isLoading: false });
                }}>
                <LoadingText
                  title={I18n.get("Send Code")}
                  loadingTitle={I18n.get("sendingCode")}
                  isLoading={this.state.isLoading}
                />
              </Button>
            )}
          </SectionFooterPrimaryContent>
          <div className="mt-1 d-flex justify-content-space-betwee align-items-center">
            <span className="text text--small">
              <Link
                theme={theme}
                onClick={() => {
                  this.setState({ delivery: null });
                  this.changeState("signIn");
                }}
                title={I18n.get("Back to Sign In")}>
                {I18n.get("Back to Sign In")}
              </Link>
            </span>
            <span
              className={
                this.state.isSending ? "loader-container" : "text text--small"
              }>
              {(this.state.delivery || authData.username) &&
              !this.state.isSending ? (
                <Link
                  theme={theme}
                  onClick={this.send}
                  title={I18n.get("Resend Code")}>
                  {I18n.get("Resend Code")}
                </Link>
              ) : null}
              {(this.state.delivery || authData.username) &&
              this.state.isSending ? (
                <Loader
                  type="TailSpin"
                  color={theme.a.color}
                  height={40}
                  width={40}
                />
              ) : null}
            </span>
          </div>
        </SectionFooter>
      </FormSection>
    );
  }
}
