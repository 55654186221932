import React, { useEffect, useState, useRef, useCallback } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import {
  Home,
  Search,
  Training,
  Trainings,
  Settings as SettingsPage,
  Link,
  Account
} from "./pages";
import Leftbar from "./components/layout/Leftbar";
import Topbar from "./components/layout/Header";
import Rightbar from "./components/layout/Rightbar";
// @ts-ignore
import { StreamApp } from "react-activity-feed";
import Config from "./config";
import Modal from "react-modal";
import {
  Authenticator,
  // SignIn,
  ConfirmSignIn,
  ConfirmSignUp,
  UsernameAttributes,
  VerifyContact
} from "aws-amplify-react";
import authTheme from "./config/authTheme";
import * as UserAPI from "./api/users";
import AcademySignIn from "./components/amplify/AcademySignIn";
import Drawer from "./components/layout/Drawer";
import Tabbar from "./components/layout/Tabbar";
import Messaging from "./pages/Messaging";
import { StreamChat } from "stream-chat";
import { useTranslation } from "react-i18next";
import { getStreami18n } from "./utils/stream";
import PrivateRoute from "./components/auth/PrivateRoute";
import { Auth, Hub } from "aws-amplify";
import { initUser } from "./utils/UserUtils";
import { toast } from "react-toastify";
import { signUpWithOnlyEmail } from "./components/amplify/signUpFields";
import queryString from "query-string";
import OnBoardingModal from "./components/modal/OnBoardingModal";
import Loader from "./components/Loader";
import Help from "./components/Help";
import AcademySignUp from "./components/amplify/AcademySignUp";
import AcademyForgotPassword from "./components/amplify/AcademyForgotPassword";
import AcademyRequireNewPassword from "./components/amplify/AcademyRequireNewPassword";
import { getIdentifierFromLinkCode } from "./api/generic";

const SSO_PROVIDER = Config.get("config.ssoProvider");
const IDENTIFIER_STORAGE_KEY = "identifier";

const defaultRoute = Config.get("config.tabsOrder")?.length
  ? `/${Config.get("config.tabsOrder")[0]}`
  : "/";

const AppMain = ({ chatClient }: { chatClient: StreamChat }) => {
  Modal.setAppElement("#root");
  const [t] = useTranslation();
  const [chatToken, setChatToken] = useState();
  const [streamToken, setStreamToken] = useState(undefined);
  const [webAppIsReady, setWebAppIsReady] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [redirect, setRedirect] = useState(defaultRoute);
  const [identifier, setIdentifier] = useState();
  const [needOnBoarding, setNeedOnBoarding] = useState(false);
  const [missingAttributes, setMissingAttributes] = useState();
  const [authState, setAuthState] = useState("signIn");
  const [isReady, setReady] = useState(false);
  const [userId, setUserId] = useState("");
  const location = useLocation();
  const authRef = useRef();
  const streami18n = getStreami18n(t);

  const checkIsLogged = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsLogged(true);
    } catch (e) {
      setIsLogged(false);
    }
  };

  useEffect(() => {
    checkIsLogged();
  }, []);

  const setupChat = useCallback(
    async (_chatToken: string) => {
      chatClient.key = Config.get("config.getStream.appKey");
      const {
        attributes: { sub: username }
      } = await Auth.currentAuthenticatedUser();

      try {
        // Set user
        await chatClient.setUser(
          {
            id: username
          },
          _chatToken
        );

        const channels = await chatClient.queryChannels(
          {
            type: "messaging",
            members: { $in: [username] }
          },
          { last_message_at: -1 },
          {}
        );

        await Promise.all(channels.map(channel => channel.watch()));
      } catch (e) {}

      setUserId(username);
      setReady(true);
    },
    [chatClient]
  );

  useEffect(() => {
    const handleLocation = async () => {
      const params = queryString.parse(location.search);
      if (params.loginAs) {
        //@ts-ignore
        localStorage.setItem("loginAs", params.loginAs);
      }
      if (params.code === "logout") {
        window.location.replace("/auth");
      }
      if (params.redirect) {
        setRedirect(params.redirect.toString());
      }

      if (params.identifier) {
        const stringIdentifier = params.identifier.toString();

        // For SSO we store this identifier into the storage given there is a redirection we lose the state
        localStorage.setItem(IDENTIFIER_STORAGE_KEY, stringIdentifier);
        setIdentifier(stringIdentifier);

        try {
          // Dirty hack to handle previous generated links
          const { organization_id } = await getIdentifierFromLinkCode(
            stringIdentifier
          );

          // Linguish related
          if (
            window.location.host.match(/(inteach\.com|localhost)/) &&
            organization_id === "8d84924c-2cb5-4c23-953d-b93c14feb208"
          ) {
            window.location.href =
              "https://webapp.linguish.fr/auth?identifier=" + params.identifier;
          }
          // Doctolib
          else if (organization_id === "4aad2d4b-1776-463e-a4d2-261b581ca9db") {
            if (!isLogged) Auth.federatedSignIn({ provider: SSO_PROVIDER });
          } else {
            //@ts-ignore
            if (!isLogged) authRef.current?.handleStateChange("signUp");
          }
        } catch {}
      }
      setDrawerIsOpen(false);
    };

    handleLocation();
  }, [location, authRef, isLogged]);

  const prepareWebApp = useCallback(async () => {
    setWebAppIsReady(false);
    // Identifier is stored into the localStorage for the SSO provider
    const currentIdentifier = SSO_PROVIDER
      ? localStorage.getItem(IDENTIFIER_STORAGE_KEY)
      : identifier;

    if (currentIdentifier) {
      toast.success(t("links.usingLinkInProgress"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    }

    if (isLogged) {
      const { identityId: IdentityId } = await Auth.currentCredentials();

      try {
        const {
          chatToken: _chatToken,
          streamToken: _streamToken,
          linkSuccess,
          attributes,
          firstname,
          lastname,
          rgpd
        } = await UserAPI.prepare({
          identifier: currentIdentifier,
          IdentityId
        });

        setStreamToken(_streamToken);

        // Remove identifier from storage, if there is none then it won't throw an error
        localStorage.removeItem(IDENTIFIER_STORAGE_KEY);

        await initUser();

        const _needOnBoarding = !((lastname || firstname) && rgpd);
        setMissingAttributes(attributes);
        setNeedOnBoarding(_needOnBoarding);

        setChatToken(_chatToken);
        await setupChat(_chatToken);

        if (currentIdentifier) {
          if (typeof linkSuccess === "string") {
            toast.success(
              t(`links.${linkSuccess}`, {
                position: "bottom-right",
                hideProgressBar: true
              })
            );
          } else if (!linkSuccess) {
            toast.error(t("links.linkInternalError"), {
              position: "bottom-right",
              hideProgressBar: true
            });
          } else {
            toast.success(t("links.linkUsed"), {
              position: "bottom-right",
              hideProgressBar: true
            });
          }
        }
      } catch (e) {
        console.log(e);
        toast.error(t("api.unknownError"), {
          position: "bottom-right",
          hideProgressBar: true
        });
      }
    }

    setWebAppIsReady(true);
  }, [identifier, isLogged, setupChat, t]);

  const renderRightBar = useCallback(() => {
    return (
      <Rightbar
        client={chatClient}
        isReady={isReady}
        streami18n={streami18n}
        userId={userId}
      />
    );
  }, [isReady, userId, chatClient, streami18n]);

  useEffect(() => {
    if (!isLogged) {
      setWebAppIsReady(true);
      return;
    }

    prepareWebApp();
  }, [isLogged, identifier]);

  useEffect(() => {
    const event = async (res: any) => {
      const { payload } = res;

      switch (payload.event) {
        case "signIn": {
          setIsLogged(true);
          break;
        }
        case "signOut": {
          setIsLogged(false);
          setAuthState("signIn");
          setChatToken("");
          setStreamToken(undefined);
          // @ts-ignore
          if (chatClient && chatClient.connecting)
            await chatClient.disconnect();

          // @todo do better
          window.location.reload();
          break;
        }
        default: {
          break;
        }
      }
    };
    Hub.listen("auth", event);
    return () => {
      Hub.remove("auth", event);
    };
  }, [chatClient]);

  if (!webAppIsReady) return <Loader />;

  return (
    <>
      {isLogged && <Drawer isOpen={drawerIsOpen} />}
      {needOnBoarding && missingAttributes && (
        <OnBoardingModal
          isOpen={needOnBoarding}
          setIsOpen={setNeedOnBoarding}
          onRequestClose={false}
        />
      )}
      <div
        onClick={() => {
          if (drawerIsOpen) setDrawerIsOpen(false);
        }}
        className="App"
        style={{
          background: `linear-gradient(312deg, ${Config.getTheme(
            "headerGradientLeftColor"
          )} 0%, ${Config.getTheme("headerGradientRightColor")} 100%)`,
          transform: `translate3d(${drawerIsOpen ? 75 : 0}%, 0, 0) scale(${
            drawerIsOpen ? 0.9 : 1
          })`,
          borderRadius: drawerIsOpen ? 4 : 0,
          boxShadow: drawerIsOpen ? "0 15px 25px 0 rgba(0, 0, 0, 0.15)" : ""
        }}>
        {isLogged && (
          <Topbar setIsOpen={setDrawerIsOpen} isOpen={drawerIsOpen} />
        )}
        <div
          className={`inteach-container ${
            isLogged
              ? ""
              : "inteach-container--center inteach-container--transparent"
          }`}>
          {isLogged && <Leftbar />}
          <Switch>
            <PrivateRoute path="/trainings" isLogged={isLogged} exact>
              <Trainings />
            </PrivateRoute>
            <PrivateRoute
              path="/trainings/:id_training"
              isLogged={isLogged}
              exact>
              <Training />
            </PrivateRoute>
            <PrivateRoute path="/account" isLogged={isLogged}>
              <Account />
            </PrivateRoute>
            <PrivateRoute path="/messaging" isLogged={isLogged}>
              {chatToken && (
                <Messaging
                  client={chatClient}
                  isReady={isReady}
                  i18nInstance={streami18n}
                  userId={userId}
                />
              )}
            </PrivateRoute>
            <PrivateRoute path="/settings" isLogged={isLogged}>
              <SettingsPage />
            </PrivateRoute>
            <PrivateRoute path="/search" exact isLogged={isLogged}>
              <Search />
            </PrivateRoute>
            <Route path="/link/:identifier">
              {!isLogged ? (
                <Link setAuthState={setAuthState} />
              ) : (
                <Redirect to={defaultRoute} />
              )}
            </Route>
            <Route path="/auth">
              {!isLogged ? (
                <Authenticator
                  ref={ref => {
                    //@ts-ignore
                    authRef.current = ref;
                  }}
                  theme={{
                    ...authTheme,
                    formContainer: {
                      ...authTheme.formContainer,
                      padding: Config.getTheme("signinFormPadding"),
                      background:
                        "url('./logo_color.png') center 1rem / 160px no-repeat"
                    }
                  }}
                  hideDefault
                  authState={authState}
                  onStateChange={_authState => {
                    setAuthState(_authState);
                  }}
                  usernameAttributes={UsernameAttributes.EMAIL}>
                  <AcademySignIn />
                  <AcademyForgotPassword />
                  <VerifyContact />
                  <ConfirmSignIn />
                  <AcademyRequireNewPassword />

                  <AcademySignUp
                    signUpConfig={{
                      signUpFields: signUpWithOnlyEmail,
                      hideAllDefaults: true
                    }}
                    usernameAttributes={UsernameAttributes.EMAIL}
                    identifier={identifier}
                    setIdentifier={setIdentifier}
                  />
                  <ConfirmSignUp
                    usernameAttributes={UsernameAttributes.EMAIL}
                  />
                </Authenticator>
              ) : (
                <Redirect to={redirect} />
              )}
            </Route>
            <PrivateRoute path="/" exact isLogged={isLogged}>
              <Home streamToken={streamToken} />
            </PrivateRoute>
            <Route>
              <Redirect to={defaultRoute} />
            </Route>
          </Switch>
          {isLogged && chatToken && renderRightBar()}
          {isLogged && <Tabbar />}
          <Help
            setIdentifier={setIdentifier}
            changeState={authState => {
              //@ts-ignore
              authRef.current.handleStateChange(authState);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AppMain;
