import React from "react";
import SvgIcon from "../SvgIcon";
import "./styles.scss";

const Input = ({
  iconLeft,
  iconRight,
  iconLeftClick,
  iconRightClick,
  className,
  ...props
}: {
  iconLeft?: string;
  iconRight?: string;
  iconLeftClick?: any;
  iconRightClick?: any;
  className?: string;
}) => {
  return (
    <div className="inteach__input">
      {iconLeft && (
        <div
          onClick={iconLeftClick}
          className={`inteach__input-icon-container ${
            iconLeftClick ? "cursor-pointer" : ""
          }`}>
          <SvgIcon
            className="inteach__input-icon"
            name={iconLeft}
            color="grey"
            height={15}
            width={15}
          />
        </div>
      )}
      <input
        className={`${className} inteach__input-input`}
        style={{
          ...(iconLeft ? { paddingLeft: "40px" } : null),
          ...(iconRight ? { paddingRight: "40px" } : null)
        }}
        {...props}
      />
      {iconRight && (
        <div
          style={{ position: "absolute", right: "1rem" }}
          onClick={iconRightClick}
          className={iconRightClick ? "cursor-pointer" : ""}>
          <SvgIcon name={iconRight} color="grey" height={15} width={15} />
        </div>
      )}
    </div>
  );
};

export default Input;
