import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

const PrivateRoute = ({
  children,
  isLogged,
  ...rest
}: {
  children: any,
  isLogged: boolean
  [key: string]: any
}) => {
  const location = useLocation();

  return (
    <Route {...rest}>
      {isLogged ? (
        children
      ) : (
        <Redirect
          to={`/auth?redirect=${location.pathname}${location.search}`}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;
