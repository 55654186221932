import React from "react";

interface Icon {
  [key: string]: string | { path: string; viewBox: string };
}

const iconsPath: Icon = {
  search:
    "M10.429 3a7.429 7.429 0 015.998 11.812l.02.02 2.218 2.217a1.143 1.143 0 01-1.616 1.617l-2.217-2.218a1.322 1.322 0 01-.02-.02A7.429 7.429 0 1110.428 3zm0 2.122a5.306 5.306 0 100 10.613 5.306 5.306 0 000-10.613z",
  search2: {
    path:
      "M378.344 332.78c25.37-34.645 40.545-77.2 40.545-123.333C418.89 93.963 324.928.002 209.444.002S0 93.963 0 209.447s93.96 209.445 209.445 209.445c46.133 0 88.692-15.177 123.337-40.547l137.212 137.212 45.564-45.564c0-.001-137.214-137.213-137.214-137.213zm-168.9 21.667c-79.958 0-145-65.042-145-145s65.042-145 145-145 145 65.042 145 145-65.043 145-145 145z",
    viewBox: "0 0 515.558 515.558"
  },
  bell:
    "M12.25 9.783V7a5.25 5.25 0 00-4.375-5.171V.875a.875.875 0 10-1.75 0v.954A5.25 5.25 0 001.75 7v2.783A2.625 2.625 0 000 12.25V14c0 .483.392.875.875.875h2.747a3.5 3.5 0 006.755 0h2.748A.875.875 0 0014 14v-1.75a2.625 2.625 0 00-1.75-2.467zM3.5 7a3.5 3.5 0 017 0v2.625h-7V7zM7 15.75a1.75 1.75 0 01-1.505-.875h3.01A1.75 1.75 0 017 15.75zm5.25-2.625H1.75v-.875c0-.483.392-.875.875-.875h8.75c.483 0 .875.392.875.875v.875z",
  settings: {
    path:
      "M496.647,312.107l-47.061-36.8c1.459-12.844,1.459-25.812,0-38.656l47.104-36.821 c8.827-7.109,11.186-19.575,5.568-29.419l-48.96-84.629c-5.639-9.906-17.649-14.232-28.309-10.197l-55.467,22.315 c-10.423-7.562-21.588-14.045-33.323-19.349l-8.512-58.923c-1.535-11.312-11.24-19.72-22.656-19.627h-98.133 c-11.321-0.068-20.948,8.246-22.528,19.456l-8.533,59.093c-11.699,5.355-22.846,11.843-33.28,19.371L86.94,75.563 c-10.55-4.159-22.549,0.115-28.096,10.005L9.841,170.347c-5.769,9.86-3.394,22.463,5.568,29.547l47.061,36.8 c-1.473,12.843-1.473,25.813,0,38.656l-47.104,36.8c-8.842,7.099-11.212,19.572-5.589,29.419l48.939,84.651 c5.632,9.913,17.649,14.242,28.309,10.197l55.467-22.315c10.432,7.566,21.604,14.056,33.344,19.371l8.533,58.88 c1.502,11.282,11.147,19.694,22.528,19.648h98.133c11.342,0.091,21-8.226,22.592-19.456l8.533-59.093 c11.698-5.357,22.844-11.845,33.28-19.371l55.68,22.379c10.55,4.149,22.543-0.122,28.096-10.005l49.152-85.12 C507.866,331.505,505.447,319.139,496.647,312.107z M255.964,362.667c-58.91,0-106.667-47.756-106.667-106.667 s47.756-106.667,106.667-106.667s106.667,47.756,106.667,106.667C362.56,314.882,314.845,362.597,255.964,362.667z",
    viewBox: "0 0 512.002 512.002"
  },
  star: {
    path:
      "M17.562 21c-.158 0-.317-.036-.463-.112l-5.1-2.664-5.097 2.664a1.002 1.002 0 01-1.448-1.057l.97-5.628-4.12-3.985a1.001 1.001 0 01.553-1.708l5.7-.828 2.548-5.126c.338-.68 1.453-.68 1.79 0l2.549 5.126 5.7.828a.998.998 0 01.552 1.708l-4.12 3.985.97 5.628a1 1 0 01-.984 1.17L104 0",
    viewBox: "0 0 24 24"
  },
  clock: {
    path:
      "M601.5.6l-17 1.4C434.4 14.2 289.5 82.7 183 191.6 74.7 302.4 11.7 443.2.9 598.4-.6 620.8.3 682.3 2.4 702.5c7.4 68.3 22.7 128.7 47.9 188 47.8 112.7 128.1 210.3 230.2 279.7 87.6 59.6 185.1 95.1 293.5 106.9 23.6 2.6 82.4 3.7 107.5 2 104.2-7 202.2-37.7 290-90.9 78.6-47.6 146.8-112.4 198.7-188.7 60.3-88.7 95.7-186.8 107.5-298 2.6-24.5 2.6-98.5 0-123-10.6-100.2-39.5-187.3-89.5-270-42.2-69.8-98.3-131.7-164.7-181.6C938.3 62.7 834.1 19.9 728 5.5c-30.1-4.1-40.6-4.7-81.5-5-22.5-.1-42.8-.1-45 .1zm48.8 229.9c14 3.6 24.2 12.1 30.5 25.2l3.7 7.8.5 331.5 165.8.2 165.7.3 7.8 3.7c14.1 6.8 23.4 19 26 34.3 3.2 18.9-7.5 38.5-26 47.3l-7.8 3.7-191 .3c-213.5.3-197.6.8-209.3-6.7-10-6.6-16.9-15.8-19.7-26.7-1.4-5.4-1.5-27.2-1.3-197l.3-190.9 3.7-7.8c9.6-20 30.9-30.5 51.1-25.2z",
    viewBox: "0 0 1280 1280"
  },
  lightning: {
    path:
      "m155.109 74.028a4 4 0 0 0 -3.48-2.028h-52.4l8.785-67.123a4.023 4.023 0 0 0 -7.373-2.614l-63.724 111.642a4 4 0 0 0 3.407 6.095h51.617l-6.962 67.224a4.024 4.024 0 0 0 7.411 2.461l62.671-111.63a4 4 0 0 0 .048-4.027z",
    viewBox: "0 0 192 192"
  },
  comments: {
    path:
      "M6.931 1.416C4.213 1.84 1.96 4.03 1.451 6.741a6.677 6.677 0 00.407 3.843.722.722 0 01.06.426l-.572 2.859a.666.666 0 00.784.784l2.856-.57a.77.77 0 01.43.058 6.684 6.684 0 003.842.407c2.712-.509 4.901-2.762 5.325-5.48.335-2.152-.346-4.26-1.87-5.783-1.522-1.522-3.63-2.202-5.782-1.869z",
    viewBox: "0 0 15 15"
  },
  check: {
    path:
      "M421.876,56.307c-6.548-6.78-17.352-6.968-24.132-0.42c-0.142,0.137-0.282,0.277-0.42,0.42L119.257,334.375l-90.334-90.334c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.713l102.4,102.4c6.665,6.663,17.468,6.663,24.132,0L421.456,80.44C428.236,73.891,428.424,63.087,421.876,56.307z",
    viewBox: "0 0 426.667 426.667"
  },
  next: {
    path:
      "M48.032,1.443c-5.095-2.957-11.623-1.224-14.58,3.871c-0.946,1.63-1.444,3.482-1.441,5.367v469.333c-0.007,5.891,4.764,10.672,10.655,10.679c1.885,0.002,3.737-0.495,5.367-1.441l405.333-234.667c5.096-2.956,6.83-9.483,3.874-14.579c-0.932-1.607-2.268-2.942-3.874-3.874L48.032,1.443z",
    viewBox: "0 0 490.693 490.693"
  },
  down: {
    path:
      "m245.213 373.415c-2.83.005-5.548-1.115-7.552-3.115l-234.667-234.667a10.67 10.67 0 0 1 .262-15.083 10.67 10.67 0 0 1 14.82 0l227.136 227.115 227.115-227.135a10.67 10.67 0 0 1 15.345 14.821l-234.928 234.929a10.67 10.67 0 0 1 -7.531 3.136z",
    viewBox: "0 0 490.688 490.688"
  },
  filter: {
    path:
      "M367.82 0H16.56A16.5 16.5 0 0 0 1.848 8.961c-2.87 5.586-2.367 12.312 1.3 17.414L131.836 207.66c.043.063.1.12.133.184a36.75 36.75 0 0 1 7.219 21.816v147.8A16.45 16.45 0 0 0 155.617 394a16.94 16.94 0 0 0 6.48-1.301l72.313-27.57c6.477-1.98 10.777-8.094 10.777-15.453V229.66c.012-7.855 2.543-15.504 7.215-21.816.043-.062.1-.12.133-.184l128.69-181.29c3.668-5.098 4.172-11.82 1.3-17.406C379.7 3.45 374.016-.012 367.82 0zm0 0",
    viewBox: "-5 0 394 394"
  },
  home: {
    path:
      "M487.083 225.514l-75.08-75.08v-86.73c0-15.682-12.708-28.4-28.413-28.4-15.67 0-28.377 12.7-28.377 28.4v29.94L299.3 37.74c-27.64-27.624-75.694-27.575-103.27.05L8.312 225.514c-11.082 11.104-11.082 29.07 0 40.158 11.087 11.1 29.1 11.1 40.172 0l187.7-187.73c6.115-6.083 16.893-6.083 22.976-.018L446.902 265.67a28.34 28.34 0 0 0 20.08 8.312c7.27 0 14.54-2.764 20.1-8.312 11.086-11.086 11.086-29.053-.001-40.158zM257.56 131.836c-5.454-5.45-14.285-5.45-19.723 0L72.712 296.913c-2.607 2.606-4.085 6.164-4.085 9.877v120.4c0 28.253 22.908 51.16 51.16 51.16h81.754v-126.6h92.3v126.6h81.755c28.25 0 51.16-22.907 51.16-51.16V306.8c0-3.713-1.465-7.27-4.085-9.877L257.56 131.836z",
    viewBox: "0 0 495.398 495.398"
  },
  user: {
    path:
      "M210.352 246.633c33.883 0 63.223-12.152 87.195-36.13s36.125-53.305 36.125-87.2c0-33.875-12.152-63.2-36.13-87.2C273.566 12.152 244.23 0 210.352 0c-33.887 0-63.22 12.152-87.2 36.125s-36.13 53.3-36.13 87.188c0 33.887 12.156 63.223 36.133 87.195s53.313 36.125 87.188 36.125zm215.778 147.07c-.7-9.977-2.1-20.86-4.148-32.352-2.078-11.578-4.754-22.523-7.957-32.527-3.3-10.34-7.8-20.55-13.37-30.336-5.773-10.156-12.555-19-20.164-26.277-7.957-7.613-17.7-13.734-28.965-18.2-11.227-4.44-23.668-6.7-36.977-6.7-5.227 0-10.28 2.145-20.043 8.5l-20.88 13.46c-6.707 4.273-15.793 8.277-27.016 11.902-10.95 3.543-22.066 5.34-33.04 5.34s-22.086-1.797-33.047-5.34c-11.2-3.62-20.297-7.625-26.996-11.898l-20.898-13.47c-9.75-6.355-14.8-8.5-20.035-8.5-13.312 0-25.75 2.254-36.973 6.7-11.258 4.457-21.004 10.578-28.97 18.2-7.605 7.28-14.4 16.12-20.156 26.273-5.56 9.785-10.06 19.992-13.37 30.34-3.2 10.004-5.875 20.945-7.953 32.523-2.06 11.477-3.457 22.363-4.148 32.363C.344 403.512 0 413.68 0 423.95c0 26.727 8.496 48.363 25.25 64.32 16.547 15.746 38.44 23.734 65.066 23.734h246.53c26.625 0 48.512-7.984 65.063-23.734 16.758-15.945 25.254-37.586 25.254-64.324-.004-10.316-.352-20.492-1.035-30.242zm0 0",
    viewBox: "-42 0 512 512.002"
  },
  users: {
    path:
      "M438.1 273.32h-39.596c4.036 11.05 6.24 22.975 6.24 35.404v149.65a44.13 44.13 0 0 1-2.543 14.782h65.46c24.453 0 44.346-19.894 44.346-44.346v-81.58c.001-40.753-33.155-73.9-73.9-73.9zm-330.835 35.405c0-12.43 2.205-24.354 6.24-35.404H73.9c-40.754 0-73.9 33.156-73.9 73.9v81.58c0 24.452 19.893 44.346 44.346 44.346h65.462c-1.64-4.628-2.543-9.6-2.543-14.783v-149.65zm193.995-73.9h-90.52c-40.754 0-73.9 33.156-73.9 73.9v149.65c0 8.163 6.618 14.782 14.782 14.782H360.4c8.164 0 14.782-6.618 14.782-14.782v-149.65c0-40.754-33.156-73.9-73.9-73.9zM256 38.84c-49.012 0-88.886 39.874-88.886 88.887 0 33.245 18.35 62.28 45.447 77.524 12.853 7.23 27.67 11.362 43.44 11.362s30.586-4.132 43.44-11.362c27.1-15.244 45.447-44.28 45.447-77.524 0-49.012-39.874-88.887-88.886-88.887zM99.918 121.7c-36.655 0-66.475 29.82-66.475 66.475s29.82 66.475 66.475 66.475c9.298 0 18.152-1.926 26.195-5.388 13.906-5.987 25.372-16.585 32.467-29.86a66.05 66.05 0 0 0 7.813-31.227c0-36.654-29.82-66.475-66.475-66.475zm312.164 0c-36.655 0-66.475 29.82-66.475 66.475 0 11.282 2.833 21.9 7.813 31.227 7.095 13.276 18.56 23.874 32.467 29.86 8.043 3.462 16.897 5.388 26.195 5.388 36.655 0 66.475-29.82 66.475-66.475s-29.82-66.475-66.475-66.475z",
    viewBox: "0 0 511.999 511.999"
  },
  heart: {
    path:
      "M492.719,166.008c0-73.486-59.573-133.056-133.059-133.056c-47.985,0-89.891,25.484-113.302,63.569c-23.408-38.085-65.332-63.569-113.316-63.569C59.556,32.952,0,92.522,0,166.008c0,40.009,17.729,75.803,45.671,100.178l188.545,188.553c3.22,3.22,7.587,5.029,12.142,5.029c4.555,0,8.922-1.809,12.142-5.029l188.545-188.553C474.988,241.811,492.719,206.017,492.719,166.008z",
    viewBox: "0 0 492.719 492.719"
  },
  graduationCap: {
    path:
      "M256 37.578L0 176.753l256 139.175 226-122.866v177.2h30v-193.52zm0 312.496l-172.888-94V431.6s70.4 42.813 172.888 42.813S428.888 431.6 428.888 431.6V256.084l-172.888 94z",
    viewBox: "0 0 512 512"
  },
  close: {
    path:
      "m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0",
    viewBox: "0 0 329.26933 329"
  },
  chat: {
    path:
      "M426.667.002H51.2C22.923.002 0 22.925 0 51.202V324.27c0 28.277 22.923 51.2 51.2 51.2h60.587l-9.284 83.456a17.07 17.07 0 0 0 28.382 14.558l108.92-98.014h186.863c28.277 0 51.2-22.923 51.2-51.2V51.202c0-28.277-22.923-51.2-51.2-51.2z",
    viewBox: "0 0 477.867 477.867"
  },
  notification: {
    path:
      "M184.904 465.044c12 27.127 39.154 46.112 70.674 46.112s58.674-18.985 70.674-46.112zm70.67-416.208c20.8 0 40.772 3.67 59.306 10.4v-2.283C314.88 25.544 289.335 0 257.938 0h-4.72c-31.398 0-56.942 25.544-56.942 56.942v2.254c18.524-6.7 38.5-10.36 59.296-10.36zm187.174 386.208H68.4c-7.082 0-13.57-4.776-15.042-11.704-1.458-6.86 1.668-13.63 8-16.56 1.505-.976 12.833-8.897 24.174-32.862 20.83-44 25.2-106.005 25.2-150.263 0-79.855 64.967-144.82 144.82-144.82 79.665 0 144.512 64.652 144.82 144.245a15.71 15.71 0 0 1 .011.575c0 44.258 4.372 106.253 25.2 150.263 11.34 23.965 22.668 31.887 24.174 32.862 6.342 2.93 9.47 9.7 8 16.56-1.473 6.927-7.96 11.704-15.042 11.704zm7.2-28.157h.01z",
    viewBox: "0 0 511.156 511.156"
  },
  plus: {
    viewBox: "0 0 512 512",
    path:
      "M492 236H276V20c0-11.046-8.954-20-20-20s-20 8.954-20 20v216H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h216v216c0 11.046 8.954 20 20 20s20-8.954 20-20V276h216c11.046 0 20-8.954 20-20s-8.954-20-20-20z"
  },
  lock: {
    viewBox: "0 0 24 24",
    path:
      "M12 16a1 1 0 110-2 1 1 0 010 2m0-4c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3m6 7a1 1 0 01-1 1H7a1 1 0 01-1-1v-8a1 1 0 011-1h10a1 1 0 011 1v8zM10 6.111C10 4.947 10.897 4 12 4s2 .947 2 2.111V8h-4V6.111zM17 8h-1V6.111C16 3.845 14.206 2 12 2S8 3.845 8 6.111V8H7c-1.654 0-3 1.346-3 3v8c0 1.654 1.346 3 3 3h10c1.654 0 3-1.346 3-3v-8c0-1.654-1.346-3-3-3z"
  }
};

interface Props {
  name: string;
  color?: string;
  width?: number | string;
  height?: number | string;

  [key: string]: any;
}

const SvgIcon = ({
  name,
  color = "#000000",
  width = 18,
  height = 18,
  ...attrs
}: Props) => {
  // @ts-ignore TODO
  const path = iconsPath[name].path || iconsPath[name];
  // @ts-ignore TODO
  const viewBox = iconsPath[name].viewBox || "0 0 20 20";

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      {...attrs}>
      <path d={path} fillRule="nonzero" />
    </svg>
  );
};

export default SvgIcon;
