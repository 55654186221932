import React from "react";
import Modal from "./BaseModal";
import { useTranslation } from "react-i18next";

const RGPDModal = ({
  show = false,
  setShow
}: {
  show: boolean;
  setShow: any;
}) => {
  const [t] = useTranslation();
  // const url = getContentLang(Config.get("rgpdUrl")); TODO Retrieve from config
  const url = "https://docs.inteach.io/fr/legal-considerations/rgpd/";

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        if (setShow) setShow(false);
      }}
      title={t("modals.rgpd.title")}
      contentStyle={{ padding: 0 }}
      size="lg"
      closeBtn>
      <iframe
        title="rgpd"
        src={url}
        height={"100%"}
        width={"100%"}
        style={{ border: "none", borderTop: "1px solid rgb(204, 204, 204)" }}
      />
    </Modal>
  );
};

export default RGPDModal;
