interface LearningItemType {
  name: string;
  titleAttribute: string;
  tPrefix: string;
}

const types: { [name: string]: LearningItemType } = {
  capsule: {
    name: "capsule",
    titleAttribute: "capsule_title",
    tPrefix: "learningSets.learningItems.capsule"
  },
  quiz: {
    name: "quiz",
    titleAttribute: "capsule_title",
    tPrefix: "learningSets.learningItems.test"
  },
  test: {
    name: "test",
    titleAttribute: "test_title",
    tPrefix: "learningSets.learningItems.test"
  },
  scorm: {
    name: "scorm",
    titleAttribute: "scorm_title",
    tPrefix: "learningSets.learningItems.scorm"
  },
  video: {
    name: "video",
    titleAttribute: "video_title",
    tPrefix: "learningSets.learningItems.video"
  },
  practice: {
    name: "practice",
    titleAttribute: "practice_title",
    tPrefix: "learningSets.learningItems.practice"
  },
  file: {
    name: "file",
    titleAttribute: "file_title",
    tPrefix: "learningSets.learningItems.file"
  }
};

export default types;
