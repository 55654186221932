import API from "../api/base";
import Config from "./index";

/**
 * @description Each white label will have their own UserPool thus we should have a config json file
 * for every of them.
 */
export default async () => {
  const brandName: string = Config.get("id");
  return await API.get(`/organization/getAwsConfig`, {
    name: brandName
  });
};
