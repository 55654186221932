/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from "react";
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Button,
  Link,
  UsernameAttributes
} from "aws-amplify-react";
import { Auth, I18n } from "aws-amplify";
import PhoneField from "aws-amplify-react/lib-esm/Auth/PhoneField";
import {
  InputLabel,
  FormField,
  Input,
  SectionFooterPrimaryContent
} from "aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React";
import {
  signUpWithOnlyEmail,
  signUpWithEmailFields,
  signUpWithPhoneNumberFields,
  signUpWithUsernameFields
} from "./signUpFields";
import { getIdentifierFromLinkCode } from "../../api/generic";
import AuthPiece, { IAuthPieceState, IAuthPieceProps } from "./AuthPiece";
import { countryDialCodes } from "./common/countryDialCodes";
import { ISignUpConfig } from "aws-amplify-react/lib-esm/Auth/SignUp";
import { initUser } from "../../utils/UserUtils";
import { initLanguage } from "../../utils/i18n";
import LoadingText from "../Loader/LoadingText";
export interface ISignUpField {
  label: string;
  key: string;
  placeholder: string;
  required: boolean;
  displayOrder: number;
  invalid?: boolean;
  custom?: boolean;
  type?: string;
}

export interface ISignUpProps extends IAuthPieceProps {
  signUpConfig?: ISignUpConfig;
  identifier: string | undefined;
  setIdentifier: (identifier: string) => void;
}

class AcademySignUp extends AuthPiece<ISignUpProps, IAuthPieceState> {
  public defaultSignUpFields: ISignUpField[];
  public header: string;
  public signUpFields: ISignUpField[] | undefined;

  constructor(props: ISignUpProps & { identifier: string }) {
    super(props);
    this.state = { requestPending: false };

    this._validAuthStates = ["signUp"];
    this.signUp = this.signUp.bind(this);
    this.sortFields = this.sortFields.bind(this);
    this.getDefaultDialCode = this.getDefaultDialCode.bind(this);
    this.checkCustomSignUpFields = this.checkCustomSignUpFields.bind(this);
    this.needPrefix = this.needPrefix.bind(this);
    this.header =
      this.props && this.props.signUpConfig && this.props.signUpConfig.header
        ? this.props.signUpConfig.header
        : "Create a new account";

    const { usernameAttributes = UsernameAttributes.USERNAME } =
      this.props || {};
    if (usernameAttributes === UsernameAttributes.EMAIL) {
      this.defaultSignUpFields = signUpWithEmailFields;
    } else if (usernameAttributes === UsernameAttributes.PHONE_NUMBER) {
      this.defaultSignUpFields = signUpWithPhoneNumberFields;
    } else {
      this.defaultSignUpFields = signUpWithUsernameFields;
    }
  }

  validate() {
    const invalids: string[] = [];
    this.signUpFields
      ?.filter(f => !["Langue", "Organization"].includes(f.label))
      .map(el => {
        if (el.key !== "phone_number") {
          if (el.required && !this.inputs[el.key]) {
            el.invalid = true;
            invalids.push(el.label);
          } else {
            el.invalid = false;
          }
        } else {
          if (el.required && !this.phone_number) {
            el.invalid = true;
            invalids.push(el.label);
          } else {
            el.invalid = false;
          }
        }
      });
    return invalids;
  }

  sortFields() {
    if (
      this.props.signUpConfig &&
      this.props.signUpConfig.hiddenDefaults &&
      this.props.signUpConfig.hiddenDefaults.length > 0
    ) {
      this.defaultSignUpFields = this.defaultSignUpFields.filter(d => {
        return !this.props.signUpConfig?.hiddenDefaults?.includes(d.key);
      });
    }

    if (this.checkCustomSignUpFields()) {
      if (
        !this.props.signUpConfig ||
        !this.props.signUpConfig.hideAllDefaults
      ) {
        // see if fields passed to component should override defaults
        this.defaultSignUpFields.forEach(f => {
          const matchKey = this.signUpFields?.findIndex(d => {
            return d.key === f.key;
          });
          if (matchKey === -1) {
            this.signUpFields?.push(f);
          }
        });
      }

      /* 
            sort fields based on following rules:
            1. Fields with displayOrder are sorted before those without displayOrder
            2. Fields with conflicting displayOrder are sorted alphabetically by key
            3. Fields without displayOrder are sorted alphabetically by key
          */

      //@ts-ignore
      this.signUpFields?.sort((a, b) => {
        if (a.displayOrder && b.displayOrder) {
          if (a.displayOrder < b.displayOrder) {
            return -1;
          } else if (a.displayOrder > b.displayOrder) {
            return 1;
          } else {
            if (a.key < b.key) {
              return -1;
            } else {
              return 1;
            }
          }
        } else if (!a.displayOrder && b.displayOrder) {
          return 1;
        } else if (a.displayOrder && !b.displayOrder) {
          return -1;
        } else if (!a.displayOrder && !b.displayOrder) {
          if (a.key < b.key) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    } else {
      this.signUpFields = this.defaultSignUpFields;
    }
  }

  needPrefix(key: string) {
    const field = this.signUpFields?.find(e => e.key === key);
    if (key.indexOf("custom:") !== 0) {
      return field?.custom;
    } else if (key.indexOf("custom:") === 0 && field?.custom === false) {
      console.warn(
        "Custom prefix prepended to key but custom field flag is set to false; retaining manually entered prefix"
      );
    }
    return null;
  }

  getDefaultDialCode() {
    return this.props.signUpConfig &&
      this.props.signUpConfig.defaultCountryCode &&
      countryDialCodes.indexOf(
        `+${this.props.signUpConfig.defaultCountryCode}`
      ) !== -1
      ? `+${this.props.signUpConfig.defaultCountryCode}`
      : "+1";
  }

  checkCustomSignUpFields() {
    return (
      this.props.signUpConfig &&
      this.props.signUpConfig.signUpFields &&
      this.props.signUpConfig.signUpFields.length > 0
    );
  }

  async signUp() {
    this.setState({ requestPending: true });

    localStorage.setItem("username", this.inputs.username);

    // First check current identifier
    try {
      // If user comes from a link and it worked the input will be filled
      // but the state will be empty given the user didn't change the input
      let [_identifier]: string[] = [this.inputs["academyIdentifier"]] || [
        this.getDefaultValueCode()
      ];

      if (!_identifier) {
        [_identifier] =
          (this.props.identifier || "").match(/[a-zA-Z0-9]+$/) || [];
      }

      const { identifier } = await getIdentifierFromLinkCode(_identifier);

      this.props.setIdentifier(identifier);
    } catch (e) {
      this.setState({ requestPending: false });
      this.error({
        code: "AcademyIdentifierNotFound",
        message: "AcademyIdentifierNotFound",
        name: "AcademyIdentifierNotFound"
      });
      return;
    }

    localStorage.removeItem("username");

    if (!this.inputs.dial_code) {
      this.inputs.dial_code = this.getDefaultDialCode();
    }
    const validation = this.validate();

    if (validation && validation.length > 0) {
      this.setState({ requestPending: false });
      return this.error(
        `The following fields need to be filled out: ${validation.join(", ")}`
      );
    }
    if (!Auth || typeof Auth.signUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    const signup_info: {
      username: string;
      password: string;
      attributes: any;
    } = {
      username: this.inputs.username,
      password: this.inputs.password,
      attributes: {}
    };

    const inputKeys = Object.keys(this.inputs);
    const inputVals = Object.values(this.inputs);

    inputKeys.forEach((key, index) => {
      if (
        ![
          "username",
          "password",
          "checkedValue",
          "dial_code",
          "academyIdentifier"
        ].includes(key)
      ) {
        if (
          key !== "phone_line_number" &&
          key !== "dial_code" &&
          key !== "error"
        ) {
          const newKey = `${this.needPrefix(key) ? "custom:" : ""}${key}`;
          signup_info.attributes[newKey] = inputVals[index];
        }
      }
    });

    if (this.phone_number)
      signup_info.attributes["phone_number"] = this.phone_number;

    let labelCheck = false;
    this.signUpFields?.forEach(field => {
      if (field.label === this.getUsernameLabel()) {
        signup_info.username =
          signup_info.attributes[field.key] || signup_info.username;
        labelCheck = true;
      }
    });
    if (!labelCheck && !signup_info.username) {
      // if the customer customized the username field in the sign up form
      // He needs to either set the key of that field to 'username'
      // Or make the label of the field the same as the 'usernameAttributes'
      throw new Error(
        `Couldn't find the label: ${this.getUsernameLabel()}, in sign up fields according to usernameAttributes!`
      );
    }

    const paramsSignup = {
      ...signup_info,
      username: (signup_info.username || "").replace(/\s/g, ""),
      attributes: {
        ...(signup_info.attributes || {}),
        email: ((signup_info.attributes || {}).email || "").replace(/\s/g, ""),
        // eslint-disable-next-line no-useless-computed-key
        ["custom:appInfo"]: JSON.stringify({
          webapp: true
        })
      }
    };

    if (
      !new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ).test(paramsSignup.username)
    ) {
      this.setState({ requestPending: false });
      this.error({
        code: "InvalidEmail",
        message: "InvalidEmail",
        name: "InvalidEmail"
      });
      return;
    }

    try {
      await Auth.signUp(paramsSignup);
    } catch (e) {
      this.error(e);
      this.setState({ requestPending: false });
      return;
    }

    try {
      // Signin user
      const user = await Auth.signIn(
        paramsSignup.username,
        paramsSignup.password
      );

      if (user.challengeName === "SMS_MFA") {
        this.changeState("confirmSignIn", user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.changeState("requireNewPassword", user);
      } else {
        await initUser();
        await initLanguage();
      }
    } catch (e) {
      console.log("e", e);
      this.error(e);
    }
    this.setState({ requestPending: false });
  }

  getDefaultValueCode() {
    const { identifier } = this.props;

    // Because currentURL change for the link identifier don't show it to the user
    // in order to avoid confusion
    return this.state.academyIdentifier || identifier;
  }

  showComponent(theme: any) {
    const { hide } = this.props;
    if (hide && hide.includes(AcademySignUp)) {
      return null;
    }
    if (this.checkCustomSignUpFields()) {
      this.signUpFields = this.props.signUpConfig?.signUpFields;
    }
    this.sortFields();

    const defaultValueCode = this.getDefaultValueCode();

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>{I18n.get(this.header)}</SectionHeader>
        <SectionBody theme={theme}>
          {signUpWithOnlyEmail.map(field => {
            return field.key !== "phone_number" ? (
              <FormField
                theme={{
                  ...theme,
                  formField: {
                    ...theme.formField,
                    ...(field.hidden ? { height: 0, width: 0, margin: 0 } : {})
                  }
                }}
                key={field.key}>
                {field.required ? (
                  <InputLabel theme={theme}>
                    {I18n.get(field.label)} *
                  </InputLabel>
                ) : (
                  <InputLabel theme={theme}>{I18n.get(field.label)}</InputLabel>
                )}
                <Input
                  placeholder={I18n.get("signup" + field.placeholder)}
                  theme={{
                    ...theme,
                    input: {
                      ...theme.input,
                      ...(field.hidden
                        ? { height: 0, width: 0, margin: 0, padding: 0 }
                        : {})
                    }
                  }}
                  type={field.type}
                  name={field.key}
                  key={field.key}
                  onChange={this.handleInputChange}
                />
              </FormField>
            ) : (
              <PhoneField
                theme={theme}
                required={field.required}
                defaultDialCode={this.getDefaultDialCode()}
                label={field.label}
                placeholder={field.placeholder}
                onChangeText={this.onPhoneNumberChanged}
                key="phone_number"
              />
            );
          })}
          <FormField theme={theme}>
            <Input
              defaultValue={defaultValueCode}
              placeholder={I18n.get("inscriptionCodeHint")}
              theme={theme}
              type={"text"}
              name={"academyIdentifier"}
              key={"academyIdentifier"}
              onChange={this.handleInputChange}
              required={true}
            />
          </FormField>
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            <Button
              disabled={this.state.requestPending}
              onClick={this.signUp}
              theme={theme}>
              <LoadingText
                loadingTitle={I18n.get("signUpLoading")}
                title={I18n.get("Create Account")}
                isLoading={this.state.requestPending}
              />
            </Button>
          </SectionFooterPrimaryContent>

          <div className="mt-1 d-flex justify-content-space-betwee">
            <span className="text text--small">
              <Link theme={theme} onClick={() => this.changeState("signIn")}>
                {I18n.get("Sign in")}
              </Link>
            </span>
          </div>
        </SectionFooter>
      </FormSection>
    );
  }
}

export default AcademySignUp;
