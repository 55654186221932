import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./BaseModal";
import ReactDOM from "react-dom";
import Lottie from "react-lottie";

const CapsuleRewardModal = ({
  success,
  points,
  percentCorrect = 0,
  minimumRequired = 0,
  isCapsule = true
}: {
  success: boolean;
  points: number;
  percentCorrect: number;
  minimumRequired: number;
  isCapsule?: boolean;
}) => {
  const [t] = useTranslation();

  const lottieAnimation = success
    ? require("../../currentOrga/smile.json")
    : require("../../currentOrga/wow.json");

  const handleClose = useCallback(() => {
    // @ts-ignore
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }, []);

  return (
    <Modal isOpen={true} onRequestClose={handleClose} size="sm">
      <div className="inteach-capsule-reward">
        <Lottie
          height={80}
          width={80}
          options={{
            loop: false,
            animationData: lottieAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />
        <h2 className="inteach-capsule-reward__title">
          {success
            ? t("modals.capsuleReward.congrats")
            : t("modals.capsuleReward.fail")}
        </h2>
        <p className="inteach-capsule-reward__description">
          {success
            ? points
              ? t("modals.capsuleReward.points_success_activity_interval", {
                  postProcess: "interval",
                  count: points
                })
              : ""
            : t("modals.capsuleReward.failDescription")}
        </p>

        {isCapsule && percentCorrect ? (
          <p className="inteach-capsule-reward__percent-correct">
            {t("modals.capsuleReward.percent_correct", {
              count: Math.round(percentCorrect)
            })}
          </p>
        ) : null}

        {success ? null : (
          <p className="inteach-capsule-reward__minimum-required">
            {t("modals.capsuleReward.minimum_required", {
              count: Math.round(minimumRequired)
            })}
          </p>
        )}

        {success ? (
          <span className="inteach-capsule-reward__points">{points}</span>
        ) : null}
        <button
          className="inteach-button inteach-button--primary inteach-button--block"
          onClick={handleClose}>
          Continuer
        </button>
      </div>
    </Modal>
  );
};

export default CapsuleRewardModal;
