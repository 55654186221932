import React, { useCallback } from "react";
import Card from "../Card";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import LEARNING_ITEM_TYPES from "../../constants/learningItems";
import { getContentLang } from "../../utils/i18n";
import SvgIcon from "../SvgIcon";
import { onLearningItemPress } from "../../utils/learningItem";

const LearningItemSearch = ({ item }: { item: any }) => {
  const [t] = useTranslation();
  const {
    learning_item_type,
    training,
    points,
    capsule_type,
    default_lang
  } = item;
  const { tPrefix, titleAttribute } = LEARNING_ITEM_TYPES[
    capsule_type || learning_item_type
  ];

  const onClick = useCallback(async () => {
    await onLearningItemPress(item, training.id_training);
  }, [item, training.id_training]);

  return (
    <Card className="inteach-learning-item-search" onClick={onClick}>
      <div className="inteach-learning-item-search__picture">
        {training.picture && (
          <img
            src={training.picture}
            alt={getContentLang(training.name, default_lang)}
          />
        )}
      </div>
      <div className="inteach-learning-item-search__infos">
        <h4 className="inteach-learning-item-search__title">
          {getContentLang(item[titleAttribute], default_lang)}
        </h4>
        <div style={{ color: "grey", fontWeight: "bold" }}>
          <span>{getContentLang(training.name, default_lang)}</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", color: "grey" }}>
          <span style={{ fontWeight: "bold", marginRight: 5 }}>
            {t(tPrefix + ".title")}
          </span>
          <SvgIcon name="star" color="var(--principal-text)" />
          <span>
            {t("pages.trainings.points_interval", {
              postProcess: "interval",
              count: points
            })}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(LearningItemSearch);
