import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import colors from "../../theme/colors";
import "./styles.scss";

const ProgressCircle = ({
  progress = 0,
  size = "100%"
}: {
  progress: number;
  size?: string;
}) => {
  return (
    <CircularProgressbar
      value={progress}
      text={`${progress}%`}
      maxValue={100}
      styles={{
        root: {
          height: size,
          width: size
        },
        path: {
          stroke: colors.mainColor,
          strokeLinecap: "butt"
        },
        text: {
          stroke: colors.mainColor,
          fill: colors.mainColor
        },
        trail: {
          stroke: "#DFE0E5"
        }
      }}
    />
  );
};
export default ProgressCircle;
