import API from "../api/base";

// @ts-ignore
export const list = (firstResult, maxResultCount, isWebApp) =>
  API.get("trainings/learner", {
    firstResult,
    maxResultCount,
    isWebApp
  });

export const get = (id_training: string) =>
  API.get(`trainings/${id_training}`, { app: true });
