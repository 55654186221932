import React, { useEffect, useState } from "react";
import Modal from "./BaseModal";
import { getCurrentLanguage } from "../../utils/i18n";
import scorm from "../../utils/scorm";
import { onMessage } from "../../utils/scormUtils";
import ReactDOM from "react-dom";
import { Auth } from "aws-amplify";
import { snakeCase } from "lodash";
import Config from "../../config";
import usePubSub, { NEW_ANALYTICS } from "../../hooks/usePubSub";
import useTrainingsStore from "../../stores/trainings";
import { generateRandomId } from "../../utils";

const formatCardForAnalytics = (card: any) => {
  const acceptedCardTypes = [
    "question",
    "relation",
    "reorder",
    "openedQuestion",
    "dragndrop"
  ];

  if (acceptedCardTypes.includes(card.card_type)) {
    return {
      title: card[`card_${snakeCase(card.card_type)}_text`],
      data: card[`card_${snakeCase(card.card_type)}_answers`]
    };
  }
};

const EVENTS = {
  card_finished: "card_finished",
  open_link: "open_link"
};

const CapsuleModal = ({
  id_capsule,
  learning_item_id,
  learning_item_type,
  learning_set_id,
  training_id,
  points = 0,
  success = false
}: {
  id_capsule?: string;
  learning_item_id?: string;
  learning_item_type?: string;
  learning_set_id?: string;
  training_id?: string;
  points?: number;
  success?: boolean;
}) => {
  const [token, setToken] = useState(null);
  const { publish } = usePubSub();
  const updateLearningItem = useTrainingsStore(
    state => state.updateLearningItem
  );

  const emitData = {
    capsule_id: id_capsule,
    tracking_id: generateRandomId(),
    learning_item_id,
    learning_item_type,
    learning_set_id,
    training_id,
    type: "time"
  };

  const scormFunctions = {
    ...scorm,
    SetValue: async (key: string, value: any) => {
      if (key === EVENTS.card_finished) {
        const { card } = value;

        await publish(NEW_ANALYTICS, {
          ...emitData,
          type: "card",
          card_data: formatCardForAnalytics(card),
          card_answers: {
            answers: card.answers
          }
        });
      }

      if (key === EVENTS.open_link) {
        if (RegExp(/^(https|http):/).test(value)) {
          window.open(value, "_blank");
        } else {
          window.open(`https://${value}`, "_blank");
        }
      }
    },
    Terminate: async (
      key: string,
      data: { success: boolean; isQuiz: boolean; finished: boolean; percentCorrect : string; minimumRequired: string }
    ) => {
      // Emit data for progress and points
      await Promise.all([
        publish(NEW_ANALYTICS, {
          ...emitData,
          type: "progress",
          finished: data.finished,
          success: data.success,
          score: +data.percentCorrect,
          minimum_required: +data.minimumRequired
        }),

        publish(NEW_ANALYTICS, {
          ...emitData,
          type: "points",
          success: data.success
        })
      ]);

      if (success) {
        // @ts-ignore
        ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
      } else {
        //@ts-ignore
        updateLearningItem(training_id, learning_item_id, {
          success: data.success,
          // Ton set finished to true for quiz, user has to succeeded to finished the quiz
          finished: data.isQuiz ? data.success : true
        });
        // Close learning item
        scorm.Terminate(data, points);
      }
    }
  };

  const getToken = async () => {
    const session = await Auth.currentSession();
    // @ts-ignore
    setToken(session.getAccessToken().getJwtToken());
  };

  useEffect(() => {
    getToken();

    const handleMessage = (event: any) => {
      // IMPORTANT: Check the origin of the data!
      if (
        ~event.origin.indexOf(
          Config.get("config.apiURL") || Config.get("api.path")
        )
      ) {
        // The data has been sent from your site
        return onMessage(scormFunctions)(event);
      }

      return;
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [scormFunctions]);

  //@ts-ignore
  useEffect(() => {
    const sendAnalytics = async () => {
      await publish(NEW_ANALYTICS, {
        ...emitData,
        start: new Date()
      });
    };

    sendAnalytics();

    return async () =>
      await publish(NEW_ANALYTICS, {
        ...emitData,
        end: new Date()
      });
  }, []);

  if (!token) return null;

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        // @ts-ignore
        ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
      }}
      style={{
        content: {
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          padding: 0,
          border: "none",
          borderRadius: 0
        }
      }}
      closeBtn>
      <iframe
        key={id_capsule}
        title={id_capsule}
        sandbox="allow-scripts allow-same-origin allow-forms"
        name="capsuleRender"
        style={{
          height: "100%",
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          border: 0,
          zIndex: 1
        }}
        src={`${
          Config.get("config.apiURL") || Config.get("api.path")
        }/capsules/${id_capsule}/render?height=${
          window.innerHeight
        }&inset=30&lang=${getCurrentLanguage()}&type=webapp&token=${token}`}
        allow="fullscreen"
      />
    </Modal>
  );
};

export default CapsuleModal;
