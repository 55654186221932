import React, { CSSProperties } from "react";
import SvgIcon from "../SvgIcon";
import { schemes } from "../../theme/colors";

const PostHeader = ({
  containerStyle = {},
  textStyle = {},
  publishedAt,
  training
}: {
  containerStyle?: CSSProperties;
  textStyle?: object;
  publishedAt: string;
  training: any;
}) => {
  return null;
  
  return (
    <div className="post__header pl-1 pr-1 pt-1" style={containerStyle}>
      <div className="d-flex align-items-center">
        <SvgIcon color={schemes.gray[600]} name={"clock"} />
        <span className="post__header-date">{publishedAt}</span>
      </div>
      {training && <div>{/*<TrainingTag name={training.name} />*/}</div>}
    </div>
  );
};

export default PostHeader;
