import React, { useContext } from "react";
import { ChannelContext } from "stream-chat-react";
import SvgIcon from "../../components/SvgIcon";
import "./styles.scss";

const CustomChannelHeader = (props: any) => {
  const { channel } = useContext(ChannelContext);

  const renderMembers = () => {
    if (!channel) return null;

    const members = Object.values(channel.state ? channel.state.members : []);

    //@ts-ignore
    if (!members.length) return channel.data.name;

    const otherMembers = members.filter(
      (member: any) => member.user.id !== channel.getClient().userID
    );

    return otherMembers
      .map(
        (member: any) =>
          member.user.name || member.user.fullname || member.user.id
      )
      .filter(Boolean)
      .join(", ");
  };
  return (
    <div className="inteach-chat__modal__header">
      <div className="inteach-chat__modal__header__name">{renderMembers()}</div>
      <button
        className="inteach-chat__modal__header__button"
        onClick={props.onClose}>
        <SvgIcon name="close" />
      </button>
    </div>
  );
};

export default CustomChannelHeader;
