import React from "react";
import Modal from "./BaseModal";
import TrainingsList from "../trainings/TrainingsList";
import { ITraining } from "../../../types/training";

const FilterTrainingModal = ({
  show = false,
  setShow,
  setChosenTraining
}: {
  show: boolean,
  setShow: any,
  setChosenTraining: any
}) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        if (setShow) setShow(false);
      }}
      closeBtn>
      <TrainingsList
        onItemClick={(training: ITraining) => {
          setChosenTraining(training);
          setShow(false);
        }}
      />
    </Modal>
  );
};

export default FilterTrainingModal;
