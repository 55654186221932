import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Lottie from "react-lottie";

import Modal from "./BaseModal";
import { getContentLang } from "../../utils/i18n";
import usePubSub, { NEW_ANALYTICS } from "../../hooks/usePubSub";
import useTrainingsStore from "../../stores/trainings";
import { generateRandomId } from "../../utils";

const PracticeModal = ({
  title,
  content,
  practice_time,
  learning_item_id,
  learning_item_type,
  learning_set_id,
  training_id,
  updateTrainingProgress
}: {
  title: any;
  content: any;
  practice_time: any;
  learning_item_id: string;
  learning_item_type: string;
  learning_set_id: string;
  training_id: string;
  updateTrainingProgress: boolean;
}) => {
  const [t] = useTranslation();
  const { publish } = usePubSub();

  const updateLearningItem = useTrainingsStore(
    state => state.updateLearningItem
  );

  const handleClose = useCallback(() => {
    // @ts-ignore
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }, []);

  const onFinishedPress = useCallback(async () => {
    if (updateTrainingProgress) {
      handleClose();
      return;
    }

    if (window.confirm(t("learningSets.learningItems.practice.alertText"))) {
      const socketData = {
        tracking_id: generateRandomId(),
        learning_item_id,
        learning_item_type,
        learning_set_id,
        training_id,
        type: "time"
      };

      await Promise.all([
        publish(NEW_ANALYTICS, {
          ...socketData,
          start: new Date()
        }),
        publish(NEW_ANALYTICS, {
          ...socketData,
          type: "progress",
          finished: true,
          success: true
        })
      ]);

      updateLearningItem(training_id, learning_item_id, {
        success: true,
        finished: true
      });

      await new Promise(resolve => setTimeout(resolve, 1500));

      await publish(NEW_ANALYTICS, {
        ...socketData,
        end: moment()
          .add(+practice_time, "minutes")
          .toDate()
      });

      handleClose();
    }
  }, [
    handleClose,
    learning_item_id,
    learning_item_type,
    learning_set_id,
    practice_time,
    t,
    training_id,
    updateTrainingProgress
  ]);

  return (
    <Modal isOpen={true} onRequestClose={handleClose} size="md" closeBtn>
      <div className="inteach-practice">
        <Lottie
          height={80}
          width={80}
          options={{
            loop: true,
            animationData: require("../../currentOrga/rocket.json"),
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />
        <h2>{getContentLang(title)}</h2>
        <p>{getContentLang(content)}</p>
        <button
          onClick={onFinishedPress}
          className="inteach-button  inteach-button--md inteach-button--primary">
          {t("learningSets.learningItems.practice.done")}
        </button>
        <a onClick={handleClose}>
          {t("learningSets.learningItems.practice.moreTime")}
        </a>
      </div>
    </Modal>
  );
};

export default PracticeModal;
