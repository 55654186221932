import { ISignUpField } from "aws-amplify-react/src/Auth/common/default-sign-up-fields";
import { getCurrentLocale } from "../../utils/i18n";
import Config from "../../config";

export const signUpWithOnlyEmail: (ISignUpField & {
  isAttribute?: boolean,
  hidden?: boolean
})[] = [
  {
    label: "Email",
    key: "email",
    required: true,
    placeholder: "Email",
    type: "email",
    displayOrder: 1
  },
  {
    label: "Password",
    key: "password",
    required: true,
    placeholder: "Password",
    type: "password",
    displayOrder: 2
  },
  {
    label: "Langue",
    key: "custom:lang",
    required: true,
    placeholder: getCurrentLocale(),
    isAttribute: true,
    type: "text",
    displayOrder: 4,
    hidden: true
  },
  {
    label: "Organization",
    key: "custom:organizationId",
    required: true,
    placeholder: Config.get("organizationId"),
    type: "text",
    displayOrder: 5,
    isAttribute: true,
    hidden: true
  }
];

export const signUpWithUsernameFields = [
  {
    label: "Username",
    key: "username",
    required: true,
    placeholder: "Username",
    displayOrder: 1
  },
  {
    label: "Password",
    key: "password",
    required: true,
    placeholder: "Password",
    type: "password",
    displayOrder: 2
  },
  {
    label: "Email",
    key: "email",
    required: true,
    placeholder: "Email",
    type: "email",
    displayOrder: 3
  },
  {
    label: "Phone Number",
    key: "phone_number",
    placeholder: "Phone Number",
    required: true,
    displayOrder: 4
  }
];

export const signUpWithEmailFields: ISignUpField[] = [
  {
    label: "Email",
    key: "email",
    required: true,
    placeholder: "Email",
    type: "email",
    displayOrder: 1
  },
  {
    label: "Password",
    key: "password",
    required: true,
    placeholder: "Password",
    type: "password",
    displayOrder: 2
  },
  {
    label: "Phone Number",
    key: "phone_number",
    placeholder: "Phone Number",
    required: true,
    displayOrder: 3
  }
];

export const signUpWithPhoneNumberFields: ISignUpField[] = [
  {
    label: "Phone Number",
    key: "phone_number",
    placeholder: "Phone Number",
    required: true,
    displayOrder: 1
  },
  {
    label: "Password",
    key: "password",
    required: true,
    placeholder: "Password",
    type: "password",
    displayOrder: 2
  },
  {
    label: "Email",
    key: "email",
    required: true,
    placeholder: "Email",
    type: "email",
    displayOrder: 3
  }
];
