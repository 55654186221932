import React from "react";
import { NavLink } from "react-router-dom";
import SvgIcon from "../../SvgIcon";
import "./styles.scss";
import Config from "../../../config";

const { messages } = Config.get("config.screens");

const Tabbar = () => {
  return (
    <div className="inteach-tabbar">
      <nav>
        <ul>
          <li>
            <NavLink exact to="/">
              <SvgIcon name="home" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/trainings">
              <SvgIcon name="graduationCap" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/search">
              <SvgIcon name="search2" />
            </NavLink>
          </li>
          {messages && (
            <li>
              <NavLink to="/messaging">
                <SvgIcon name="chat" />
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Tabbar;
