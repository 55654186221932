import ReactDOM from "react-dom";
import React from "react";
import CapsuleReward from "../components/modal/CapsuleReward";

export default {
  Initialize: (info: string) => {
    console.log("Initialize => ", info);
  },
  Terminate: (data: any, points: number) => {
    ReactDOM.render(
      React.createElement(CapsuleReward, {
        isCapsule: true,
        success: data.success,
        points,
        percentCorrect: data.percentCorrect,
        minimumRequired: data.minimumRequired
      }),
      document.getElementById("modal")
    );
  },
  GetValue: () => {},
  SetValue: (key: string, value: any) => {},
  Commit: () => {},
  GetLastError: () => {},
  GetErrorString: () => {},
  GetDiagnostic: () => {}
};
