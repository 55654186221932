import React, { CSSProperties } from "react";
import "./styles.scss";

const Content = ({
  children,
  title,
  style,
  contentStyle,
  onScroll = () => {}
}: {
  children: React.ReactNode;
  onScroll?: (e: React.UIEvent) => void;
  title?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
}) => {
  return (
    <div onScroll={onScroll} className="inteach-content" style={style}>
      <div className="inteach-content__content" style={contentStyle}>
        {title && <h1 className="inteach-content__title">{title}</h1>}
        {children}
      </div>
    </div>
  );
};

export default Content;
