import React from "react";
import {
  Chat,
  ChannelList,
  ChannelListMessenger,
  Streami18n
} from "stream-chat-react";
import { StreamChat } from "stream-chat";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import ChatModal from "./ChatModal";
import EmptyStateIndicator from "./EmptyStateIndicator";
import "../layout/Rightbar/styles.scss";
import "./styles.scss";
import Config from "../../config";

const ChatComponent = ({
  client,
  theme,
  isReady,
  i18nInstance,
  userId
}: {
  client: StreamChat;
  theme: string;
  isReady: boolean;
  i18nInstance: Streami18n;
  userId: string;
}) => {
  const [t] = useTranslation();

  const isEnabled = Config.get("config.screens.messages");

  if (!isEnabled) return null;

  return (
    <div className="inteach-chat-component__content">
      <h2 className="inteach-content__title">{t("rightbar.messaging")}</h2>
      <h3>{t("rightbar.trainers")}</h3>

      {isReady ? (
        <div className="inteach-chat-component__chat">
          <Chat client={client} theme={theme} i18nInstance={i18nInstance}>
            <div className="inteach-chat-component__list">
              <ChannelList
                filters={{
                  type: "messaging",
                  members: { $in: [userId] }
                }}
                setActiveChannelOnMount={false}
                sort={{ last_message_at: -1 }}
                EmptyStateIndicator={EmptyStateIndicator}
                LoadingIndicator={() => (
                  <Loader
                    type="ThreeDots"
                    color="#6fd6ba"
                    height={50}
                    width={50}
                  />
                )}
                List={ChannelListMessenger}
              />

              <ChatModal streami18n={i18nInstance} />
            </div>
          </Chat>
        </div>
      ) : (
        <Loader type="ThreeDots" color="#6fd6ba" height={50} width={50} />
      )}
    </div>
  );
};

export default ChatComponent;
