import React, { createRef, CSSProperties, useState } from "react";
import * as UserAPI from "../../api/users";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateProfilePicture } from "../../utils/UserUtils";
import SvgIcon from "../SvgIcon";
import "./styles.scss";
import { fileToBase64 } from "../../utils";
import { IUser } from "../../../types/users";

const ProfilePicture = ({
  user,
  size = 80,
  canEdit = false,
  style
}: {
  user: IUser;
  size?: number | string;
  canEdit?: boolean;
  style?: CSSProperties;
}) => {
  const [t] = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const ref = createRef<HTMLInputElement>();

  const uploadImage = async (e: any) => {
    if (!canEdit || isUploading || e.target.files === undefined) return;

    try {
      const _file = e.target.files[0];
      if (!_file) return;

      const contentBase64: string = await fileToBase64(_file);
      const content = contentBase64.split("base64,")[1];

      setIsUploading(true);
      const URL = await UserAPI.uploadProfilePicture(
        {
          content,
          contentType: _file.type
        },
        {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }
        }
      );

      await updateProfilePicture(URL);
      toast.success(t("user.updateProfilePictureSuccess"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    } catch (e) {
      toast.error(t("api.errorUploadProfilePicture"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    }

    setIsUploading(false);
  };

  return (
    <div
      onClick={() => {
        if (!canEdit) return;
        ref.current?.click();
      }}
      className={`inteach-profile-picture ${canEdit ? "cursor-pointer" : ""}`}
      style={{ height: size, width: size, ...style }}>
      <input
        type="file"
        ref={ref}
        style={{ display: "none" }}
        accept="image/jpeg, image/png"
        onChange={async (e: any) => {
          await uploadImage(e);
        }}
      />
      {isUploading ? (
        <span>{progress}%</span>
      ) : user.picture ? (
        <img
          src={user.picture}
          alt=""
          className="inteach-profile-picture__img"
        />
      ) : canEdit ? (
        <div className="inteach-profile-picture__add">
          <SvgIcon name="plus" height={30} width={30} color={"grey"} />
        </div>
      ) : null}
    </div>
  );
};

export default ProfilePicture;
