import Config from ".";

/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

export const Container = {};
export const FormContainer = {
  padding: "7rem 3rem 3rem 3rem",
  textAlign: "center",
  background:
    "url('https://admin.inteach.io/images/inteach.png') center 1rem / 160px no-repeat",
  borderRadius: "4px",
  backgroundColor: "#FFF"
};
export const FormSection = {
  boxShadow: "none"
};
export const FormField = {
  marginTop: "20px"
};
export const SectionHeader = {
  fontSize: "22px",
  color: "#44566C",
  marginBottom: "10px"
};
export const SectionBody = {};
export const SectionFooter = {};
export const SectionFooterPrimaryContent = {};
export const SectionFooterSecondaryContent = {
  display: "none"
};
export const Input = {
  backgroundColor: "#F8FAFB",
  borderRadius: "25px",
  border: "none",
  fontSize: "15px",
  outline: "none",
  height: "40px",
  width: "100%",
  paddingLeft: "20px"
};
export const Button = {
  backgroundColor: Config.getTheme("mainColor"),
  color: "white",
  fontWeight: "bold",
  borderRadius: "25px",
  border: "none",
  height: "40px",
  width: "100%",
  cursor: "pointer",
  marginTop: "20px"
};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {
  color: Config.getTheme('mainColor'),
  textDecoration: "underline",
  cursor: "pointer"
};
export const Hint = {
  marginTop: "20px"
};
export const Radio = {};
export const Label = {
  fontSize: "15px",
  color: "#44566C"
};
export const InputLabel = {
  fontSize: "15px",
  color: "#44566C",
  display: "none"
};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {
  display: "none"
};
export const NavBar = {};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

const AmplifyTheme = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  photoPickerButton: PhotoPickerButton,
  photoPlaceholder: PhotoPlaceholder,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  amazonSignInButton: AmazonSignInButton,
  facebookSignInButton: FacebookSignInButton,
  googleSignInButton: GoogleSignInButton,
  oAuthSignInButton: OAuthSignInButton,

  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,

  hint: Hint,
  radio: Radio,
  label: Label,
  inputLabel: InputLabel,
  toast: Toast,

  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton
};

export default AmplifyTheme;
