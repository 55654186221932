import { TFunction } from "i18next";
import { useState, useEffect } from "react";
import moment from "moment";

export const generateRandomId = () =>
  `${Math.random().toString(36).substring(2, 15)}${Math.random()
    .toString(36)
    .substring(2, 15)}`;

export const fileToBase64 = async (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result?.toString() || "");
    reader.onerror = e => reject(e);
  });

export const camelCaseToKebabCase = (str: string): string => {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
};

export function useDebounce(value: string, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

export const parseMinutesToDate = (time: number) => {
  const {
    //@ts-ignore
    _data: { months, days, hours, minutes }
  } = moment.duration(+time, "minutes");

  const totalHours = Math.ceil(hours + days * 24 + months * 730.001);

  return { days, hours: totalHours, minutes };
};

export const dateToStr = (time: number, t: TFunction) => {
  const { days, hours, minutes } = parseMinutesToDate(time);

  return days || hours
    ? t("pages.account.time_per_user_format_hours", {
        hours,
        minutes
      })
    : t("pages.account.time_per_user_format_minutes", {
        minutes: minutes || 1
      });
};
