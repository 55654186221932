import React from "react";

const DrawerButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={"inteach-drawer__button"} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default DrawerButton;
