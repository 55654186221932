import React from "react";
// @ts-ignore
import { ReactionList } from "react-activity-feed";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { get } from "lodash";
import { schemes } from "../../theme/colors";
import SvgIcon from "../SvgIcon";
import Avatar from "../Avatar";
import LoadMorePaginator from "./paginator/LoadMorePaginator";

const PostCommentList = ({
  activity,
  currentUserId,
  onToggleChildReaction
}: {
  activity: any;
  currentUserId: string;
  onToggleChildReaction: (a: string, r: any, z: any, s: any) => any;
}) => {
  const [t] = useTranslation();

  return (
    <ReactionList
      activityId={activity.id}
      reactionKind={"comment"}
      Paginator={(props: any) => (
        <LoadMorePaginator
          textButton={t("pages.wall.loadMoreComments")}
          {...props}
        />
      )}
      Reaction={({ reaction }: { reaction: any }) => {
        const reactionDoLike = !!get(reaction, "own_children.like", []).find(
          ({ user_id }: { user_id: string }) => user_id === currentUserId
        );
        const publishedAt = moment(reaction.created_at).fromNow();

        return (
          <div className="inteach-post-comments__comment">
            <div className="inteach-post-comments__block">
              <div className="inteach-post-comments__avatar">
                <Avatar
                  style={{ height: 35, width: 35 }}
                  src={reaction.user.data.image || reaction.user.data.picture}
                />
              </div>
              <div className="inteach-post-comments__message">
                <div className="inteach-post-comments__name">
                  {reaction.user.data.name}
                </div>
                <p className="inteach-post-comments__text">
                  {reaction.data.text}
                </p>

                <div className="inteach-post-comments__reactions">
                  <span>{publishedAt}</span>
                  <span className="text-bold">
                    {t("pages.wall.likes", {
                      count: reaction.children_counts.like || 0
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="inteach-post-comments__like">
              <button
                onClick={() => onToggleChildReaction("like", reaction, {}, {})}>
                <SvgIcon
                  name="heart"
                  height={14}
                  width={14}
                  color={
                    reactionDoLike ? "var(--like-icon)" : schemes.gray[600]
                  }
                />
                {t("pages.wall.likes")}
              </button>
            </div>
          </div>
        );
      }}
    />
  );
};

export default PostCommentList;
