import React from "react";
import { List } from "lodash";
import { useTranslation } from "react-i18next";

export default ({ listType }: { listType: List<String> }) => {
  const [t] = useTranslation();

  let Indicator;
  switch (listType) {
    case "channel":
  Indicator = <p>{t('messaging.noChannelsYet')}</p>;
      break;
    case "message":
      Indicator = null;
      break;
    default:
      Indicator = <p>Pas d'items</p>;
      break;
  }

  return Indicator;
};
