import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/fr";
import fr from "../config/langs/fr";
import en from "../config/langs/en";
import es from "../config/langs/es";
import de from "../config/langs/de";
import nl from "../config/langs/nl";
import it from "../config/langs/it";
import { I18n } from "aws-amplify";
import { updateUser } from "./UserUtils";
import { vanillaUserStore } from "../stores/users";

// the translations
const resources = {
  fr: {
    translation: fr
  },
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  nl: {
    translation: nl
  },
  de: {
    translation: de
  },
  it: {
    translation: it
  }
};

export const initLanguage = async () => {
  await i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(intervalPlural)
    .use(LanguageDetector)
    .init({
      detection: {
        order: [
          "navigator",
          "querystring",
          "cookie",
          "localStorage",
          "sessionStorage",
          "htmlTag",
          "path",
          "subdomain"
        ]
      },
      resources,
      fallbackLng: "fr",
      react: {
        wait: true
      },
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
  const lang = getCurrentLanguage();
  I18n.setLanguage(lang);
  moment.locale(lang);
};

export default i18n;

export const getCurrentLanguage = (): string => {
  return i18n.language.split("-")[0] || "fr";
};

export const getUserLanguage = (): string | null => {
  try {
    const { user } = vanillaUserStore.getState();

    return user.lang;
  } catch (e) {
    return null;
  }
};

export const getOrganizationLanguage = (): string | undefined => {
  try {
    const { user } = vanillaUserStore.getState();

    return user.organizationLang;
  } catch (e) {
    return undefined;
  }
};

export const getCurrentLocale = () => {
  // @ts-ignore
  const navLang = navigator.language || navigator.userLanguage;
  return navLang ? navLang.split("-")[0] : "fr";
};

export const getContentLang = (
  obj: { [key: string]: string } | string,
  lang: string = getCurrentLanguage(),
  fallBack: string = getCurrentLanguage()
): string => {
  if (typeof obj === "string") return obj;
  if (!obj) return "";
  const orgaLang = getOrganizationLanguage();
  const translation =
    obj[lang] || (orgaLang ? obj[orgaLang] : undefined) || obj[fallBack];

  if (translation) return translation;

  return Object.values(obj).find(Boolean) || "";
};

export const retrieveLanguage = () => {
  try {
    const { user } = vanillaUserStore.getState();

    return user.lang || getCurrentLocale();
  } catch (e) {
    return getCurrentLocale(); // Fallback to device language
  }
};

export const updateLanguage = async (lang: string) => {
  await updateUser({
    settings: { lang }
  });

  await setLanguage(lang);
};

export const setLanguage = async (lang: string) => {
  if (getCurrentLanguage() === lang) return;
  I18n.setLanguage(lang);
  moment.locale(lang);
  await i18n.changeLanguage(lang);
};
