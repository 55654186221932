import React, { useCallback, useEffect, useState } from "react";
import Modal from "./BaseModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import Loader from "react-loader-spinner";

const ChangePassModal = ({
  show = false,
  setShow
}: {
  show: boolean;
  setShow: any;
}) => {
  const [t] = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getUsername();
  }, []);

  const getUsername = async () => {
    const { attributes } = await Auth.currentUserInfo();
    setEmail(attributes.email);
  };

  const updatePassword = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, password);
      setShow(false);
      toast.success(t("user.updatePasswordSuccess"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    } catch (e) {
      toast.error(t("user.errorUpdatePassword"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    }
    setIsLoading(false);
  }, [password, oldPassword, setShow, t, isLoading]);

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        if (setShow) setShow(false);
      }}
      title={t("modals.changePassword.title")}
      closeBtn>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
        <form>
          <input
            type="email"
            id="username"
            defaultValue={email}
            autoComplete="username"
            hidden
          />
          <div className="mb-2">
            <label>{t("modals.changePassword.oldPasswordLabel")}</label>
            <input
              id="oldPassword"
              autoComplete="current-password"
              type="password"
              className="inteach-input"
              value={oldPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOldPassword(e.target.value)
              }
            />
          </div>
          <div className="mb-2">
            <label>{t("modals.changePassword.newPasswordLabel")}</label>
            <input
              id="password"
              autoComplete="new-password"
              type="password"
              className="inteach-input"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
          </div>
          <div>
            <label>{t("modals.changePassword.newPasswordConfirmLabel")}</label>
            <input
              id="passwordConfirm"
              autoComplete="new-password"
              type="password"
              className="inteach-input"
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(e.target.value)
              }
            />
          </div>
        </form>
        <button
          type="submit"
          onClick={updatePassword}
          className="inteach-button inteach-button--primary inteach-button--lg inteach-button--block mb-2"
          title={t("common.save")}>
          {isLoading ? (
            <Loader type="ThreeDots" color="#F8F8F8" height={10} width={30} />
          ) : (
            t("common.save")
          )}
        </button>
      </div>
    </Modal>
  );
};

export default ChangePassModal;
