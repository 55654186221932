import { Streami18n } from "stream-chat-react";
import { TFunction } from "i18next";
import { frTranslations, enTranslations } from "stream-chat-react";
import { getCurrentLanguage } from "./i18n";

interface ITranslation {
  [key: string]: object;
}

const defaultTranslations: ITranslation = {
  fr: frTranslations,
  en: enTranslations
};

const translations = [
  // https://github.com/GetStream/stream-chat-react/blob/master/src/i18n/fr.json
  ["Nothing yet...", "nothingYet"],
  ["Loading channels ...", "loadingChannels"],
  ["Type your message", "writeMessage"],
  ["Send message", "sendMessage"],
  ["Commands", "commands"],
  ["Searching for people", "searchingPeople"],
  ["Upload a file", "uploadFile"],
  ["Upload a photo", "uploadPhoto"],
  ["Add a file", "addFile"],
  ["Cancel", "cancel"],
  ["Choose an action", "chooseAction"],
  ["Delete Message", "deleteMessage"],
  ["Edit Message", "editMessage"],
  ["Reply", "reply"],
  ["Add Reaction", "addReaction"],
  ["1 reply", "oneReply"],
  ["Loading messages ...", "loadingMessages"],
  ["Editing Message", "editingMessage"],
  ["Start of a new thread", "startThread"],
  ["This message was deleted ...", "messageDeleted"],
  ["{{ watcherCount }} online", "countOnline"],
  ["{{ replyCount }} replies", "countReplies"]
];


export const getStreami18n: any = (t: TFunction) => {

  const lang = getCurrentLanguage();

  return new Streami18n({
    language: lang,
    translationsForLanguage: {
      ...defaultTranslations[getCurrentLanguage()],
      ...translations.reduce((memo, item) => {
        const [key, tKey] = item;
        return {
          ...memo,
          // We use ## to prevent interpolation
          [key]: t(`messaging.${tKey}`, {
            interpolation: { prefix: "##", suffix: "##" }
          })
        };
      }, {})
    }
  });
};
