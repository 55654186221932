import React, { useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import Config from "../../config";
import Modal from "../modal/BaseModal";
import { getIdentifierFromLinkCode } from "../../api/generic";
import useUiStore, { uiSelector } from "../../stores/ui";
import "./help.scss";
import { toast } from "react-toastify";

export default ({
  setIdentifier,
  changeState
}: {
  setIdentifier: (identifier: string) => void;
  changeState: (state: string) => void;
}) => {
  const [t] = useTranslation();
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const contactURL = Config.get("contact") ?? "support@inteach.io";
  const username = localStorage.getItem("username");
  const setAuthError = useUiStore(uiSelector("setAuthError"));
  const currentError = useUiStore<object>(uiSelector("authError"));

  const resetAuthError = useCallback(() => {
    setIsOpen(false);
    //@ts-ignore
    setCodeError(null);
    setCode("");
    //@ts-ignore
    setAuthError({ code: null, message: null, name: null });
  }, [setAuthError]);

  const onVerifyLink = useCallback(async () => {
    setIsBusy(true);

    const authErrorCode = get(currentError, "code");

    const isNotConfirmed = authErrorCode === "UserNotConfirmedException";

    try {
      if (!code.trim()) {
        setCodeError(t("pages.help.emptyCode"));
        //@ts-ignore
        setTimeout(() => setCodeError(null), 2000);

        setIsBusy(false);
        return;
      }

      const { identifier } = await getIdentifierFromLinkCode(code);
      changeState(isNotConfirmed ? "confirmSignUp" : "signUp");
      setIdentifier(identifier);
      resetAuthError();
    } catch (e) {
      console.log("e", e);
      setCodeError(t("pages.help.codeNotFound"));
    }

    setIsBusy(false);
  }, [code, resetAuthError, t, changeState, currentError, setIdentifier]);

  const openStore = useCallback(() => {
    console.log("openStore");
  }, []);

  const goBack = useCallback(
    route => {
      if (route === "trainings") {
        resetAuthError();
      }
    },
    [resetAuthError]
  );

  useEffect(() => {
    const code: string = get(currentError, "code");

    if (!code || isOpen) return;

    if (!code && isOpen) {
      setIsOpen(false);
      return;
    }

    if (!validErrorCodes.includes(code)) {
      renderErrorToast();
      resetAuthError();
      return;
    }

    setIsOpen(true);
  }, [currentError, isOpen]);

  const renderErrorToast = useCallback(() => {
    const _code: string = get(currentError, "code");
    const _message: string = get(currentError, "message");

    console.log(_code, _message);

    const entries = exceptionsMap.filter(e => e[0] === _code);

    const entry =
      entries.length > 1
        ? entries.find(e => new RegExp(e[1]).test(_message))
        : entries[0];

    const key = `amplify.${
      entry && entry.length && new RegExp(entry[1]).test(_message)
        ? entry[2]
        : _code
    }`;

    toast.error(t(key), { position: "bottom-right" });
  }, [currentError]);

  const renderNotFound = useCallback(
    () => (
      <>
        <span className="help__heading">{t("pages.help.title")}</span>

        <span className="help__sub-heading">{t("pages.help.noPanic")}</span>

        <div className="help__section">
          <span className="help__text">
            {t("pages.help.linkChar", { brand: Config.get("brandName") })}
          </span>

          <div>
            <input
              onChange={evt => setCode(evt.target.value)}
              className="help__input"
            />

            {isBusy ? (
              <span>Loading</span>
            ) : (
              <button
                className="help__button inteach-button"
                style={{ backgroundColor: Config.getTheme("mainColor") }}
                onClick={onVerifyLink}>
                {t("pages.help.verifyLink")}
              </button>
            )}
          </div>

          {codeError ? (
            <span className="d-block mt-1 help__text help__text--italic">
              {codeError}
            </span>
          ) : null}
        </div>

        <div className="help__separator mb-1 mt-1" />

        <div className="mb-2">
          <span className="help__text mb-1">
            {t("pages.help.didSignupError")}
          </span>

          <div className="help__button-container">
            <a
              href={`mailto:${contactURL}?subject=${t(
                "screens.help.emailSubject",
                {
                  brand: Config.get("brandName")
                }
              )}&body=${t("screens.help.emailBody", { username })}`}
              className="help__button inteach-button"
              style={{ backgroundColor: Config.getTheme("mainColor") }}>
              <span>{t("common.contactUs")}</span>
            </a>
          </div>
        </div>

        <div className="help__separator mb-1 mt-1" />

        <div className="help__button-container mb-1">
          <button
            className="help__button inteach-button"
            style={{ backgroundColor: Config.getTheme("mainColor") }}
            onClick={resetAuthError}>
            <span>{t("pages.help.tryLoginAgain")}</span>
          </button>
        </div>
      </>
    ),
    [codeError, isBusy, onVerifyLink, t, resetAuthError]
  );

  const renderUserExists = useCallback(
    () => (
      <>
        <h2>{t("pages.help.titleExists")}</h2>

        <h3>{t("pages.help.noPanic")}</h3>

        <div>
          <span>{t("pages.help.alreadySignup")}</span>
        </div>

        <div />

        <div>
          <span>{t("pages.help.alreadySignupConfirmCode")}</span>
        </div>

        <div>
          <button onClick={resetAuthError}>
            <span>{t("common.understood")}</span>
          </button>
        </div>
      </>
    ),
    [resetAuthError, t]
  );

  const renderAcademyCodeNotFound = useCallback(
    () => (
      <>
        <span className="help__heading">{t("pages.help.signupCodeTitle")}</span>

        <span className="help__sub-heading">{t("pages.help.noPanic")}</span>

        <div className="help__section">
          <span>{t("pages.help.signupCodeText")}</span>
        </div>

        <div className="help__button-container mb-1">
          <a
            href={`mailto:${contactURL}`}
            className="help__button inteach-button"
            style={{ backgroundColor: Config.getTheme("mainColor") }}>
            {t("common.contactUs")}
          </a>
        </div>

        <div className="help__separator mb-1 mt-1" />

        <div className="help__button-container mb-1">
          <button
            className="help__button inteach-button"
            style={{ backgroundColor: Config.getTheme("mainColor") }}
            onClick={resetAuthError}>
            {t("common.understood")}
          </button>
        </div>
      </>
    ),
    [resetAuthError, t]
  );

  const renderAcademyAppUpgradeNeeded = useCallback(
    () => (
      <>
        <h3>{t("pages.help.appUpgradeNeededTitle")}</h3>

        <div>
          <span>{t("pages.help.appUpgradeNeeded")}</span>
        </div>

        <button onClick={openStore}>
          <span>{t("pages.help.upgrade")}</span>
        </button>

        <div
          style={{
            marginTop: 0
          }}
        />

        <button onClick={() => goBack("trainings")}>
          <span>{t("pages.help.goBackTrainings")}</span>
        </button>
      </>
    ),
    [goBack, t, openStore]
  );

  const renderContent = useCallback(() => {
    const code: string = get(currentError, "code");
    const name: string = get(currentError, "name");
    const message: string = get(currentError, "message");

    if (!code) return null;

    const isUserNotFound = ["UserNotFoundException"].includes(code);

    const isUserExists = code === "UsernameExistsException";
    const academyCodeNotFound = code === "AcademyIdentifierNotFound";
    const academyAppUpgradeNeeded = code === "AcademyAppUpgradeNeeded";

    if (isUserNotFound) return renderNotFound();

    if (isUserExists) return renderUserExists();

    if (academyCodeNotFound) return renderAcademyCodeNotFound();

    if (academyAppUpgradeNeeded) return renderAcademyAppUpgradeNeeded();
  }, [
    currentError,
    renderAcademyCodeNotFound,
    renderUserExists,
    renderNotFound,
    renderAcademyAppUpgradeNeeded
  ]);

  return (
    <Modal
      closeBtn={true}
      isOpen={isOpen}
      onRequestClose={() => resetAuthError()}>
      {renderContent()}
    </Modal>
  );
};

export const validErrorCodes = [
  "UserNotFoundException",
  "UsernameExistsException",
  "AcademyIdentifierNotFound",
  "AcademyAppUpgradeNeeded"
];

// Format : [ErrorCode : string, MessageValidator : Regexp, CorrespondingErrorCode : string][]
export const exceptionsMap = [
  [
    "InvalidParameterException",
    /.*password.*fail.*constraint.*length.*greater.*equal.*6/i,
    "InvalidPasswordExceptionNotLongEnough"
  ],
  [
    "InvalidParameterException",
    /.*password.*fail.*constraint.*satisfy.*expression.*pattern/i,
    "InvalidPasswordExceptionBadFormat"
  ],
  [
    "InvalidPasswordException",
    /.*password.*not.*long.*enough/i,
    "InvalidPasswordExceptionNotLongEnough"
  ]
];
