import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../components/layout/Content";
import EditAccountModal from "../../components/modal/EditAccountModal";
import ProfilePicture from "../../components/ProfilePicture";
import "./styles.scss";
import SvgIcon from "../../components/SvgIcon";
import useUserStore, { userSelector } from "../../stores/users";
import { IUser } from "../../../types/users";
import * as UsersAPI from "../../api/users";
import Loader from "react-loader-spinner";
import { dateToStr } from "../../utils";

const Account = () => {
  const [t] = useTranslation();
  const [edit, setEdit] = useState(false);
  //@ts-ignore
  const user: IUser = useUserStore(userSelector("user"));

  if (!user) return null;

  return (
    <Content title={t("pages.account.title")}>
      <EditAccountModal setShow={setEdit} show={edit} user={user} />
      <div className="inteach-account__content">
        <ProfilePicture user={user} canEdit size={100} />
        <h3 className="inteach-account__name">
          {user.firstname} {user.lastname}
        </h3>
        <p className="inteach-account__email">{user.email}</p>
        <button
          className="inteach-button inteach-button--primary"
          onClick={() => setEdit(true)}>
          {t("pages.account.editButton")}
        </button>
        <StatsComponent experience_points={user.points} />
      </div>
    </Content>
  );
};

const StatsComponent = ({
  experience_points
}: {
  experience_points: number;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [stats, setStats] = useState<{
    training_time: number;
    experience_points: number;
    week_session_count: number;
  }>({ training_time: 0, experience_points: -1, week_session_count: -1 });
  const [t] = useTranslation();

  useEffect(() => {
    const fetchStats = async () => {
      setIsLoading(true);
      try {
        const _stats = await UsersAPI.getStats();
        setStats(_stats);
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchStats();
  }, []);

  const renderStats = useCallback(
    () => (
      <>
        <div className="inteach-account__stats__item">
          <SvgIcon name="clock" width={22} height={22} color="grey" />
          <span>{dateToStr(stats.training_time, t)}</span>
          <p>{t("pages.account.trainingTime")}</p>
        </div>
        <div className="inteach-account__stats__item">
          <SvgIcon name="star" width={22} height={22} color="#FFBA49" />
          <span style={{ color: "#FFBA49" }}>
            {stats.experience_points >= 0
              ? stats.experience_points
              : experience_points}
          </span>
          <p>{t("pages.account.points")}</p>
        </div>
        {/* <div className="inteach-account__stats__item">
        <SvgIcon name="lightning" width={22} height={22} color="grey" />
        <span>3</span>
        <p>{t("pages.account.sessionNumber")}</p>
      </div> */}
      </>
    ),
    [stats]
  );

  return isError ? (
    <p className="mt-3 inteach-account--error">
      {t("pages.account.statsLoadError")}
    </p>
  ) : (
    <div className="inteach-account__stats">
      {isLoading ? (
        <Loader type="ThreeDots" color="#6fd6ba" height={50} width={50} />
      ) : (
        renderStats()
      )}
    </div>
  );
};

export default Account;
