import API from "../api/base";

export const link = () => API.post("users/link");
export const getStreamToken = () => API.get("users/getStreamToken");
export const prepare = (payload: any) => API.post("users/prepare", payload);
export const update = (payload: any) => API.put(`users`, payload);
export const getProfile = () => API.get("users/profile");
export const hasPosts = () => API.get("users/hasPosts");
export const uploadProfilePicture = (payload: any, onUploadProgress: any) =>
  API.post("users/profile/picture", payload, onUploadProgress);
export const getStats = (): Promise<{
  training_time: number;
  experience_points: number;
  week_session_count: number;
}> => API.get("user/stats");
export const getFeed = (feedGroup: any, options: any) =>
  API.get("user/feed", { feedGroup, options });
