import React from "react";
import "./LikesList.scss";
import Modal from "../modal/BaseModal";
import { useTranslation } from "react-i18next";
// @ts-ignore TODO add types
import { ReactionList } from "react-activity-feed";
import Avatar from "../Avatar";

const LikesList = ({
  show = false,
  setShow,
  activity
}: {
  show: boolean,
  setShow: any,
  activity: any
}) => {
  const [t] = useTranslation();

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => setShow(false)}
      title={t("pages.wall.likesModal.title", {
        count: activity.reaction_counts.heart
      })}
      closeBtn
    >
      <ReactionList
        activityId={activity.id}
        reactionKind={"heart"}
        flatListProps={{
          style: {
            flex: 1
          }
        }}
        LoadMorePaginator={({ onPress }: { onPress: any }) => (
          <div onClick={onPress}>
            <span>{t("pages.wall.loadMoreLikes")}</span>
          </div>
        )}
        Reaction={({ reaction }: { reaction: any }) => {
          return (
            <div className="inteach-post-likes-user">
              <Avatar src={reaction.user.data.image} />
              <span className="inteach-post-likes-user__name">
                {reaction.user.data.name}
              </span>
            </div>
          );
        }}
      />
    </Modal>
  );
};

export default LikesList;
