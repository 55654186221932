import React, { createRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

// @ts-ignore
import { StreamApp, SinglePost } from "react-activity-feed";
import PostCommentList from "../getStream/PostCommentList";
import PostCommentBox from "../getStream/PostCommentBox";
import usePubSub, { NEW_ANALYTICS } from "../../hooks/usePubSub";

const Activity = ({
  activity,
  currentUserId,
  onToggleChildReaction
}: {
  activity: any;
  currentUserId: string;
  onToggleChildReaction: () => any;
}) => {
  const [t] = useTranslation();

  return (
    <>
      {activity.reaction_counts.comment ? (
        <div>
          <PostCommentList
            activity={activity}
            currentUserId={currentUserId}
            onToggleChildReaction={onToggleChildReaction}
          />
        </div>
      ) : (
        <div className="inteach-post-comments__empty">
          <h4>{t("pages.wall.firstToComment")}</h4>
        </div>
      )}
    </>
  );
};

const CommentsList = ({
  activity,
  currentUserId,
  refreshFeed
}: {
  activity: any;
  currentUserId: string;
  refreshFeed: any;
}) => {
  const inputRef = createRef<HTMLTextAreaElement>();
  const { publish } = usePubSub();
  const onCommentAdded = useCallback(async () => {
    const [, training_id] = activity.actor.split("training:");

    await publish(NEW_ANALYTICS, {
      social_id: activity.id,
      training_id,
      type: "commitment"
    });
  }, [activity.actor, activity.id]);

  useEffect(() => {
    if (!activity.reaction_counts.comment) {
      inputRef.current?.focus();
    }
  }, [activity.reaction_counts.comment, inputRef]);

  return (
    <div className="inteach-post-comments__container">
      <div style={{ flex: 5 }}>
        <SinglePost
          options={{
            withOwnChildren: true,
            refresh: true
          }}
          Activity={(props: any) => {
            if (activity.id !== props.activity.id) return null;
            return (
              <Activity
                currentUserId={currentUserId}
                refreshFeed={refreshFeed}
                onCommentAdded={onCommentAdded}
                {...props}
              />
            );
          }}
          activity={activity}
        />
      </div>
      <div style={{ flex: 1 }}>
        <StreamApp.Consumer>
          {(appCtx: any) => {
            return (
              <PostCommentBox
                activity={activity}
                ref={inputRef}
                onAddReaction={async (...args: any[]) => {
                  await appCtx.client.reactions.add(...args);
                  refreshFeed();
                  onCommentAdded();
                }}
              />
            );
          }}
        </StreamApp.Consumer>
      </div>
    </div>
  );
};

export default CommentsList;
