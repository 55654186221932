import { Cache, Auth } from "aws-amplify";
import { useCallback } from "react";
import Axios from "axios";

import Config from "../config";
import Settings from "../settings";

const config = Cache.configure({
  keyPrefix: "inteach",
  capacityInBytes: 5 * 1000 * 1000,
  itemMaxSize: 10 * 10000 // 0,1 Mo max
});
const PubSubCache = Cache.createInstance(config);

export const NEW_ANALYTICS =
  Config.get("config.pubSubTopic") || "analytics/new";

export default function usePubSub() {
  const publish = useCallback(async (topic, message) => {
    const {
      attributes: { sub: username }
    } = await Auth.currentAuthenticatedUser();

    const messageId = Math.random()
      .toString(36)
      .replace(/[^a-zA-Z0-9]+/g, "")
      .substr(0, 10);

    const messageWithUser = {
      ...message,
      id_user: username
    };

    const cacheItem = {
      messageId,
      topic,
      message: messageWithUser,
      timestamp: Date.now()
    };

    const keys = await PubSubCache.getAllKeys();

    const cachedMessages = await Promise.all(
      keys
        .map(key => PubSubCache.getItem(key))
        .sort((a, b) => a.timestamp - b.timestamp)
    );

    const messages = [cacheItem, ...cachedMessages].sort(
      (a, b) => a.timestamp - b.timestamp
    );

    try {
      const data = await Auth.currentSession();

      await Promise.all(
        messages.map(({ message }) => {
          return Axios.post(
            Config.get("config.apiGateway") ?? Config.get("apiGateway"),
            {
              token: data.getAccessToken().getJwtToken(),
              message
            }
          );
        })
      );

      await Promise.all(
        messages.map(({ messageId }) => PubSubCache.removeItem(messageId))
      );
    } catch (e) {
      console.log("e", e);
    }
  }, []);

  return { publish };
}
