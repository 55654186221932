import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "../SvgIcon";
import { get } from "lodash";
import Avatar from "../Avatar";
import CommentsList from "../posts/CommentsList";
import LikesList from "../posts/LikesList";

const PostCommentLike = ({
  activity,
  containerStyle = {},
  onLikePress = () => {},
  onCommentPress = () => {},
  doLike,
  likesCount,
  commentsCount,
  currentUserId,
  refreshFeed,
  onSeeMorePress = () => {}
}: {
  activity: any;
  containerStyle?: CSSProperties;
  onLikePress: any;
  onCommentPress: any;
  doLike: boolean;
  likesCount: any;
  commentsCount: any;
  currentUserId: any;
  refreshFeed: any;
  onSeeMorePress: any;
}) => {
  const [t] = useTranslation();
  const [showComments, setShowComments] = useState(false);
  const [showLikes, setShowLikes] = useState(false);

  const _renderComments = () => {
    if (!showComments) return null;
    return (
      <CommentsList
        // @ts-ignore TODO Antoine
        activity={activity}
        currentUserId={currentUserId}
        refreshFeed={refreshFeed}
      />
    );
  };

  const _renderReactions = () => {
    const heartReactions = get(activity, "latest_reactions.heart", []);
    // Render Avatar
    const reactionsWithoutCurrentUser = heartReactions.filter(
      ({ user }: { user: any }) => {
        if (!user) return false;

        return user.id !== currentUserId;
      }
    );

    const avatars = reactionsWithoutCurrentUser
      .slice(0, 3)
      .map((data: any, index: number) => {
        return (
          <Avatar
            style={{ marginLeft: index ? -10 : 0 }}
            src={data.user.data.image}
            key={index}
          />
        );
      });

    // Render names
    const _renderLikes = (likes: number) => {
      if (likes <= 0 || !reactionsWithoutCurrentUser.length) return;

      let text;

      // Why 3 ? Because we set this number in Home.screen.js as the max reactions we want
      // to always display 3 avatar
      if (likes > 3) {
        const [
          {
            user: {
              data: { name }
            }
          }
        ] = reactionsWithoutCurrentUser;

        // We do - 1 because we already display the first user
        text = t("pages.wall.otherLikes", {
          name,
          count: likesCount - 1
        });
      } else {
        const [
          firstUser,
          secondUser,
          thirdUser
        ] = reactionsWithoutCurrentUser.map(
          ({
            user: {
              data: { name }
            }
          }: {
            user: { data: { name: string } };
          }) => name
        );

        text = t(`pages.wall.otherLikesPeople_${likes}`, {
          firstUser,
          secondUser,
          thirdUser
        });
      }

      return <span style={{ color: "grey", fontSize: 11 }}>{text}</span>;
    };

    // If there is no like or only one and it's us then don't show the "See" button
    if (!avatars.length) return null;

    return (
      <div
        className="post__reactions pl-1 pr-1 pb-1 d-flex align-items-center"
        onClick={() => setShowLikes(!showLikes)}>
        {/* Display avatars */}
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}>
          {avatars}
        </div>
        {/* Get people who like the post */}
        <div
          style={{
            display: "flex",
            flex: 2,
            alignItems: "center",
            justifyContent: "center"
          }}>
          {_renderLikes(likesCount - +doLike)}
        </div>
      </div>
    );
  };

  const _setShowLikes = (show: boolean) => {
    setShowLikes(show);
  };

  const _renderLikesList = () => {
    return (
      <LikesList show={showLikes} setShow={_setShowLikes} activity={activity} />
    );
  };

  return (
    <>
      <div className="post__actions pb-1 pl-1 pr-1 pt-1">
        <div
          className="post-actions__button"
          onClick={() => setShowComments(!showComments)}>
          <SvgIcon name="comments" color="#7f8592" />
          <span>{t("pages.wall.commentsCount", { count: commentsCount })}</span>
        </div>
        <div className="post-actions__button" onClick={onLikePress}>
          <SvgIcon name="heart" color={doLike ? "red" : "#7f8592"} />
          <span>{t("pages.wall.likes", { count: likesCount })}</span>
        </div>
      </div>
      {_renderLikesList()}
      {_renderReactions()}
      {_renderComments()}
    </>
  );
};

export default PostCommentLike;
