import React, { CSSProperties } from "react";
import "./styles.scss";

const Avatar = ({
  children,
  size,
  src,
  style,
  name,
  className
}: {
  children?: any,
  size?: number,
  src?: string,
  style?: CSSProperties,
  name?: string,
  className?: string
}) => {
  return (
    <div
      className={`inteach-avatar ${className ? className : ""}`}
      style={{ height: size, width: size, ...style }}>
      {src && <img src={src} alt="" style={{ height: size, width: size }} />}
      {name && (
        <span>
          {name
            .split(" ")
            .map(a => a[0])
            .join("")}
        </span>
      )}
    </div>
  );
};

export default Avatar;
