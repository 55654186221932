import React, { useState, useEffect, useCallback } from "react";
import Card from "../Card";
import BasePost from "../getStream/BasePost";
import { useTranslation } from "react-i18next";
import * as TrainingAPI from "../../api/trainings";
import { useHistory } from "react-router-dom";
import { getContentLang } from "../../utils/i18n";
import { ITraining } from "../../../types/training";
import useTrainingsStore from "../../stores/trainings";

const Post = ({
  activity,
  currentUser,
  onToggleReaction,
  onRefresh
}: {
  activity: any;
  currentUser: any;
  onToggleReaction: any;
  onRefresh: any;
  history: any;
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const currentUserId = currentUser.attributes.sub;
  const [training, setTraining] = useState<any | null>({});
  const [isLoading, setIsLoading] = useState(false);
  const addTrainings = useTrainingsStore(
    useCallback(state => state.addTrainings, [])
  );
  const trainings = useTrainingsStore(state => state.trainings);

  useEffect(() => {
    const _getTraining = async () => {
      const training_id = activity.training;

      if (!training_id) {
        setTraining(null);
        return;
      }

      const trainingStored = trainings.find(
        ({ id_training }) => id_training === training_id
      );

      if (trainingStored) {
        setTraining(trainingStored);
        return;
      }
      setIsLoading(true);

      try {
        const _training: ITraining = await TrainingAPI.get(training_id);
        addTrainings([_training], { append: true });
        setTraining(_training);
        setIsLoading(false);
      } catch (e) {
        setTraining(null);
        setIsLoading(false);
      }
    };

    _getTraining();
  }, [activity.training]);

  const onActivityClick = useCallback(
    () => history.push("/trainings/" + training.id_training),
    [history, training && training.id_training]
  );

  const renderLearningItems = useCallback(() => {
    if (!activity.learningSets) return null;

    const [firstLearningSet] = activity.learningSets;

    if (!firstLearningSet) return;

    return (
      <div className="post__training pl-1 pr-1 pt-1 pb-1">
        <span className="post__training-name">
          {getContentLang(firstLearningSet.title, training?.default_lang)}
        </span>
        {training ? (
          <button
            className={`inteach-button inteach-button--${
              isLoading ? "loading" : "primary"
            } inteach-button--md`}
            onClick={() => {
              if (!isLoading) {
                onActivityClick();
              }
            }}>
            {isLoading ? t("common.loading") : t("pages.wall.start")}
          </button>
        ) : null}
      </div>
    );
  }, [training, activity.learningSets, onActivityClick, t, isLoading]);

  return (
    <Card className="p-0">
      <BasePost
        activity={activity}
        training={training}
        currentUserId={currentUserId}
        onToggleReaction={onToggleReaction}
        type="wall"
        beforeLikeComment={renderLearningItems}
        refreshFeed={onRefresh}
        onActivityClick={onActivityClick}
      />
    </Card>
  );
};

export default Post;
