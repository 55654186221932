/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from "react";

import { I18n, ConsoleLogger as Logger, isEmpty } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";

import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Input,
  Button,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent
} from "aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React";

import LoadingText from "../Loader/LoadingText";

const logger = new Logger("RequireNewPassword");

export interface IRequireNewPasswordState extends IAuthPieceState {
  isLoading: boolean;
}

export class RequireNewPassword extends AuthPiece<
  IAuthPieceProps,
  IRequireNewPasswordState
> {
  constructor(props: IAuthPieceProps) {
    super(props);

    this.state = { isLoading: false };
    this._validAuthStates = ["requireNewPassword"];
    this.change = this.change.bind(this);
    this.checkContact = this.checkContact.bind(this);
  }

  checkContact(user: any) {
    if (!Auth || typeof Auth.verifiedContact !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.verifiedContact(user).then(data => {
      if (!isEmpty(data.verified)) {
        this.changeState("signedIn", user);
      } else {
        user = Object.assign(user, data);
        this.changeState("verifyContact", user);
      }
    });
  }

  async change() {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    const user = this.props.authData;
    const { password } = this.inputs;
    const { requiredAttributes } = user.challengeParam;
    const attrs = objectWithProperties(this.inputs, requiredAttributes);

    if (!Auth || typeof Auth.completeNewPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    try {
      const _user = await Auth.completeNewPassword(user, password, attrs);
      logger.debug("complete new password", _user);
      if (_user.challengeName === "SMS_MFA") {
        this.changeState("confirmSignIn", _user);
      } else if (_user.challengeName === "MFA_SETUP") {
        logger.debug("TOTP setup", _user.challengeParam);
        this.changeState("TOTPSetup", _user);
      } else {
        this.checkContact(_user);
      }
    } catch (err) {
      this.error(err);
    }
    this.setState({ isLoading: false });
  }

  showComponent(theme: any) {
    const { hide } = this.props;
    if (hide && hide.includes(RequireNewPassword)) {
      return null;
    }

    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get("Change Password")}
        </SectionHeader>
        <SectionBody theme={theme}>
          <Input
            autoFocus
            placeholder={I18n.get("New Password")}
            theme={theme}
            key="password"
            name="password"
            type="password"
            onChange={this.handleInputChange}
          />

          {requiredAttributes.map((attribute: any) => (
            <Input
              placeholder={I18n.get(convertToPlaceholder(attribute))}
              theme={theme}
              key={attribute}
              name={attribute}
              type="text"
              onChange={this.handleInputChange}
            />
          ))}
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            <Button theme={theme} onClick={this.change}>
              <LoadingText
                title={I18n.get("Change")}
                loadingTitle={I18n.get("changingPassword")}
                isLoading={this.state.isLoading}
              />
            </Button>
          </SectionFooterPrimaryContent>
          <div className="mt-1 d-flex justify-content-center align-items-center">
            <span className="text text--small">
              <Link theme={theme} onClick={() => this.changeState("signIn")}>
                {I18n.get("Back to Sign In")}
              </Link>
            </span>
          </div>
        </SectionFooter>
      </FormSection>
    );
  }
}

function convertToPlaceholder(str: string) {
  return str
    .split("_")
    .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    .join(" ");
}

function objectWithProperties(obj: any, keys: any) {
  const target: object = {};
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    //@ts-ignore
    target[key] = obj[key];
  }
  return target;
}

/**
 * @deprecated use named import
 */
export default RequireNewPassword;
