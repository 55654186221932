export default {
  common: {
    cancel: "Cancelar",
    update: "Actualizar",
    understood: "Entendido",
    next: "Siguiente",
    agree: "Aceptar",
    signout: "Cerrar sesión",
    start: "Comenzar",
    save: "Grabar",
    settingUpWebapp: "Estamos preparando tu aplicación",
    contactUs: "Contactarnos",
    loading: "Cargando",
    seeMore: "Ver más",
    loadMore: "Carga más",
    yes: "sí",
    no: "No",
    open: "abrir",
    close: "Cerrar",
    french: "Francés",
    english: "Inglés",
    spanish: "Español",
    italian: "Italiano",
    german: "Alemán",
    dutch: "holandés",
    rejoin: "Unirse"
  },
  languages: {
    fr: "Francés",
    en: "Inglés",
    es: "Español",
    nl: "Holandés",
    de: "Alemán",
    it: "Italiano"
  },
  menu: {
    home: "Página principal",
    trainings: "Tu curso",
    account: "Mi cuenta",
    settings: "Configuración",
    wall: "Mi Wall"
  },
  user: {
    updateProfilePictureSuccess: "¡Imagen de perfil actualizada!",
    errorUploadProfilePicture: "No se puede actualizar la foto de perfil.",
    updateProfileSuccess: "¡Tu perfil ha sido actualizado!",
    errorUpdateProfile: "No se puede actualizar su perfil.",
    updatePasswordSuccess: "¡Tu contraseña ha sido actualizada!",
    errorUpdatePassword: "No se pudo actualizar su contraseña.",
    firstname: "Nombre",
    lastname: "Apellido"
  },
  amplify: {
    "Code hint":
      "Verifique que su código de cuatro caracteres coincida con los últimos 4 caracteres del enlace que recibió.",
    signupEmail: "Introduce tu correo electrónico",
    signupPassword: "Ingresa tu contraseña (8 caracteres como mínimo)",
    "Reset your password": "Restablecer su contraseña",
    "Send Code": "Enviar código",
    "Code Sent": "Código enviado",
    "Create Account": "Crea una cuenta nueva",
    successSignIn: "Conexión exitosa!",
    successSignOut: "Estás desconectado",
    errorSignOut: "No se puede cerrar la sesión",
    emptyPassword: "Debe ingresar la contraseña",
    "Resend code": "Reenviar código",
    "Sign in to your account": "Conexión",
    InvalidEmail: "Formato de correo inválido",
    inscriptionCode: "Código de registro",
    inscriptionCodeHint: "Código de registro de 4 caracteres",
    "I've got a code": "Regístrese con un código",
    Email: "Correo electrónico",
    "Create a new account": "Crea una cuenta nueva",
    "Sign in": "Conexión",
    "Enter your username": "Nombre del usuario",
    "Enter your email": "Correo electrónico",
    "Enter your password": "Contraseña",
    "Temporary password has expired and must be reset by an administrator.":
      "Votre mot de passe temporaire à expiré et doit être reinitialisé par un administrateur",
    "Use QRCode": "Usar código QR",
    Submit: "enviar",
    Send: "Enviar",
    "Enter your confirmation code":
      "Código de confirmación recibido por correo electrónico.",
    "Enter your new password": "Nueva contraseña",
    "Incorrect username or password.": "Contraseña incorrecta",
    "User does not exist.": "Contraseña incorrecta",
    InvalidParameterExceptionEmail:
      "formato de dirección de correo electrónico no válido",
    UsernameExistsException: "Esta direccion de correo electronico ya existe.",
    InvalidPasswordExceptionUppercase:
      "La contraseña debe tener letras mayúsculas",
    InvalidPasswordExceptionLowercase: "La contraseña debe tener minúsculas",
    InvalidPasswordExceptionNumeric: "La contraseña debe tener números",
    InvalidPasswordExceptionLong:
      "la contraseña debe tener al menos 8 caracteres",
    InvalidPasswordExceptionSymbol:
      "La contraseña debe tener caracteres especiales",
    InvalidParameterExceptionGreaterthanorequal:
      "La contraseña debe tener al menos 6 caracteres",
    InvalidPasswordExceptionBadFormat:
      "Formato de contraseña no válido (no debe comenzar ni terminar con un espacio)",
    InvalidParameterExceptionPasswordempty: "se requiere contraseña",
    UserNotFoundException: "\r\nUsuario no encontrado",
    NotAuthorizedExceptionIncorrectusername: "Usuario no encontrado",
    NotAuthorizedException: "Correo electrónico o contraseña incorrecta",
    InvalidPasswordExceptionNotLongEnough:
      "La contraseña debe tener 8 caracteres",
    InvalidParameterException: "Se ha producido un error",
    LimitExceededException:
      "Se superó el límite de intentos; inténtelo después de un tiempo.",
    InvalidParameterExceptionThereisnoregisteredverifiedemail:
      "No puede cambiar su contraseña porque su cuenta no está verificada. Comuníquese con su administrador para hacerlo.",
    InvalidParameterExceptionAlreadyconfirmed: "El usuario ya está confirmado.",
    "Forget your password?": "Contraseña olvidada ?",
    ssoButtonTitleDoctolib: "Conéctese con Doctolib",
    "Back doctolib login": "Regresar a la conexión Doctolib",
    Redirection: "Serás redirigido a tu proveedor de acceso",
    CodeMismatchException: "código de verificación invalido",
    connectionLoading: "Conexión",
    signUpLoading: "Inscripción",
    sendingCode: "Enviando el codigo",
    changingPassword: "Actualizando contraseña"
  },
  rightbar: {
    messaging: "Mensajería",
    statistics: "Estadísticas",
    trainers: "Tus entrenadores",
    formationTime: "Tiempo de entrenamiento",
    sessionNumber: "Numero de sesiones",
    mainTrainer: "Entrenador principal"
  },
  messaging: {
    title: "Chat",
    chat: "Chat",
    yourTrainers: "Tus entrenadores",
    mainTrainer: "Entrenador principal",
    nothingYet: "Iniciar la conversación",
    loadingChannels: "Cargando mensajes ...",
    writeMessage: "Mensaje",
    sendMessage: "Enviar",
    Commands: "Pedidos",
    searchingPeople: "Buscar usuarios",
    uploadFile: "Agregar archivo",
    uploadPhoto: "Agregar una imagen",
    addFile: "Agregar archivo",
    cancel: "Anular",
    chooseAction: "Elige una acción",
    deleteMessage: "Eliminar",
    editMessage: "Editar",
    reply: "Répondre",
    addReaction: "Réagir",
    oneReply: "1 respuesta",
    countReplies: "{{ replyCount }} respuestas",
    loadingMessages: "Cargando mensajes ...",
    editingMessage: "Édition du message",
    startThread: "Iniciar una conversación",
    startNewThread: "Iniciar una nueva conversación",
    noChannelsYet: "Aún no tienes un chat",
    noItems: "Sin elemento",
    messageDeleted: "Mensaje borrado",
    sent: "Enviado",
    openEmojiPicker: "Abre el selector de emoji",
    close: "Cerrar",
    countOnline: "{{ watcherCount }} registrado",
    limitExceeded: "Por favor, inténtelo de nuevo más tarde ..."
  },
  api: {
    unknownError: "Se ha producido un error. Inténtalo de nuevo",
    errorUploadProfilePicture: "No pudimos cambiar tu foto de perfil"
  },
  start: "Comenzar",
  continue: "Seguir",
  signout: "Cerrar sesión",
  "Network Error": "Asegúrate de estar conectado a Internet.",
  codePushUpdateAvailable: "Actualización disponible",
  codePushUpdateAvailableInstall:
    "Hay una actualización disponible, ¿quieres instalarla?",
  links: {
    usingLinkInProgress: "Usando el enlace actual ...",
    linkUsed: "Enlace utilizado!",
    linkNotAuthorized: "No tienes autorización para utilizar este enlace.",
    linkInternalError: "Nous n'avons pas pu appliquer le lien"
  },
  modals: {
    notifications: {
      title: "Notificaciones",
      empty: "Ninguna notificación"
    },
    editAccount: {
      title: "Editar mi información"
    },
    rgpd: {
      title: "información de GDPR"
    },
    changePassword: {
      title: "Para cambiar la contraseña",
      oldPasswordLabel: "Antigua contraseña",
      newPasswordLabel: "Nueva contraseña",
      newPasswordConfirmLabel: "Confirmar la contraseña"
    },
    capsuleReward: {
      points_success_activity_interval:
        "Completó esta actividad y obtuvo (0) {0 punto}; (1) {1 punto};",
      points_success_activity_plural:
        "You completed this activity and earned {{count}} points",
      percent_correct: "{{count}}% de buenas respuestas",
      minimum_required: "Mínimo requerido : {{count}}%",
      congrats: "Felicidades !",
      fail: "Falla",
      reward: "Completaste esta actividad y ganaste",
      failDescription:
        "Esta vez no lo consiguió. ¡Vuelve a intentar validar esta cápsula!"
    }
  },
  pages: {
    help: {
      upgrade: "Poner al día",
      appUpgradeNeededTitle:
        "Esta aplicación es como sus alumnos, ¡está progresando! 🎉",
      appUpgradeNeeded:
        "Para una mejor experiencia de aprendizaje, lo invitamos a actualizar esta aplicación.",
      goBackTrainings: "Regreso al entrenamiento",
      linkChar: `Ingrese los últimos cuatro caracteres del enlace de invitación en el campo a continuación si no pudo acceder a la pantalla de registro. (Ejemplo : "abcd" para el siguiente enlace https://{{brand}}.page.link/abcd).`,
      emptyCode: "El campo no puede estar vacío.",
      codeNotFound: "No pudimos encontrar este enlace de registro.",
      verifyLink: "Cheque",
      titleNotConfirmed: "Tu cuenta no está confirmada.",
      title: "No podemos encontrar tu cuenta 🕵️‍♀️",
      signupCodeTitle: "Código de registro incorrecto",
      signupCodeText: `Verifique que el código de 4 dígitos que ingresó sea correcto. Si es así, contáctenos haciendo clic en el botón de abajo.`,
      titleExists: "Esta cuenta ya existe 🔍",
      noPanic: "¡No te preocupes! 🧘🏽‍♂",
      alreadySignup:
        "Si nunca se ha registrado con este correo electrónico, comuníquese con el administrador para que pueda volver a crear una cuenta para usted.",
      alreadySignupConfirmCode: `Si su cuenta no está confirmada y desea hacerlo, haga clic en "Confirmar un código" y luego en "Reenviar un código".`,
      didSignupError:
        "Si encontraste un problema para inscribirte, puedes contactarnos dando click en el botón de abajo.",
      tryLoginAgain: "Intenta conectarte de nuevo",
      codeSent: "Solo te volvemos a enviar un código",
      emailSubject: "Problema de registro {{brand}}",
      emailBody: `
Buenos dias,

No puedo conectarme con mi correo electrónico : {{username}}.

Puede ayudarme ?

Gracias
      `
    },
    messaging: {
      title: "Mensajería"
    },
    account: {
      title: "Mi cuenta",
      editButton: "Modificar mi información",
      trainingTime: "Tiempo de entrenamiento",
      points: "Puntos de experiencia",
      sessionNumber: "Número de sesiones esta semana",
      time_per_user_format_hours: "{{hours}}h{{minutes}}",
      time_per_user_format_minutes: "{{minutes}}min",
      statsLoadError:
        "No pudimos cargar sus estadísticas, intente volver más tarde"
    },
    settings: {
      title: "Configuración",
      changePassword: "Para cambiar la contraseña",
      changeLanguage: "Cambiar idioma",
      infosRGPD: "Información GDPR",
      toggleNotifications: "Notificaciones"
    },
    trainings: {
      title: "Tu curso",
      refreshListFail: "\r\nTus cursos no se cargaron",
      noTraining: "No hay curso en las cajas!",
      participants_interval: "(0){no hay participante};(1){1 participante};",
      participants_plural: "{{count}} participantes",
      points_interval: "(0){0 punto};(1){1 punto};",
      points_plural: "{{count}} puntos",
      training_not_started: "Este entrenamiento estará disponible el {{date}}"
    },
    wall: {
      title: "Noticias",
      loadingFail: "No podemos cargar tus publicaciones en este momento.",
      commentsCount: "{{count}} Comentario",
      commentsCount_plural: "{{count}} Comentario",
      likes: "Me gusta",
      otherLikes: "a {{name}} y a {{count}} les gustó",
      otherLikes_plural:
        "\r\nA {{name}} y a {{count}} otras personas les gustó",
      otherLikesPeople_1: "{{firstUser}} le gusta",
      otherLikesPeople_2: "a {{name}} y a {{count}} les gustó",
      otherLikesPeople_3:
        "a {{firstUser}}, a {{secondUser}} y a {{thirdUser}} les gustó",
      commentBoxPlaceholder: "Tu comentario",
      seeLikes: "Ver",
      loadMoreComments: "Cargar más comentarios",
      loadMoreLikes: "Cargar más me gusta",
      newPosts_interval: "nueva publicación",
      newPosts_plural: "Nuevas publicaciones",
      likesModal: {
        title: "menciones me gusta\r\nmencionas me gusta"
      },
      firstToComment: "Sé el primero en comentar !",
      start: "Empezar",
      noPosts: "Aún no hay publicaciones en tu muro",
      loadMorePosts: "Cargar más entradas"
    },
    search: {
      title: "Busqueda",
      refreshListFail: "Tu búsqueda falló",
      noResult: "Ningún resultado encontrado",
      searchPlaceholder: "Buscar contenido",
      filters: {
        keywords: "Buscar por palabra clave",
        training: "Filtrar por curso",
        types: {
          capsules: "Cápsulas",
          videos: "Videos",
          files: "Archivos adjuntos"
        }
      }
    }
  },
  learningSets: {
    learningItems: {
      availableOn: "Disponible el {{date}}",
      locked: "Completa la actividad anterior",
      capsule: {
        title: "Lección"
      },
      test: {
        title: "Evaluación"
      },
      video: {
        title: "Vídeo"
      },
      scorm: {
        title: "Package SCORM"
      },
      file: {
        title: "Archivo"
      },
      practice: {
        title: "Poner en práctica",
        moreTime: "Todavía necesito un poco de tiempo",
        done: "He terminado !",
        confirm: "Confirmo",
        alertTitle: "¿Confirmas tu acción?",
        alertText:
          "Confirmo que he completado esta práctica. Si mi entrenador solicita un trabajo, le envío"
      }
    }
  },
  oneSignal: {
    bellPrompt: {
      "tip.state.unsubscribed": "Suscríbete a las notificaciones",
      "tip.state.subscribed": "Te has suscrito a las notificaciones",
      "tip.state.blocked": "Has bloqueado las notificaciones",
      "message.prenotify": "Haga clic para suscribirse a las notificaciones",
      "message.action.subscribed": "¡Te has suscrito a las notificaciones!",
      "message.action.resubscribed": "¡Te has suscrito a las notificaciones!",
      "message.action.unsubscribed":
        "¡Anulaste la suscripción a las notificaciones!",
      "dialog.main.title": "Notificaciones",
      "dialog.main.button.subscribe": "Abonado",
      "dialog.main.button.unsubscribe": "Darse de baja",
      "dialog.blocked.title": "Desbloquear notificaciones",
      "dialog.blocked.message":
        "Siga las instrucciones para desbloquear notificaciones:"
    }
  }
};
