import React, { useState, useEffect, useContext } from "react";
import {
  Chat,
  Channel,
  MessageList,
  MessageInput,
  ChatContext,
  Thread,
  Window,
  Streami18n
} from "stream-chat-react";
import CustomChannelHeader from "./CustomChannelHeader";
import { isEmpty } from "lodash";
import "./styles.scss";

const ChatModal = ({ streami18n }: { streami18n: Streami18n }) => {
  const { channel, client, setActiveChannel } = useContext(ChatContext);
  const [showChatModal, setShowChatModal] = useState(false);

  useEffect(() => {
    if (channel && !isEmpty(channel)) {
      setShowChatModal(true);
    } else {
      setShowChatModal(false);
    }
  }, [channel]);

  const onClose = () => {
    setShowChatModal(false);
    if (setActiveChannel) {
      setActiveChannel(undefined);
    }
  };

  return showChatModal ? (
    <div className="inteach-chat__modal">
      <Chat client={client} theme="custom" i18nInstance={streami18n}>
        <Channel channel={channel}>
          <div className="inteach-chat__modal__content">
            <Window hideOnThread>
              <CustomChannelHeader onClose={onClose} />
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </div>
        </Channel>
      </Chat>
    </div>
  ) : null;
};

export default ChatModal;
