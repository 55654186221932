import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Modal from "./BaseModal";
import { IUser } from "../../../types/users";
import { updateUser } from "../../utils/UserUtils";

const EditAccountModal = ({
  show = false,
  setShow,
  user
}: {
  show: boolean;
  setShow: any;
  user: IUser;
}) => {
  const [t] = useTranslation();
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);

  const saveProfile = useCallback(async () => {
    try {
      await updateUser({ firstname, lastname });
      setShow(false);
      toast.success(t("user.updateProfileSuccess"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    } catch (e) {
      toast.error(t("user.errorUpdateProfile"), {
        position: "bottom-right",
        hideProgressBar: true
      });
    }
  }, [firstname, lastname, setShow, t]);

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        if (setShow) setShow(false);
      }}
      title={t("modals.editAccount.title")}
      closeBtn>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
        <div>
          <label>{t("user.firstname")}</label>
          <input
            id="firstname"
            type="text"
            className="inteach-input mb-2"
            value={firstname}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFirstname(e.target.value)
            }
          />
          <label>{t("user.lastname")}</label>
          <input
            id="lastname"
            type="text"
            className="inteach-input"
            value={lastname}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setLastname(e.target.value)
            }
          />
        </div>
        <button
          type="submit"
          onClick={saveProfile}
          className="inteach-button inteach-button--primary inteach-button--lg inteach-button--block mb-2">
          {t("common.save")}
        </button>
      </div>
    </Modal>
  );
};

export default EditAccountModal;
