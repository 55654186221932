export const schemes = {
  gray: {
    "200": "#F8F8F8",
    "300": "#DFE0E5",
    "400": "#EFEFF2",
    "500": "#D8D8D8",
    "600": "#7F8592",
    "700": "#70747C",
    "800": "#333436"
  },
  blue: {
    "500": "#1B2749",
    "600": "#222B45"
  }
};

export default {
  title: schemes.blue[500],
  separator: schemes.gray[400],
  postLearningSetTitle: schemes.gray[600],
  postLearningItemsSeparator: schemes.gray[300],
  headerGradientLeftColor: "#45EDAA",
  headerGradientRightColor: "#2EC4C4",
  menuIconColor: "#FFFFFF",
  iconColor: "#222B45",
  iconDisabledColor: "#7F8592",
  likeIconColor: "#FF3F3E",
  // ----------------------
  orange: "#FFAC00",
  blue: "#1f6c77",
  mainColor: "#3AD9B8",
  darkBlue: "#103338",
  black: "#000000",
  white: "#FFFFFF",
  principalText: "#333333",
  gray700: "#7F8592",
  gray: "#f2f3f2",
  darkerGray: "#dddedd",
  lighterGray: "#F8F8F8",
  veryLightGray: "#DEDEDE",
  modalTitle: "#c0c1c0",
  correctResponse: "#55c955",
  wrongResponse: "#c95555",
  pointComponent: "#F8F8F8",
  red: "#d43f3a",
  gold: "#FFBA49",
  silver: "#9c9c9c",
  bronze: "#eb8560",
  disabled: "rgba(0, 0, 0, 0.3)",
  shadow: "rgba(0, 0, 0, 0.3)",
  darkerShadow: "rgba(0, 0, 0, 0.8)",
  gradientColor1: "#63dcb6",
  gradientColor2: "#59a78e",
  fontGray: "#777777",
  authorGray: "#D3D3D3",
  thumbColors: ["#1A535C", "#EF5B5B", "#5EC2A8", "#FFBA49"],
  lighterDark: "#4a4a4a",
  blackSilver: "#555555",
  welcomeBackgroundColorOpacity: "0.6",
  registerTextButton: "#5EC1A7",
  lessonsListTextColor: "#5C5B5C",
  topbarTitle: "#000000"
};
