//@flow
import * as React from "react";
import { withTranslation } from "react-i18next";
// import LoadMoreButton from './LoadMoreButton';
// import { smartRender } from '../utils';

// import type { Renderable } from '../types';
type Props = {
  /** The button the user should click to click to load more */
  /** callback to load the next page */
  loadNextPage: () => any,
  /** indicates if there is a next page to load */
  hasNextPage: boolean,
  /** indicates if there there's currently any refreshing taking place */
  refreshing: boolean,
  /** display the items in opposite order */
  reverse: boolean,
  /** The paginated content to display */
  children: any,
  t: any,
  textButton: string
};

class LoadMorePaginator extends React.Component<Props> {
  render() {
    return (
      <>
        {!this.props.reverse && this.props.children}
        {this.props.hasNextPage && !this.props.refreshing ? (
          <div style={{ textAlign: "center" }}>
            <button
              className="inteach-button inteach-button--primary mb-2"
              onClick={
                this.props.loadNextPage || this.props.t("common.loadMore")
              }>
              {this.props.textButton}
            </button>
          </div>
        ) : null}
        {this.props.reverse && this.props.children}
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(LoadMorePaginator);
