import React, { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "../SvgIcon";
import TextareaAutosize from "react-textarea-autosize";

export default React.forwardRef(
  (
    {
      activity,
      onAddReaction,
      ...props
    }: {
      activity: any,
      onAddReaction: any,
      [key: string]: any
    },
    ref:
      | MutableRefObject<HTMLTextAreaElement | null>
      | ((instance: HTMLTextAreaElement | null) => void)
      | null
  ) => {
    const [t] = useTranslation();
    const [text, setText] = useState("");

    return (
      <div className="inteach-post-comments-send">
        <div className="inteach-post-comments-send__icon">
          <SvgIcon name="comments" height={18} width={18} />
        </div>
        <TextareaAutosize
          className="inteach-post-comments-send__input"
          ref={ref}
          rows={1}
          autoFocus
          placeholder={t("pages.wall.commentBoxPlaceholder")}
          value={text}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setText(e.target.value)
          }
        />
        <div
          className="inteach-post-comments-send__submit"
          onClick={() => {
            if (text.trim() === "") return;
            onAddReaction("comment", activity, { text });
            setText("");
          }}>
          <SvgIcon name="next" height={11} width={11} />
        </div>
      </div>
    );
  }
);
