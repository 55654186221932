import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "../SvgIcon";
import "./styles.scss";
import { ITraining } from "../../../types/training";
import moment from "moment";

const TrainingsInfo = ({
  training,
  className
}: {
  training: ITraining;
  className?: string;
}) => {
  const [t] = useTranslation();

  const { totalPoints, learnerCount, started, start_at } = training;

  const renderStartDate = useCallback(() => {
    if (started) return null;

    return (
      <span
        className={`training-item__info training-item__info--available ${className}`}>
        <SvgIcon name="clock" />
        {t("learningSets.learningItems.availableOn", {
          date: moment(start_at).format("DD/MM/YYYY")
        }).toLocaleLowerCase()}
      </span>
    );
  }, [started, start_at]);

  return (
    <div>
      {renderStartDate()}
      <span className={`training-item__info ${className}`}>
        <SvgIcon name="users" />
        {t("pages.trainings.participants_interval", {
          postProcess: "interval",
          count: +learnerCount
        })}
      </span>

      <span className={`training-item__info ${className}`}>
        <SvgIcon name="star" />
        {t("pages.trainings.points_interval", {
          postProcess: "interval",
          count: totalPoints
        })}
      </span>
    </div>
  );
};

export default TrainingsInfo;
