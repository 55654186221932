import React from "react";
import ChatComponent from "../../components/getStream/ChatComponent";
import { Streami18n } from "stream-chat-react";
import { StreamChat } from "stream-chat";
import { useMediaLayout } from "use-media";
import Home from "../Home";

const Messaging = ({
  client,
  isReady,
  i18nInstance,
  userId
}: {
  client: StreamChat;
  isReady: boolean;
  i18nInstance: Streami18n;
  userId: string;
}) => {
  const wideScreen = useMediaLayout({
    minWidth: "841px"
  });
  return wideScreen ? (
    <Home />
  ) : (
    <ChatComponent
      client={client}
      isReady={isReady}
      theme="inteach"
      i18nInstance={i18nInstance}
      userId={userId}
    />
  );
};

export default Messaging;
