import React from "react";
import "./styles.scss";
import { StreamChat } from "stream-chat";
import { Streami18n } from "stream-chat-react";
import ChatComponent from "../../getStream/ChatComponent";

const Rightbar = ({
  client,
  isReady,
  userId,
  streami18n
}: {
  client: StreamChat;
  isReady: boolean;
  userId: string;
  streami18n: Streami18n;
}) => {
  return (
    <div className="inteach-rightbar">
      <div className="inteach-rightbar__content">
        <ChatComponent
          client={client}
          isReady={isReady}
          theme="inteach"
          i18nInstance={streami18n}
          userId={userId}
        />
      </div>
    </div>
  );
};

export default React.memo(Rightbar);
