import API from "./base";

export const getSignedURL = (
  id: string,
  key: string,
  fileType: any,
  model: any // TODO type
) => API.get(`getSignedURL/${id}`, { key, fileType, model });

export const getSignedMetadata = (
  id: string,
  key: string,
  model: any // TODO type
) => API.get(`getSignedMetadata/${id}`, { key, model });

export const getIdentifierFromLinkCode = (code: string) =>
  API.get(`links/identifier/${code}`);

export const getAPIVersion = () => API.get("/");
