import * as moment from "moment-timezone";
import OneSignal from "react-onesignal";
import { Auth } from "aws-amplify";

import * as UserAPI from "../api/users";
import { IUser } from "../../types/users";
import { setLanguage } from "./i18n";
import { vanillaUserStore } from "../stores/users";

export interface IUserProfile extends IUser {
  email: string;
  firstname: string;
  lastname: string;
  role: "ADMINISTRATOR" | "LEARNER";
  organization_id: string;
  points: number;
  picture?: string | null;
  lang: string | null;
  timezone: string | null;
  rgpd: boolean;
}

export interface IUserUpdate {
  firstname?: string;
  lastname?: string;
  role?: "ADMINISTRATOR" | "LEARNER";
  settings?: {
    attributes?: object;
    lang?: string | null;
    rgpd?: boolean;
  };
}

export const initUser = async () => {
  try {
    const user: IUserProfile = await UserAPI.getProfile();
    // get local timezone and update user
    // ( ex: for paris timezone = Europe/Paris )
    const timezone = moment.tz.guess();
    !(user.timezone && user.timezone === timezone) &&
      (await UserAPI.update({
        settings: { timezone }
      }));

    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: { ...user }
    });

    if (user.lang) await setLanguage(user.lang);

    setTimeout(async () => {
      try {
        const {
          attributes: { sub }
        } = await Auth.currentAuthenticatedUser();
        if (sub) await OneSignal.setExternalUserId(sub);
      } catch (e) {}
    }, 5000);

    return user;
  } catch (e) {
    // User not logged
  }
};

export const userReadRGPD = async () => {
  try {
    const { user } = vanillaUserStore.getState();

    return user.rgpd;
  } catch (e) {
    // Maybe there is no user in cache
  }

  // Get profile
  const profile: IUserProfile = await UserAPI.getProfile();

  if (profile.rgpd) return true;

  try {
    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: { ...profile }
    });
  } catch (e) {}

  return false;
};

export const acceptRGPD = async () => {
  try {
    await UserAPI.update({
      settings: { rgpd: true }
    });

    const { user } = vanillaUserStore.getState();

    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: {
        ...user,
        rgpd: true
      }
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const updateUser = async (attributes: IUserUpdate) => {
  await UserAPI.update(attributes);

  const { user } = vanillaUserStore.getState();

  vanillaUserStore.setState({
    ...vanillaUserStore.getState(),
    user: {
      ...user,
      ...attributes
    }
  });
};

export const updateProfilePicture = async (url: string) => {
  try {
    const { user } = vanillaUserStore.getState();

    vanillaUserStore.setState({
      ...vanillaUserStore.getState(),
      user: {
        ...user,
        picture: url
      }
    });
  } catch (e) {}
};
